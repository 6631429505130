import {AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {Clipboard} from '@angular/cdk/clipboard';
import {MAT_SNACK_BAR_DATA, MatSnackBarRef} from '@angular/material/snack-bar';


@Component({
  selector: 'web-farbkatalog',
  templateUrl: './web-farbkatalog.component.html',
  styleUrls: ['./web-farbkatalog.component.css']
})
export class WebFarbkatlogComponent implements OnInit, AfterViewInit {

  public jsonData = {}
  @Inject(MAT_SNACK_BAR_DATA) public data: any
  @ViewChild('picker') picker: ElementRef
  public keys: string[]
  public currentKey: string
  public currentColor
  public previousTooltip: string;
  public nextTooltip: string;
  public response

  constructor(
    public snackBarRef: MatSnackBarRef<WebFarbkatlogComponent>,
    private zwischenablage: Clipboard
  ) {}

  currentColor1: string
  currentColorHex1: string
  currentColor2: string
  currentColorHex2: string

  ngOnInit() {
    this.currentKey = '1';
    this.currentColor = {
      '_id': '1',
      'revision': 2,
      'createdAt': 1699956950,
      'createdBy': 'ClientId::533898cb7798a9d19c4f2a9191ebc0a0',
      'changedAt': 1709629773,
      'changedBy': 'ClientId::533898cb7798a9d19c4f2a9191ebc0a0',
      'version': 1,
      'title': 'Demo',
      'private': false,
      'primary': '#2b69ab',
      'secondary': '#f2ad00'
    }
    this.currentColor1 = '#2b69ab';
    this.currentColorHex1 = '#2b69ab';
    this.currentColor2 = '#f2ad00';
    this.currentColorHex2 = '#f2ad00';
  }

  ngAfterViewInit() {}

  toggle_sidebar() {
    this.snackBarRef.dismiss()
  }

  updateColor(inputField: string, hexField: string, cssVariable: string) {
    this[hexField] = this[inputField];
    document.documentElement.style.setProperty(cssVariable, this[inputField]);
  }

  updateColorFromHex(inputField: string, hexField: string, cssVariable: string) {
    this[inputField] = this[hexField];
    document.documentElement.style.setProperty(cssVariable, this[hexField]);
  }

  bearbeiten(id) {
    document.getElementById(id).click()
  }

  reset(color) {
    if (color == 'pri') {
      this.currentColor1 = '#2b69ab';
      this.currentColorHex1 = '#2b69ab';
      document.documentElement.style.setProperty('--primary', this.currentColor.primary);
    } else if (color == 'sec') {
      this.currentColor2 = '#f2ad00';
      this.currentColorHex2 = '#f2ad00';
      document.documentElement.style.setProperty('--secondary', this.currentColor.secondary);
    }
  }

  kopiereFarbe(farbZeichenkette, elem) {
    document.getElementById(elem).classList.add('heartbeat')
    setTimeout(() => {
      document.getElementById(elem).classList.remove('heartbeat')
    }, 1000)
    this.zwischenablage.copy(this[farbZeichenkette])
  }

  navigate(direction: string): void {
    const currentIndex = this.keys.indexOf(this.currentKey);
    if (direction === 'previous' && currentIndex > 0) {
      this.currentKey = this.keys[currentIndex - 1];
    } else if (direction === 'next' && currentIndex < this.keys.length - 1) {
      this.currentKey = this.keys[currentIndex + 1];
    }
    this.updateCurrentColor();
    this.updateTooltips();
  }

  updateCurrentColor(): void {
    this.currentColor = this.jsonData[this.currentKey];
    this['currentColor1'] = this.currentColor.primary
    this['currentColorHex1'] = this.currentColor.primary
    this['currentColor2'] = this.currentColor.secondary
    this['currentColorHex2'] = this.currentColor.secondary
    document.documentElement.style.setProperty('--primary', this.currentColor.primary);
    document.documentElement.style.setProperty('--secondary', this.currentColor.secondary);
  }

  updateTooltips(): void {
    const currentIndex = this.keys.indexOf(this.currentKey);
    this.previousTooltip = currentIndex > 0 ? this.jsonData[this.keys[currentIndex - 1]].title : '';
    this.nextTooltip = currentIndex < this.keys.length - 1 ? this.jsonData[this.keys[currentIndex + 1]].title : '';
  }
}
