import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'web-profile',
    templateUrl: './web-profile.component.html',
    styleUrls: ['./web-profile.component.css'],
})
export class WebProfileComponent implements OnInit {

    constructor() {}

    ngOnInit() {}
}
