import {Injectable} from '@angular/core';


@Injectable()
export class SpeiseplanDarstellungService {

  constructor() {}

}

