import { Component, OnInit } from '@angular/core';
import {KundeService} from 'services/kunden/kunde.service';
import {environment} from '../../../../environments/environment';
import {ApiCallService} from '../../../../services/api/api-call.service';
import {AppStateService} from '../../../../services/app/app-state.service';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';

@Component({
    selector: 'home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {

    public startseite = null
    public os

    constructor(
        public kunde_service: KundeService,
        private app_state_service: AppStateService,
        private contentBlockService: ContentBlockService
        ) {
      this.os = environment.type
      this.app_state_service.set_page_name('Home');
      this.app_state_service.set_seitenname('home')
      this.app_state_service.set_page_navigation('normal');
    }

    ngOnInit() {
      if (this.os == 'web'){
        this.home_abrufen('Web_Startseite')
      } else {
        this.home_abrufen('App_Startseite')
      }
    }

    public home_abrufen(zweck){
      this.contentBlockService.getContentBlock(zweck).subscribe(result => {
        this.startseite = result
      })
    }
}
