<form
  *ngIf="form"
  id="profil"
  [formGroup]="form"
  [class.mobil]="detectedPlattform == 'MOBIL'"
  (ngSubmit)="onSubmit(form.value)"
>

  <mat-card class="profil_nr" *ngIf="detectedPlattform != 'WEB'">
    <strong>Allgemeine Informationen</strong>
    <span>Kundennummer: {{ kunde.kundenNr }}</span>
  </mat-card>

  <mat-accordion class="example-headers-align" multi>
    <mat-expansion-panel expanded="true" class="profil_kategorien" [class.panel]="detectedPlattform == 'WEB'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Essensteilnehmer</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>

      <div class="form-controls" [class.et-web]="detectedPlattform == 'WEB'">
        <div [class.et-bereich]="detectedPlattform == 'WEB'" *ngIf="form.controls.essensteilnehmer_anrede || form.controls.essensteilnehmer_vorname || form.controls.essensteilnehmer_name">
          <strong *ngIf="form.controls.essensteilnehmer_anrede || form.controls.essensteilnehmer_vorname || form.controls.essensteilnehmer_name">Angabe zur Person</strong>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_anrede">
            <label>Anrede</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_anrede">
          </div>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_vorname">
            <label>Name</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_vorname">
          </div>
<!--          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_name">-->
<!--            <label></label>-->
<!--            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_name">-->
<!--          </div>-->
        </div>
        <div [class.et-bereich]="detectedPlattform == 'WEB'" *ngIf="form.controls.essensteilnehmer_strasse || form.controls.essensteilnehmer_plz || form.controls.essensteilnehmer_ort || form.controls.essensteilnehmer_land">
          <strong *ngIf="form.controls.essensteilnehmer_strasse || form.controls.essensteilnehmer_plz || form.controls.essensteilnehmer_ort || form.controls.essensteilnehmer_land">Anschrift</strong>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_strasse">
            <label>Straße</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_strasse">
          </div>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_plz">
            <label>PLZ</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_plz">
          </div>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_ort">
            <label>Ort</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_ort">
          </div>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_land">
            <label>Land</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_land">
          </div>
        </div>
        <div [class.et-bereich]="detectedPlattform == 'WEB'" *ngIf="form.controls.essensteilnehmer_gebTag || form.controls.essensteilnehmer_eMail || form.controls.essensteilnehmer_telefon">
          <strong *ngIf="form.controls.essensteilnehmer_gebTag || form.controls.essensteilnehmer_eMail || form.controls.essensteilnehmer_telefon">Weitere Angabe</strong>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_gebTag">
            <label>Geburtstag</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_gebTag">
          </div>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_eMail">
            <label>E-Mail</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_eMail" [readonly]="!mandant.mailerAktiv" (change)="change($event)">
            <mat-error>{{ form.controls.essensteilnehmer_eMail.errors }}</mat-error>
          </div>
          <div appearance="standart" *ngIf="form.controls.essensteilnehmer_telefon">
            <label>Telefon</label>
            <input class="form-controls-inputs" matInput formControlName="essensteilnehmer_telefon" (change)="change($event)">
            <mat-error>{{ form.controls.essensteilnehmer_telefon.errors }}</mat-error>
          </div>
        </div>
      </div>
    </mat-expansion-panel>

    <mat-expansion-panel class="profil_kategorien" *ngIf="istBankdaten()" [class.panel]="detectedPlattform == 'WEB'">
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>Bankdaten</strong>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="form-controls" [class.web]="detectedPlattform == 'WEB'">
        <div appearance="standart" *ngIf="form.controls.bankverbindung_iban && form.controls.bankverbindung_iban.value">
          <label>IBAN</label>
          <input class="form-controls-inputs" readonly matInput formControlName="bankverbindung_iban">
        </div>
        <div appearance="standart" *ngIf="form.controls.bankverbindung_bic && form.controls.bankverbindung_bic.value">
          <label>BIC</label>
          <input class="form-controls-inputs" readonly matInput formControlName="bankverbindung_bic">
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>

  <mat-expansion-panel expanded="true" class="profil_kategorien" [class.panel]="detectedPlattform == 'WEB'" *ngIf="mandant.mailerAktiv">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <strong>Zugangsdaten</strong>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <div class="form-controls" [class.web]="detectedPlattform == 'WEB'">
      <div class="login-data passwort" appearance="standart" *ngIf="form.controls.neues_passwort">
        <label>Neues Passwort</label>
        <input class="form-controls-inputs" [type]="hide ? 'password' : 'text'"  matInput formControlName="neues_passwort" [readonly]="!mandant.mailerAktiv" (change)="change($event)">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error>{{ form.controls.neues_passwort.errors }}</mat-error>
      </div>

      <div class="login-data passwort" appearance="standart" *ngIf="form.controls.neues_passwort_wiederholt">
        <label>Neues Passwort wiederholen</label>
        <input class="form-controls-inputs" [type]="hide ? 'password' : 'text'"  matInput formControlName="neues_passwort_wiederholt" [readonly]="!mandant.mailerAktiv" (change)="change($event)">
        <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
        <mat-error>{{ form.controls.neues_passwort_wiederholt.errors }}</mat-error>
      </div>
    </div>
  </mat-expansion-panel>
  <mat-card *ngIf="detectedPlattform != 'WEB' && mandant.mailerAktiv">
    <strong>Änderungen bestätigen</strong>
    <div class="login-data passwort" appearance="standart" *ngIf="form.controls.passwort">
      <label>Dein aktuelles Passwort</label>
      <input class="passwort-input" formControlName="passwort" [type]="hide ? 'password' : 'text'" [readonly]="!mandant.mailerAktiv" (change)="change($event)">
      <mat-icon matSuffix (click)="hide = !hide">{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
      <mat-error *ngIf="form.controls.passwort.errors">{{ form.controls.passwort.errors }}</mat-error>
    </div>
    <div class="profil-optionen-buttons">
      <button (click)="reset()">
        <span>Abbrechen</span>
      </button>
      <button type="submit" [disabled]="form.controls.passwort.value == ''">
        <span>Speichern</span>
      </button>
    </div>
  </mat-card>
</form>
