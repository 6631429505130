import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { zip } from 'rxjs';

@Injectable()
export class StartupAppGuard  {
  constructor(
    private router: Router,
    private db_service: DbReaderService,
  ) { }

  canActivate(): boolean {
    const aktiverKunde = this.db_service.get('active', 'User')
    const kunden = this.db_service.all('User', 'keys')
    zip(aktiverKunde, kunden).subscribe((result) => {
      if (result[0] && result[1].length === 2) {
        this.router.navigateByUrl('speiseplan-kunde')
      }
      if (!kunden){
        this.router.navigateByUrl('login')
      }
    })
    return true;
  }
}
