import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../environments/environment';

@Pipe({
  name: 'kalenderDatum'
})
export class KalenderDatumPipe implements PipeTransform {

  transform(value: string, format: string): string {
    // Hier können Sie die Logik zur Formatierung des Datums implementieren, ohne das Jahr zu ändern
    // Ein Beispiel für eine einfache Formatierung ohne das Jahr automatisch zu erhöhen
    const dateParts = value.split('-');
    let day = dateParts[2].slice(0, 2);
    const month = dateParts[1];
    const year = dateParts[0];
    return `${day}.${month}.${year}`;
  }
}
