import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SammelbestellerFilter} from '../sammelbestellerFilter/sammelbestellerFilter.config';
import {SpeiseplanDatenService} from '../../services/speiseplan-daten.service';

@Component({
	selector: 'app-sammelbestellerGruppe',
	templateUrl: './sammelbestellerGruppe.component.html',
	styleUrls: ['./sammelbestellerGruppe.component.css'],
})
export class SammelbestellerGruppeComponent implements OnInit, AfterViewInit, OnDestroy {

  @Input('sammelbestellerFilterConfig') filterConfig: SammelbestellerFilter;
  public sammelbestellerGruppen: Array<any>

  constructor(
    private speiseplanDatenSerice: SpeiseplanDatenService,
  ) {}

	ngOnInit() {
    this.speiseplanDatenSerice.einrichtungsGruppenGet(
      this.filterConfig.sammelbesteller['mandantId'],
      this.filterConfig.sammelbesteller['einrichtung'].nameEinrichtung
    ).subscribe(apiResponse => {
      this.sammelbestellerGruppen = apiResponse['gruppen']
    })
  }

	ngAfterViewInit() {}

  ngOnDestroy() {}
}
