import { AfterViewInit, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AppStateService } from 'services/app/app-state.service';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { ConfirmDialogComponent } from '../../dialog/confirm-dialog/confirm-dialog.components';
import { ServiceWorkerService } from 'services/navigation/service-worker.service';
import {MitteilungsService} from '../../../../services/prozesse/mitteilung.service';
import {StandartDialogComponent} from '../../dialog/standart-dialog/standart-dialog.components';
import {Platform} from '@angular/cdk/platform';


@Component({
  selector: 'einstellungen',
  templateUrl: './einstellungen.component.html',
  styleUrls: ['./einstellungen.component.css'],
})

export class EinstellungenComponent implements OnInit, AfterViewInit {

  @Output() toggleChange: EventEmitter<void>
  public tagWocheAktiv = true
  public form = new UntypedFormGroup({
    tagWoche: new UntypedFormControl(false),
    allergene: new UntypedFormControl(false),
    darkmode: new UntypedFormControl(false),
    vertikal: new UntypedFormControl(false),
  });
  public tagWoche: boolean = false
  public allergene: boolean = false
  public darkmode: boolean = false
  public vertikal: boolean = false
  public disable: boolean = true
  public changed: boolean = false

  // Plattform: WBA, WBP

  public tagWocheArray = [
    { name: 'TagWoche', active: true, plattform: 'WBA'},
    { typ: 'div1', class: 'infoSpan', onclick: 'openDialog("tagwoche")', icon: 'info', span: 'Speiseplanansicht' },
    { typ: 'div2', class: 'modusSlide', span: 'tagWoche' },
    { typ: 'mat-slide-toggle', checked: 'tagWoche', class: 'mat-toggle', id: 'primary-slide', changeValue: 'tagWoche', changeParameter: 'tagWoche', formControlName: 'tagWoche' },
    { text: 'Diese Einstellung ändert die Speiseplanansicht auf die Tagesansicht oder die Wochenansicht'}
  ]

  public allergeneArray = [
    { name: 'Allergene', active: true, plattform: 'WBP' },
    { typ: 'div1', class: 'infoSpan', onclick: 'openDialog("allergene")', icon: 'info', span: 'Allergene filtern' },
    { typ: 'div2', class: 'modusSlide', span: false },
    { typ: 'mat-slide-toggle', checked: 'allergene', class: 'mat-toggle', id: '', changeValue: 'allergene', changeParameter: 'allergene', formControlName: 'allergene' },
    { text: 'Diese Einstellung ändert die Darstellung der Legende im Detailbereich der Menüs im Speiseplan für den aktuell genutzen Browser.'}
  ]

  public darkmodeArray = [
    { name: 'DarkMode', active: true, plattform: 'WBA' },
    { typ: 'div1', onclick: 'openDialog("darkmode")', icon: 'info', span: 'Dunkler Modus' },
    { typ: 'div2', class: 'modusSlide', span: false },
    { typ: 'mat-slide-toggle', checked: 'darkmode', class: 'mat-toggle', id: '', changeValue: 'darkmode', changeParameter: 'darkmode', formControlName: 'darkmode' }
  ]

  public vertikalArray = [
    { name: 'vertikal', active: true },
    { typ: 'div1', class: 'infoSpan', onclick: 'openDialog("vertikal")', icon: 'info', span: 'Vertikaler Modus' },
    { typ: 'div2', class: 'modusSlide', span: false },
    { typ: 'mat-slide-toggle', checked: 'vertikal', class: 'mat-toggle', id: '', changeValue: 'vertikal', changeParameter: 'vertikal', formControlName: 'vertikal' }
  ]

  public einstellungen = [
    this.tagWocheArray,
    // this.allergeneArray,
    // this.darkmodeArray,
    // this.vertikalArray,
  ]

  public isPWA = this.service_worker.isPWA
  public detectedPlattform: string

  constructor(
    private dialog: MatDialog,
    private db: DbReaderService,
    private app_state: AppStateService,
    private service_worker: ServiceWorkerService,
    private mitteilugsService: MitteilungsService,
    public platform: Platform,
  ) {
    this.app_state.set_page_name('Einstellungen');
    this.app_state.set_seitenname('einstellungen')
    this.app_state.set_page_navigation('normal');
  }

  ngOnInit() {
    if (this.platform.ANDROID) {
      this.detectedPlattform = 'ANDROID'
    }
    else if (this.platform.IOS) {
      this.detectedPlattform = 'IOS'
    }
    else {
      this.detectedPlattform = 'WEB'
      this.array_anpassen('WBP')
    }
    this.db.all('Options', 'keys').subscribe(keys => {
      this.db.all('Options', 'value').subscribe(value => {
        for (let i = 0; i < keys.length; i++) {
          this[keys[i]] = value[i]
        }
      })
    })
  }

  ngAfterViewInit() {
    this.form.valueChanges.subscribe(() => { this.changed = true })
  }

  array_anpassen(plattform){
    let index: any
    let array: any
    for ([index, array] of this.einstellungen.entries()){
      if (array[0].plattform != plattform){
        this.einstellungen.splice(index, 1)
      }
    }
  }

  sliderChange(value) {
    if (this[value] === true) {
      this[value] = false
    }
    else if (this[value] === false) {
      this[value] = true
    }
  }

  speichern() {
    this.db.add(this.tagWoche, 'tagWoche', 'Options')
    this.db.add(this.allergene, 'allergene', 'Options')
    this.db.add(this.darkmode, 'darkmode', 'Options')
    this.db.add(this.vertikal, 'vertikal', 'Options')
    this.changed = false
    if (this.detectedPlattform != 'WEB'){
      this.mitteilugsService.set_snackbar('8602', 60000)
    }
    else{
      this.dialog.closeAll()
      this.mitteilugsService.setMitteilung('8602')
    }
  }

  werkseinstellungen() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Werkseinstellungen',
        message: `Wollen sie wirklich auf Werkseinstellungen zurücksetzen?`,
        panelClass: 'dialog-container-bestellschluss',
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.db.add(false, 'tagWoche', 'Options')
        this.form.controls['tagWoche'].setValue(false)
        this.tagWoche = false
        this.tagWocheAktiv = false
        this.changed = false
        this.db.add(false, 'allergene', 'Options')
        this.db.add(false, 'darkmode', 'Options')
        this.db.add(false, 'vertikal', 'Options')
        this.mitteilugsService.set_snackbar('8603', 60000)
      }
    });
  }

  information(text) {
    const dialogRef = this.dialog.open(StandartDialogComponent, {
      data: {
        title: "Information",
        text: text,
      }
    })
  }
}
