import {Component, OnInit, AfterViewInit } from '@angular/core';
import {Router} from '@angular/router';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {Observable} from 'rxjs';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import {Platform} from '@angular/cdk/platform';
import {AppStateService} from '../../../../services/app/app-state.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'api-email-verifikation',
  templateUrl:  './email-verifikation.component.html',
  styleUrls: ['./email-verifikation.component.css'],
})

export class EmailVerifikationComponent implements OnInit, AfterViewInit {

  public mailNachricht:string
  public mailResponseType: string
  public mandant:any
  private mailTemplates = {
    'reset_password': {
      'good': '1013: Die Anfrage wurde erfolgreich versandt. Sie erhalten in den nächsten Minuten eine E-Mail. Bitte folgen Sie den darin enthaltenen Anweisungen.',
      'bad': `1012: Aufgrund eines technischen Fehlers konnten wir das Passwort über diese Funktion nicht zurücksetzen. Bitte nehmen Sie <a href=${environment.type == 'web' ? '#/rechtliches?id=Kontakt' : '#/kontakt'}>Kontakt</a> zu uns auf.`
    },
    'verify_email': {
      'good': `1023: Vielen Dank für Ihre Registrierung. <br>Bitte beachten Sie, dass der Vertrag erst gültig ist, wenn er von uns bestätigt wurde.`,
      'bad': '1022: Der Registrierungslink ist abgelaufen. Bitte registrieren Sie sich erneut.'
    }
  }

  constructor(
    private router: Router,
    private mandantService: MandantenService,
    private kundenService: KundeService,
    private platform: Platform,
    private appStateService: AppStateService,
  ) {
    this.mandantService.mandant_get().subscribe((mandant) => {
      this.mandant = mandant
    })
    this.appStateService.set_page_name('Email-Verifikation');
    this.appStateService.set_page_navigation('profils');
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const params = this.router.url.split('/')
    let observer:Observable<any>
    if (params[1] == 'reset_password'){
      observer = this.kundenService.kunde_passwort_reseten(params[2])
    }
    if (params[1] == 'verify_email'){
      observer = this.kundenService.kunde_verify(params[2])
    }
    observer.subscribe(
      () => {
        this.mailNachricht = this.mailTemplates[params[1]]['good']
        this.mailResponseType = 'good'
      },
      () => {
        this.mailNachricht = this.mailTemplates[params[1]]['bad']
        this.mailResponseType = 'bad'
      }
    )
  }

  public linkToLogin(){
    if (!this.platform.ANDROID && !this.platform.IOS){
      const neuerLink = window.location.href.split('/#/');
      this.router.navigate(['/login'])
    }else{
      const neuerLink = window.location.href.split('/#/');
      this.router.navigate(['/profile'])
    }
  }
}
