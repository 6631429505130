import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {BehaviorSubject, zip} from 'rxjs';
import { MandantenService } from '../mandanten/mandanten.service';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';

@Injectable()
export class MenueSyncroSopro {

  constructor(
    private mandant_service: MandantenService,
    private router: Router,
  ) {
  }

  public modus = new BehaviorSubject<any>(false)
  public config = new BehaviorSubject({
    menus: {},
    bestellungen: {}
  })
  public mSBG_SecArrays = []
  public mSBG_MainArray = []
  public mSBG_bestellt = false

  public mengenSyncByGrp(aktuelle_regel, bestellungen, alle_regeln) {
    this.mandant_service.mandant_get().subscribe(mandant => {
      if (mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp) {
        this.mengenSyncByGrpArrays(mandant, aktuelle_regel, alle_regeln)
        // geht alle bestellungen durch um abzugleichen ob schon bestellungen vorhanden sind oder nicht
        let schon_bestellt = false
        let bestellt: any
        for (bestellt of Object.entries(bestellungen.bestellMenues)){
          let id = this.mSBG_MainArray[0].find(item => item.menuetextid === bestellt[1].menueTextId)
          if (!id){
            if (bestellt[1].mengeNeu > 0){
              schon_bestellt = true
            }
          }
        }
        let zuordnungen = Object.values(bestellungen.bestellMenues).filter((bestellung: any) => {
          // checkt ob das angeklickte menü ein mainGrp menü ist
          let mainGrp = mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp[aktuelle_regel.splanId].mainGrp
          if (aktuelle_regel.gruppe == mainGrp) {
            // überprüft die id der bestellungen und schaut ob es ein bereits bestelltes oder ein neues ist
            if (aktuelle_regel.menuetextid == bestellung.menueTextId && bestellung.mengeAlt > 0) {
              this.mengenSyncByGrp_activate(bestellung, bestellungen, aktuelle_regel, schon_bestellt)
            } else {
              if (bestellung.mengeAlt > 0) {
                bestellung.mengeNeu = 0
              }
              this.mengenSyncByGrp_activate(bestellung, bestellungen, aktuelle_regel, schon_bestellt)
            }
          } else {
            this.mengenSyncByGrp_secGrp(aktuelle_regel, bestellung, bestellungen)
          }
        })
        this.mSBG_bestellt = true
      }
    })
  }

  public mengenSyncByGrpPruefung(bestellungen, aktuelle_regel, schon_bestellt){
    let status = true
    let bestellung: any
    // geht durch die gesamten tages bestellugnen durch
    for (bestellung of Object.entries(bestellungen)){
      // überprüft ob bereits eine bestellung am tag vorliegt
      // wenn bereits eine bestellung vorlag
      if (bestellung[1].mengeAlt > 0){
        if (bestellung[1].mengeNeu > 0){
          status = true
        }
      }
      // wenn das main menu erneut bestellt wird
      if (bestellung[1].mengeNeu > 1 && bestellung[1].menueTextId == aktuelle_regel.menuetextid){
        status = true
      }
      // wenn das main menü wechselt
      let id = this.mSBG_MainArray[0].find(item => item.menuetextid === bestellung[1].menueTextId)
      // wählt aus ob es ein main menü ist
      if (id){
        //bestellt alle alle anderen ausgewählten main menüs ab
        if(bestellung[1].menueTextId != aktuelle_regel.menuetextid){
          if (bestellung[1].mengeNeu > 0){
            bestellung[1].mengeNeu = 0
          }
        }
      }
      else {
        // hier muss er jetzt schauen ob die untergruppen menüs schon bestellt wurden oder nicht
        if (schon_bestellt){
          if (bestellung[1].mengeAlt > 0 || bestellung[1].mengeNeu > 0){
            bestellung[1].mengeNeu = this.mengenSyncByGrp_sync(bestellungen, aktuelle_regel)
          }
          status = false
        }
      }
    }
    return status
  }

  // geht die sopro aus der API durch, und speichert diese für jede gruppe in ein eigenes array
  public mengenSyncByGrpArrays(mandant, aktuelle_regel, alle_regeln) {
    this.mSBG_MainArray = []
    let neuesMainArray = [];
    for (let zahl of mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp[aktuelle_regel.splanId].secGrps) {
      let neuesSecArray = [];
      let arrayName = "grp" + zahl;
      for (let x of alle_regeln) {
        if (x.gruppe == zahl && !neuesSecArray.includes(x)) {
          neuesSecArray.push(x)
        }
      }
      this.mSBG_SecArrays.push({[arrayName]: neuesSecArray});
    }
    neuesMainArray.push(aktuelle_regel)
    for (let x of alle_regeln) {
      if (x.gruppe == 1 && !neuesMainArray.includes(x)) {
        neuesMainArray.push(x)
      }
    }
    this.mSBG_MainArray.push(neuesMainArray);
  }

  // activiert die secGrp menüs bei der ersten auswahl eines mainGrp menüs mit prüfung
  public mengenSyncByGrp_activate(bestellung, bestellungen, aktuelle_regel, schon_bestellt) {
    for (let array of this.mSBG_SecArrays) {
      let menue = []
      for (menue of Object.entries(array)) {
        if (bestellung.menueTextId == menue[1][0].menuetextid) {
          if (this.mengenSyncByGrpPruefung(bestellungen.bestellMenues, aktuelle_regel, schon_bestellt)){
            bestellung.mengeNeu = this.mengenSyncByGrp_sync(bestellungen.bestellMenues, aktuelle_regel)
          }
        }
      }
    }
  }

  // menge der sec menüs anpassen ans hauptmenü
  public mengenSyncByGrp_sync(bestellungen, aktuelle_regel){
    let menu : any
    for (menu of Object.entries(bestellungen)){
      if (menu[1].menueTextId == aktuelle_regel.menuetextid){
        return menu[1].mengeNeu
      }
    }
  }

  // Sorgt dafür das bereits bestellte secgrp Menüs abbestellt werden.
  public mengenSyncByGrp_secGrp(aktuelle_regel, bestellung, bestellungen) {
    if (aktuelle_regel.menuetextid != bestellung.menueTextId) {
      for (let array of this.mSBG_SecArrays) {
        let menue = []
        for (menue of Object.entries(array)) {
          if (aktuelle_regel.gruppe == menue[1][0].gruppe) {
            let x, y
            for ([x, y] of Object.entries(menue[1])) {
              if (y.menuetextid == bestellung.menueTextId) {
                bestellung.mengeNeu = 0
              }
            }
          }
        }
      }
    }
    // zugrif auf die menge des neuen secGrp menüs
    else {
      let main: any
      for (main of Object.entries(bestellungen.bestellMenues)){
        if (main[1].mengeNeu > 0 || main[1].mengeAlt > 0) {
          if (main[1].mengeNeu > 0){bestellung.mengeNeu = main[1].mengeNeu}
          if (main[1].mengeAlt > 0){bestellung.mengeNeu = main[1].mengeAlt}
        }
      }
    }
  }
}
