import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'guthaben'
})

export class GuthabenPipe implements PipeTransform {
  constructor() {
  }

  transform(value: any): any {
    if (value == null) {
      return 0
    } else {
      if (typeof value === 'number') {
        value = value.toFixed(2)
        var value = value.toString()
        value = value.replaceAll('.', ',')
        return value
      }
    }
  }
}
