import * as moment from 'moment/moment';
import {environment} from '../../../../environments/environment';

export class Zeitraum_Woche {
  index: number
  kw: number
  monat: number
  start: string
  ende: string

  constructor(datum) {
    this.index = moment.utc(datum).unix(),
      this.kw = moment.utc(datum).week(),
      this.monat = moment.utc(datum).month() + 1,
      this.start = moment.utc(datum).startOf('week').format('YYYY-MM-DD'),
      this.ende = moment.utc(datum).endOf('week').format('YYYY-MM-DD')
  }
}

export class Zeitraum_Monat {
  index: number
  monat: number
  start: string
  ende: string

  constructor(datum) {
    this.index = moment.utc(datum).unix(),
    this.monat = moment.utc(datum).month() + 1,
    this.start = moment.utc(datum).startOf('month').format('YYYY-MM-DD'),
    this.ende = moment.utc(datum).endOf('month').format('YYYY-MM-DD')
  }
}

export class Zeitraum_Tag {
  index: number
  start: string
  ende: string
  constructor(datum) {
    this.index = moment.utc(datum).startOf('day').unix()
    this.start = moment.utc(datum).startOf('day').format("YYYY-MM-DD")
    this.ende = moment.utc(datum).startOf('day').format("YYYY-MM-DD")
  }
}
