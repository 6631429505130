// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
@Injectable()
export class AuthGuardService {
  constructor(public router: Router) {}

  canActivate(): boolean {
    if (!localStorage.getItem('active') && !localStorage.getItem(localStorage.getItem('active'))) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}