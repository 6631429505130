import { Injectable } from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class StateService {

  public mandant: BehaviorSubject<any> = new BehaviorSubject<any>(false)

  constructor() {}
}
