import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';

@Component({
  selector: 'app-formular-password-input',
  templateUrl: './formular-password-input.component.html',
  styleUrls: ['./formular-password-input.component.css']
})
export class FormularPasswordInputComponent implements OnInit {

  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @Input() tabIndex
  @HostBinding('className') componentClass: string;
  public visibility: boolean = false

  constructor(
    public registrationService: RegistrierungService
  ) { }

  ngOnInit(): void {
    this.componentClass = this.feldConfig.name;
  }

  public zeigePasswort(){
    if (this.visibility){
      this.visibility = false
    }else{
      this.visibility = true
    }
  }
}
