import { Component, Inject } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {DateiService} from '../../../services/prozesse/datei.service';

@Component({
  selector: 'app-web-pdf',
  templateUrl: './web-pdf.component.html',
  styleUrls: ['./web-pdf.component.css']
})
export class WebPdfComponent {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    private dateiService: DateiService,
  ) { }

  downloadPDF(item) {
    this.dateiService.speiseplanPDF(item.value).subscribe(file => {
      setTimeout(() => { window.open(URL.createObjectURL(file)) }, 50)
    })
  }

}
