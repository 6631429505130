import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'base64'
})
export class Base64Pipe implements PipeTransform {

  transform(base64encodedText: string): string {
    if (base64encodedText){
      return atob(base64encodedText);
    }
    return base64encodedText
  }

}
