<div mat-dialog-content class="dialog-content">
  <h2>Wir haben Ihnen eine E-Mail geschickt</h2>
  Sie haben eine Verifizierungsmail an die Vertragspartner-E-Mail-Adresse erhalten.
  Bitte verifizieren Sie diese E-Mail-Adresse mit dem darin enthaltenen Link. Prüfen Sie auch den Spam-Ordner.<br><br>
  Erst im Anschluss wird Ihre Registrierung zur Bearbeitung freigeschaltet.<br>
  Bitte beachten Sie, dass der Vertrag erst gültig ist, wenn er von:<br><br>
  <p><strong [innerHTML]="firma"></strong></p>
  bestätigt wurde.<br>
  Dies dauert in der Regel 1-2 Werktage.
</div>

<div mat-dialog-actions class="dialog-actions">
  <button mat-raised-button class="option-yes" (click)="onConfirm()">Ok</button>
</div>
