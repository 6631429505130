<div id="registrierungErfolgreich" *ngIf="mandant">
  <mat-icon>forward_to_inbox</mat-icon>
  <strong>Wir haben Ihnen eine E-Mail geschickt</strong>
  <span>
    Sie haben eine Verifizierungsmail an die Vertragspartner-E-Mail-Adresse erhalten. Bitte verifizieren Sie diese
    E-Mail-Adresse mit dem darin enthaltenen Link. Prüfen Sie auch den Spam-Ordner.
  </span>
  <span>
    Erst im Anschluss wird Ihre Registrierung zur Bearbeitung freigeschaltet.
    Bitte beachten Sie, dass der Vertrag erst gültig ist, wenn er von:
  </span>
  <strong>"{{ mandant.betreiberDaten.name }}"</strong>
  <span>bestätigt wurde. <br> Dies dauert in der Regel 1-2 Werktage.</span>
  <button (click)="onDismiss()" mat-flat-button >Fortfahren</button>
</div>
