import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SeitenleisteService} from '../../seitenleiste.service';
import {Router} from '@angular/router';

@Component({
	selector: 'registrierung-zwischen-seitenleiste',
	templateUrl: './registrierung-zwischen.seitenleiste.component.html',
	styleUrls: ['./registrierung-zwischen.seitenleiste.component.css'],
})
export class RegistrierungZwischenSeitenleisteComponent implements OnInit, AfterViewInit {

  @Input('mandant') mandant

	constructor(
    private seitenleistenService: SeitenleisteService,
    private router: Router,
	) { }

	ngOnInit() {
    console.log(this.mandant)
	}

	ngAfterViewInit() {
  }

  public formularAbbrechen(){
    this.router.navigate(['login']);
  }
}
