import {Component, Input, OnInit} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'speiseplanTagContent',
  templateUrl: './speiseplanTagContent.component.html',
  styleUrls: ['./speiseplanTagContent.component.css'],
})
export class SpeiseplanTagContentComponent implements OnInit {

  @Input() mandant
  @Input() kunde
  @Input() speiseplan
  @Input() config
  @Input() tag
  @Input() darstellung
  @Input() modus

  public os


  constructor() {
    this.os = environment.type == 'web' ? 'web' : 'app'
  }

  ngOnInit() {
  }

  trennung(){
    let laenge = Object.keys(this.tag.value.tagesMenues).length
    let number = laenge <= 5 ? 5 : laenge <= 6 ? 6 : laenge <= 10 ? 5 : laenge <= 12 ? 6 : 6
    return number
  }

  protected readonly window = window;
}
