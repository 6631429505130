import {AfterViewInit, Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {SpeiseplanSammelbestellerService} from '../../services/speiseplan-sammebesteller.service';
import {SammelbestellerFilter} from './sammelbestellerFilter.config';

@Component({
	selector: 'app-sammelbestellerFilter',
	templateUrl: './sammelbestellerFilter.component.html',
	styleUrls: ['./sammelbestellerFilter.component.css'],
})
export class SammelbestellerFilterComponent implements OnInit, AfterViewInit, OnDestroy {

  public filterTab
  public filterTemplate
  @ViewChild('tab1') public tab1: TemplateRef<any>;
  public sammelbestellerFilterConfig: SammelbestellerFilter
  public status

  constructor(
    public speiseplanSammelbestellerService: SpeiseplanSammelbestellerService,
  ) {}

	ngOnInit() {
    this.sammelbestellerFilterConfig = this.speiseplanSammelbestellerService.sammelbestellerFilter.getValue()
  }

	ngAfterViewInit() {
    this.setzeTab('tab1', this.tab1)
  }

  ngOnDestroy() {}

  public setzeTab(tabName:string, tabRef: TemplateRef<any>){
    this.filterTab = tabName
    this.filterTemplate = tabRef
  }

  public auswahlBestaetigen(){
    this.status = this.status ? false : true
    this.speiseplanSammelbestellerService.filterAktualisieren(this.sammelbestellerFilterConfig)
  }
}
