import {Component, OnDestroy, OnInit} from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import {Platform} from '@angular/cdk/platform';
import {BilderKarussellComponent} from 'submodul/obs.shared/fragmente/bilder-karussell/bilder-karussell.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';


@Component({
  selector: 'app-pwa-install',
  templateUrl: './pwa-install.component.html',
  styleUrls: ['./pwa-install.component.css']
})
export class PwaInstallComponent implements OnInit, OnDestroy {

  public detectedPlattform: string
  public pwaInstallManual = {
    'ANDROID': [
      'im Chrome das <strong>Funktionsmenü</strong> öffnen (Burgermenü am oberen rechten Rand)',
      'Funktion <strong>"Zum Startbildschirm hinzufügen"</strong> wählen',
      'hier können Sie eine Auswahl treffen zwischen dem <strong>Installieren</strong> oder der <strong>Erstellung einer Verknüpfung</strong>',
      'nach der <strong>Installation</strong> ist das Icon der Web-Bestell-App im Hauptmenü und kann bei Bedarf auf dem Home-Bildschirm platziert werden',
      'nach der <strong>Erstellung einer Verknüpfung</strong> ist das Icon der Web-Bestell-App auf dem Homebildschirm platziert. Zusätzlich wird am Icon das Browsersymbol angezeigt, mit welchem die Web-Bestell-App verknüpft ist'
    ],
    'SAFARI': [
      'im Safari das <strong>Teilen-Menü</strong> öffnen (Menüleiste im untereren Bereich)',
      'Funktion <strong>„Zum Home-Bildschirm“</strong> wählen',
      'bei Bedarf den <strong>Titel anpassen</strong> für das App-Icon und „Hinzufügen“'
    ]
  }
  public mandant
  public bilder = new Array()

  constructor(
    private appStateService: AppStateService,
    public platform: Platform,
    public mandant_service: MandantenService,
    public dialog: MatDialog,
  ) {
    this.appStateService.set_page_name('FAQ');
    this.appStateService.set_seitenname('faq')
    this.appStateService.set_page_navigation('normal');
  }

  ngOnInit(): void {
    localStorage.setItem('readIosPwaInstall', 'true')
    if (this.platform.ANDROID) {
      this.detectedPlattform = 'ANDROID'
    }
    if (this.platform.SAFARI || this.platform.IOS || this.platform.WEBKIT) {
      this.detectedPlattform = 'SAFARI'
    }
    this.img_vergeben(this.detectedPlattform)
    this.mandant_service.mandant_get().subscribe(mandant => this.mandant = mandant)
  }

  ngOnDestroy() {
    localStorage.setItem('readTutorialMenu', 'true')
  }

  public anleitung(){
    this.dialog.open(BilderKarussellComponent, {
      id: 'bilder-dialog',
      panelClass: 'bilder_karussell_titel',
      data: {
        plattform: this.detectedPlattform
      }
    })
  }

  public img_vergeben(status){
    this.bilder[0] = new Image()
    this.bilder[0].src = 'assets/icons/pwa-anleitung/' + status + '/Anleitung1.webp'
    this.bilder[1] = new Image()
    this.bilder[1].src = 'assets/icons/pwa-anleitung/' + status + '/Anleitung2.webp'
    this.bilder[2] = new Image()
    this.bilder[2].src = 'assets/icons/pwa-anleitung/' + status + '/Anleitung3.webp'
  }
}
