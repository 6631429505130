<div id="sondetagTitel">
  <h3><strong>Informationen</strong></h3>
  <mat-icon (click)="schliesseDialog()">close</mat-icon>
</div>
<div id="feiertag" *ngIf="feiertag">
  <strong>Feiertag {{ feiertag.bezeichnung }}</strong>
  <span>{{feiertag.datum | datum}}</span>
</div>

<div id="zustellpause" *ngIf="zustellpause">
  <strong>Zustellpause</strong>
  <span>{{zustellpause.von | datum}} - {{(zustellpause.bis | datum) == '01.01.1970' ? 'offen' : zustellpause.bis | datum }}</span>
  <span>Grund: {{zustellpause.grund}}</span>
</div>
