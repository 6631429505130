import { Injectable } from '@angular/core';
import { ApiCallService } from '../api/api-call.service';

import { environment } from 'environments/environment';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';

@Injectable()
export class MandantenAIZService {

  private splanName = new BehaviorSubject('')
  getSplanName = this.splanName.asObservable();

  constructor(
    private datenbank_service: DbReaderService,
  ) { }

  public kunde: any
  public mandant_id: BehaviorSubject<string>

  public init(mandant){
  }
}
