import {Component, ElementRef, Output, ViewChild, Input, OnInit, AfterViewInit} from '@angular/core';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/de';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'zeitraumPicker',
  templateUrl: './zeitraumPicker.component.html',
  styleUrls: ['./zeitraumPicker.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy,
    }
  ]
})
export class ZeitraumPickerComponent implements OnInit, AfterViewInit{
  @Input('zeitraum') api_zeitraum
  @Output() zeitraum_update = new EventEmitter()
  @ViewChild('picker') picker: any | ElementRef
  @Input('speiseplanKunde') speiseplanKunde
  @Input('speiseplanBestellung') speiseplanBestellung
  public bestellung
  public zeitraum = {
    von: undefined,
    bis: undefined
  }
  public moment = moment
  public dateRange: DateRange<any> | null;
  public newTime = {
    start: undefined,
    ende: undefined
  }
  public mobil = false

  constructor(
  ) {
    moment.locale('de')
  }

  ngOnInit() {
    this.mobil = environment.type != 'web' ? true : false
    this.zeitraum.von = this.api_zeitraum.von
    this.zeitraum.bis = this.api_zeitraum.bis
    this.zeitraumLaden(this.zeitraum)
  }

  ngAfterViewInit(){
    this.aktuelle_woche()
  }

  aktualisieren(datum){
    this.zeitraum.von = moment.default(datum.start)
    this.zeitraum.bis = moment.default(datum.end)
    this.zeitraum_update.emit(this.zeitraum)
  }

  aktueller_zeitraum(zeitraum){
    let von = zeitraum.split(' - ')[0].split('.').reverse().join('-')
    let bis = zeitraum.split(' - ')[1].split('.').reverse().join('-')
    this.zeitraum.von = moment.default(von, 'YYYY-MM-DD').locale('de').startOf('day')
    this.zeitraum.bis = moment.default(bis, 'YYYY-MM-DD').locale('de').endOf('day')
    this.zeitraum_update.emit(this.zeitraum)
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
  }

  aktueller_tag(){
    this.zeitraum.von = moment.default().locale('de').startOf('day')
    this.zeitraum.bis = moment.default().locale('de').endOf('day')
    this.zeitraum_update.emit(this.zeitraum)
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
    this.zumDatumWechseln()
  }

  aktuelle_woche() {
    this.zeitraum.von = moment.default().locale('de').startOf('week')
    this.zeitraum.bis = moment.default().locale('de').endOf('week')
    this.zeitraum_update.emit(this.zeitraum)
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
    this.zumDatumWechseln()
  }

  aktueller_monat() {
    this.zeitraum.von = moment.default().locale('de').startOf('month')
    this.zeitraum.bis = moment.default().locale('de').endOf('month')
    this.zeitraum_update.emit(this.zeitraum)
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
    this.zumDatumWechseln()
  }

  public zumDatumWechseln(){
    this.picker._goToDateInView(moment.default().locale('de').startOf('day'), 'month')
  }

  public selectedChange(event){
    if (!this.dateRange?.start || this.dateRange?.end) {
      this.dateRange = new DateRange<Date>(event, null);
    }
    else {
      const start = this.dateRange.start;
      const end = event;
      if (end < start) {
        this.dateRange = new DateRange<Date>(end, start);
      }
      else {
        this.dateRange = new DateRange<Date>(start, end);
      }
    }
    if (this.dateRange.end != null){
      this.aktualisieren(this.dateRange)
    }
  }

  markiereBestellungen(){
    return (date: Date) => {
      let _date = moment.default(date)
      const isoDate = _date.format('YYYY-MM-DD')
      let bestellungen = 0
      if (this.bestellung.tage[isoDate]){
        for (let index in this.bestellung.tage[isoDate].bestellMenues){
          if (this.bestellung.tage[isoDate].bestellMenues[index].mengeAlt > 0){
            bestellungen ++
          }
        }
        if (bestellungen > 0){
          return 'special-date'
        }
        return
      }
    };
  }

  public zeitraumLaden(zeitraum) {
    this.bestellung = {
      menutexte: this.speiseplanKunde.menuetexte,
      tage: {},
    }
    let bestellhistoriePreis = 0
    for (let bestellTag in this.speiseplanBestellung.kunden[this.speiseplanKunde.bestellerId].tage){
      let unixBestellTag = moment.default(bestellTag).unix()
      let von = moment.default(zeitraum.von).unix()
      let bis = moment.default(zeitraum.bis).unix()
      if (von <= unixBestellTag && bis >= unixBestellTag){
        let tag = this.speiseplanBestellung.kunden[this.speiseplanKunde.bestellerId].tage[bestellTag]
        for (let menu_id in tag.bestellMenues) {
          let menu = tag.bestellMenues[menu_id]
          if (menu.mengeNeu == menu.mengeAlt) {
            delete tag.bestellMenues[menu_id]
          }else{
            bestellhistoriePreis += menu.gesamtBetrag.betrag
          }
        }
        if (Object.keys(tag.bestellMenues).length !== 0){
          this.bestellung.tage[bestellTag] = tag
        }
      }
    }
  }
}


