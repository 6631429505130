import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateConvert'
})
export class DateConvertPipe implements PipeTransform {

 private weekdays = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']

  transform(value: any, args?: any): any {
    const date = new Date(value);
    let weekday = this.weekdays[date.getDay()];
    let day: any = date.getDate()
    let month: any = date.getMonth() + 1

    if (day < 10){
      day = '0' + day
    }
    if (month < 10){
      month = '0' + month
    }

    if (args === 'no_weekday'){
      return day + '.' + month + '.' + date.getFullYear();
    }
    if (args === 'iso'){
      return date.getFullYear() + '-' + month + '-' + day;
    }
    if (args === 'short_year'){
      return day + '.' + month + '.' + date.getFullYear().toString().substr(-2)
    }
    if (args === 'only_weekday'){
      return weekday
    }
    return weekday + ' - ' + (day + '.' + month + '.' + date.getFullYear());
  }

}
