import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {RouterModule} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PipeModule} from "../../pipe/pipe.module";
import {MaterialModule} from '../../application/web/material.module';
import {DirectiveModule} from '../../directives/directives.module';
import {BrowserModule} from '@angular/platform-browser';
import {SeitenleisteComponent} from './seitenleiste/seitenleiste.component';
import {BestellhistorieSeitenleisteComponent} from './components/bestellhistorie/bestellhistorie.seitenleiste.component';
import {ProfilSeitenleisteComponent} from './components/profil/profil.seitenleiste.component';
import {RechtlichesSeitenleisteComponent} from './components/rechtliches/rechtliches.seitenleiste.component';
import {RegistrierungSeitenleisteComponent} from './components/registrierung/registrierung.seitenleiste.component';
import {WarenkorbSeitenleisteComponent} from './components/warenkorb/warenkorb.seitenleiste.component';
import {SeitenleisteService} from './seitenleiste.service';
import {SharedModule} from '../obs.shared/shared.module';
import {RegistrierungInfoSeitenleisteComponent} from './components/registrierung-info/registrierung-info.seitenleiste.component';
import {
  RegistrierungZwischenSeitenleisteComponent
} from './components/registrierung-zwischen/registrierung-zwischen.seitenleiste.component';
import {WebBestellhistoriePickerComponent} from '../../components/web/web-bestellhistorie_picker/web-bestellhistorie_picker.component';

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    PipeModule,
    DirectiveModule,
    ReactiveFormsModule,
    MaterialModule,
    SharedModule,
  ],
  declarations: [
    SeitenleisteComponent,
    BestellhistorieSeitenleisteComponent,
    ProfilSeitenleisteComponent,
    RechtlichesSeitenleisteComponent,
    RegistrierungSeitenleisteComponent,
    WarenkorbSeitenleisteComponent,
    RegistrierungInfoSeitenleisteComponent,
    RegistrierungZwischenSeitenleisteComponent,
    WebBestellhistoriePickerComponent,
  ],
    exports: [
        SeitenleisteComponent,
        BestellhistorieSeitenleisteComponent,
        ProfilSeitenleisteComponent,
        RechtlichesSeitenleisteComponent,
        RegistrierungSeitenleisteComponent,
        WarenkorbSeitenleisteComponent,
        RegistrierungInfoSeitenleisteComponent,
        RegistrierungZwischenSeitenleisteComponent,
        WebBestellhistoriePickerComponent,
    ],
  providers: [
    SeitenleisteService
  ]
})
export class SeitenleisteModule { }
