<div id="hauptcontent" *ngIf="bestellungen_meta">
  <div id="bestellhistorieKopf">
    <div class="bestelltag-kopf">
      <strong>Menü</strong>
      <strong>Beschreibung</strong>
      <strong>Änderung</strong>
      <strong>Menge</strong>
      <strong *ngIf="preis_anzeige">Einzelpreis</strong>
      <strong *ngIf="preis_anzeige">Summe</strong>
    </div>
    <div id="bestellhistorieKopfPlatzhalter"></div>
  </div>

  <div id="warenkorb" *ngIf="bestellungen_meta && bestellungen_meta.anzahl > 0 && bestellungen && speiseplan">
    <table *ngFor="let tag of speiseplan.menu | keyvalue">
      <tr class="bestelltag" *ngIf="tag && bestellungen.tage[tag.key]">
        <div class="bestelltag-menues">
          <speiseplanTagLabel
            [tag]="tag"
            [speiseplan]="speiseplan"
            [darstellung]="2"
          ></speiseplanTagLabel>
          <mat-accordion>
            <ng-container *ngFor="let menu of bestellungen.tage[tag.key].bestellMenues | speiseplanTagesmenus:bestellungen.tage[tag.key].bestellMenues">
              <mat-expansion-panel
                [id]="tag.key"
                class="bestellhistorieExpansionPanel"
                [expanded]="aufgeklapptesMenu === menu.menueTextId"
                (opened)="offneTageZusammenklappen(menu.menueTextId)"
                *ngIf="menu.mengeNeu || menu.mengeAlt != 0">
                <mat-expansion-panel-header class="bestelltagKopfAbstand">
                  <mat-panel-title>
                    <div class="bestellungtag-zeile">
                      <div class="bestellungtag-zeile-content">
                        <td>{{ speiseplan.menutexte[menu.menueTextId].bezeichnung }}</td>
                        <td>
                          <div [innerHTML]="speiseplan.menutexte[menu.menueTextId].menueText | bbCodeBegrenzer"></div>
                        </td>
                        <td bestellungUpdate [bestellung]="menu">{{ menu.mengeNeu - menu.mengeAlt }}</td>
                        <td>{{ menu.mengeAlt }}</td>
                        <td *ngIf="preis_anzeige">{{ menu.einzelPreis | euroPrice}}</td>
                        <td *ngIf="preis_anzeige">{{ menu.gesamtBetrag | euroPrice }}</td>
                      </div>
                      <span class="menu-fehler" *ngIf="menu.error">{{ menu.error.msgId | speiseplan_fehler }}</span>
                    </div>
                  </mat-panel-title>
                </mat-expansion-panel-header>
                <div class="bestellungtag-zeile-content-ausgeklappt">
                  <div></div>
                  <bestellungenDetails [menu]="speiseplan.menutexte[menu.menueTextId]" [mandant]="mandant"></bestellungenDetails>
                </div>
              </mat-expansion-panel>
            </ng-container>
          </mat-accordion>
        </div>
      </tr>
    </table>
  </div>
  <div id="warenkorbLeer" *ngIf="bestellungen_meta.anzahl == 0">
    <mat-icon>remove_shopping_cart</mat-icon><div>
  </div>
</div>
