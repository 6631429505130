<div id="speiseplan-bestellung">
  <div id="gesperrt">
    <mat-icon id="speiseplanBestellungGesperrt">phone</mat-icon>
  </div>
  <input
    type="number"
    [(ngModel)]="bestellung.mengeNeu"
    readonly
    class="disabled"
  />
</div>
