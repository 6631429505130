import { AfterViewInit, Directive, ElementRef, Input, Renderer2 } from "@angular/core";
import { FormGroup } from "@angular/forms";

@Directive({
    selector: '[formularInput]'
  })
  export class FormularInputDirective implements AfterViewInit{
    @Input() form

    constructor(
        private element: ElementRef
    ) {}

    ngAfterViewInit(){
        if (!this.element.nativeElement.attributes['ng-reflect-name']){
        }
    }
  }