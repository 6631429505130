import { Pipe, PipeTransform } from '@angular/core';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Pipe({
    name: 'feierpipe'
})

export class FeierPipe implements PipeTransform {
    constructor(
    ) { }

    transform(value: any, object, tag): any {
        if (Object.keys(object).length != 0) {
            for (var key in Object.keys(object)) {
                if (Object.keys(object)[key] == tag) {
                    let obj = Object.values(object)[key]
                    return Object.values(obj)[0]
                }
            }
        }
    }

}
