<div id="vertrag_partner" *ngIf="form_daten">
	<h2 *ngIf="page != 'register_form'">{{ vertrag_titel }}</h2>
	<strong class="abstand">zwischen</strong>
	<!-- Vertragspartner -->
	<ng-container *ngIf="page == 'register_info' || page == 'register_form'">
		<strong class="einrueckung">Ihnen</strong>
    <p class="abstand"><i>im folgenden Auftraggeber</i></p>
		<strong class="abstand">und</strong>
	</ng-container>
	<ng-container *ngIf="page != 'register_info' && page != 'register_form'">
		<span class="einrueckung">{{ form_daten.vertragspartner.anrede }} {{ form_daten.vertragspartner.vorname }} {{
			form_daten.vertragspartner.name }}</span>
		<span class="einrueckung">{{ form_daten.vertragspartner.strasse }}</span>
		<span class="einrueckung">{{ form_daten.vertragspartner.plz }} {{ form_daten.vertragspartner.ort }}</span>
    <p class="abstand"><i>im folgenden Auftraggeber</i></p>
		<strong class="abstand">und</strong>
	</ng-container>
	<!-- Caterer -->
	<ng-container>
		<span class="einrueckung">{{ caterer.name }}</span>
		<span class="einrueckung">{{ caterer.adresse.strasse }}</span>
		<span class="einrueckung">{{ caterer.adresse.plz }} {{ caterer.adresse.ort }}</span>
    <p class="abstand"><i>im folgenden Auftragnehmer</i></p><br>
	</ng-container>
</div>
