import { Injectable } from '@angular/core';
import { ApiCallService } from '../../../services/api/api-call.service';
import {map, switchMap} from 'rxjs/operators';
import {BehaviorSubject, zip} from 'rxjs';
import { MitteilungsService } from '../../../services/prozesse/mitteilung.service';
import { MandantenService } from '../../../services/mandanten/mandanten.service';
import {KundeService} from '../../../services/kunden/kunde.service';
import {Router} from '@angular/router';
import {DbReaderService} from '../../../services/db-reader/db-reader.service';
import { MenueSyncroSopro } from '../../../services/sopro/menue-syncro.sopro';

@Injectable()
export class SpeiseplanService {

  constructor(
    private db: DbReaderService,
    private api_call_service: ApiCallService,
    private mitteilungs_service: MitteilungsService,
    private mandant_service: MandantenService,
    private kunde_service: KundeService,
    private router: Router,
    private menueSyncro: MenueSyncroSopro
    ){
  }

  public modus = new BehaviorSubject<any>(false)
  public config = new BehaviorSubject({
    menus: {},
    bestellungen: {}
  })

  public speiseplan_zeitraum(){
    let payload = {
      'mandantId': this.mandant_service.mandant_id.getValue()
    }
    return this.mandant_service.ladeAkutelleMandantId().pipe(
      switchMap((mandantId) => {
        return this.api_call_service.request('/public', 'speiseplan/zeitraum', {
          'mandantId': mandantId
        })
      })
    )
  }

  public speiseplan_mandant(speiseplan_id:string, von:string, bis: string){
    let payload = {
      'mandantId': this.mandant_service.mandant_id.getValue(),
      'speiseplanNr': speiseplan_id,
      'von': von,
      'bis': bis
    }
    return this.api_call_service.request('/public', 'speiseplan/mandantAPI_1_5', payload)
  }

  public speiseplan_kunde(besteller_id:string, von:string, bis:string) {
    let payload = {
      'mandantId': this.mandant_service.mandant_id.getValue(),
      'bestellerId[0]': besteller_id,
      'von': von,
      'bis': bis
    }
    return this.api_call_service.request('/call', 'speiseplan/kundeAPI_1_5', payload)
    .pipe(map((response) => {return <any> response}))
    .pipe(map((speiseplan_kunde:any) => {
      let bestellungen = {}
      for (let menutextid in speiseplan_kunde.menuetexte){
        bestellungen[menutextid] = {
          menuetextid: menutextid,
          splanId: speiseplan_kunde.menuetexte[menutextid].splanId,
          zuordnung: speiseplan_kunde.menuetexte[menutextid].bestellbarWenn,
          gruppe: speiseplan_kunde.menuetexte[menutextid].menueGruppe,
          gesperrt: !speiseplan_kunde.menuetexte[menutextid].gesperrt && speiseplan_kunde.menuetexte[menutextid].menueText !== '' ? false : true,
          mehrfachbestellbar: speiseplan_kunde.menuetexte[menutextid].mehrfachbestellbar,
          bezeichnung: speiseplan_kunde.menuetexte[menutextid].bezeichnung,
          markierung: false,
          fehler: false
        }
      }
      this.config.next({ menus: {}, bestellungen: bestellungen })
      return speiseplan_kunde
    }))
  }

  // TODO: Warum ist das nicht in einer eigenen Sopro Datei?!
  public nurbestellteMenus(mandant, speiseplan){
    const nurBestellteMenusConfig = mandant.sonderKonfiguration['nurBestelltSichtbar']
    for (let datum in speiseplan.menu){
      for (let nr in speiseplan.menu[datum].tagesMenues){
        if (nurBestellteMenusConfig.includes(Number(nr))){
          const bestellung = speiseplan.bestellung.tage[datum].bestellMenues[nr]
          if (bestellung.mengeAlt == 0 && bestellung.mengeNeu == 0){
            delete speiseplan.menu[datum].tagesMenues[nr]
            delete speiseplan.bestellung.tage[datum].bestellMenues[nr]
          }
        }
      }
    }
    return speiseplan
  }

  public get_modus(){
    let path = this.router.url
    let login: any
    this.db.all('User', 'key').subscribe(active => {
      login = active
    })
    let _mandantSubscription = this.mandant_service.mandant_get()
    let _kundenSubscription = this.kunde_service._kunde_get()
    return zip(_mandantSubscription, _kundenSubscription).pipe(
      map((apiResponse) => {
        let result = { mandant: apiResponse[0], kunde: apiResponse[1] }
        let key: any
        let value: any
        if (!login || login.length <= 1) {
          for ([key, value] of Object.entries(result.mandant.speisePlanIds)){
            if (value.splanId == path.split('/')[2]){
              if (result.mandant.speisePlanIds && value.darstellung == 3) {
                return 'monat'
              } else {
                return 'woche'
              }
            } else return 'woche'
          }
        } else if (login.length > 1) {
          if (result.kunde && result.kunde.speiseplanDarstellung == 3) {
            return 'monat'
          } else {
            return 'woche'
          }
        }
      })
    )
  }
}
