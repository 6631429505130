<div id="speiseplanSubmenu" *ngIf="data">
  <button *ngIf="data.bestellschluss && data.kunde" mat-button (click)="bestellSchlussText()">
    <mat-icon>alarm</mat-icon>
    <span class="submenueText">Bestellschlussinfo</span>
  </button>
  <button *ngIf="pdfsAktiv" mat-button (click)="speiseplanPdfs()">
    <mat-icon>picture_as_pdf</mat-icon>
    <span class="submenueText">Speiseplan PDF</span>
  </button>
</div>
