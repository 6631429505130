<div id="hauptcontent">
  <div id="bestellhistorieKopf">
    <div class="bestelltag-kopf">
      <strong>Menü</strong>
      <strong>Beschreibung</strong>
      <strong>Menge</strong>
      <strong *ngIf="preis_anzeige">Einzelpreis</strong>
      <strong *ngIf="preis_anzeige">Summe</strong>
    </div>
    <div id="bestellhistorieKopfPlatzhalter"></div>
  </div>
  <ng-container *ngIf="!bestellungLeer">
    <div id="bestellhistorie">
      <table *ngFor="let tag of bestellung.tage | keyvalue">
        <tr class="bestelltag">
          <div class="bestelltag-menues">
            <speiseplanTagLabel
              [tag]="tag"
              [speiseplan]="speiseplan"
              [darstellung]="2"
            ></speiseplanTagLabel>
            <mat-accordion>
              <ng-container  *ngFor="let menu of tag.value.bestellMenues | speiseplanTagesmenus:bestellung.tage[tag.key].bestellMenues; index as zeile">
                <mat-expansion-panel
                  class="bestellhistorieExpansionPanel"
                  [expanded]="aufgeklapptesMenu === menu.menueTextId"
                  (opened)="offneTageZusammenklappen(menu.menueTextId)"
                >
                  <mat-expansion-panel-header class="bestelltagKopfAbstand">
                    <mat-panel-title>
                      <div [class.lonely]="zeile == 0" class="bestellungtag-zeile" *ngIf="menu.mengeNeu || menu.mengeAlt != 0">
                        <div class="bestellungtag-zeile-content">
                          <td>{{ bestellung.menutexte[menu.menueTextId].bezeichnung }}</td>
                          <td>
                            <div [innerHTML]="bestellung.menutexte[menu.menueTextId].menueText | bbCodeBegrenzer"></div>
                          </td>
                          <td>{{ menu.mengeAlt }}</td>
                          <td *ngIf="preis_anzeige">{{ menu.einzelPreis | euroPrice}}</td>
                          <td *ngIf="preis_anzeige">{{ menu.gesamtBetrag | euroPrice }}</td>
                        </div>
                        <span class="menu-fehler" *ngIf="menu.error">{{ menu.error.msgId | speiseplan_fehler }}</span>
                      </div>
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  <div class="bestellungtag-zeile-content-ausgeklappt">
                    <div></div>
                    <bestellungenDetails [menu]="bestellung.menutexte[menu.menueTextId]" [mandant]="mandant"></bestellungenDetails>
                  </div>
                </mat-expansion-panel>
              </ng-container>
            </mat-accordion>
          </div>
        </tr>
      </table>
    </div>
  </ng-container>
  <ng-container  *ngIf="bestellungLeer">
    <div id="bestellhistorieLeer">
      <mat-icon>event_busy</mat-icon>
    </div>
  </ng-container>
</div>
