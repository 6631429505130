import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'keineAngaben'
})
export class KeineAngabenPipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    if (value == ''){
      return 'Keine Angaben';
    }else{
      return value;
    }
  }

}
