import {Component, Input, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

@Component({
  selector: 'speiseplanSondertag',
  templateUrl: './speiseplanSondertag.component.html',
  styleUrls: ['./speiseplanSondertag.component.css'],
})
export class SpeiseplanSondertagComponent implements OnInit {

  @Input('feiertag') feiertag
  @Input('zustellpause') zustellpause

  constructor(
    public dialogRef: MatDialogRef<SpeiseplanSondertagComponent>
  ) { }

  ngOnInit() {
    // for (var index in this.speiseplan.feiertag) {
    //   const von = this.speiseplan.zustellpause[index].von;
    //   const bis = this.speiseplan.zustellpause[index].bis;
    //   if (bis !== '1970-01-01' && this.aktuellesDatum <= bis && this.aktuellesDatum >= von) {
    //     this.zustellPause = this.speiseplan.zustellpause[index]
    //   }
    //   if ((bis == '1970-01-01') && (this.aktuellesDatum >= von)) {
    //     this.zustellPause = this.speiseplan.zustellpause[index]
    //   }
    // }
    // for (var index in this.speiseplan.zustellpause) {
    //   const von = this.speiseplan.zustellpause[index].von;
    //   const bis = this.speiseplan.zustellpause[index].bis;
    //   if (bis !== '1970-01-01' && this.aktuellesDatum <= bis && this.aktuellesDatum >= von) {
    //     this.zustellPause = this.speiseplan.zustellpause[index]
    //   }
    //   if ((bis == '1970-01-01') && (this.aktuellesDatum >= von)) {
    //     this.zustellPause = this.speiseplan.zustellpause[index]
    //   }
    // }
  }

  public schliesseDialog(){
    this.dialogRef.close();
  }
}
