<div id="faqContainer" *ngIf="dataSource">
  <mat-form-field>
    <div class="suche">
      <mat-icon>search</mat-icon>
      <input matInput (keyup)="applyFilter($event)" #input id="inputFilter">
      <mat-icon *ngIf="dataSource.filter != ''" (click)="clearFilter(); accordion.closeAll()">close</mat-icon>
    </div>
  </mat-form-field>
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 faq-table"
         [class.tableWba]="detectedPlattform != 'WEB'">
    <ng-container matColumnDef="position">
      <th mat-header-cell *matHeaderCellDef>FAQ Themen</th>
      <mat-accordion multi *matCellDef="let element; let i = index">
        <mat-expansion-panel mat-cell [class.panelWba]="detectedPlattform != 'WEB'" [expanded]="openPanel(element.name)" [id]="getPanelId(i)">
          <mat-expansion-panel-header>
            <mat-panel-title [innerHTML]="'<strong>' + element.name + '</strong>'" class="faqTitel">
            </mat-panel-title>
            <mat-panel-description>
            </mat-panel-description>
          </mat-expansion-panel-header>
          <p *ngIf="element.name != 'Installationsanleitung'" class="contentblock"
             [innerHTML]="element.content | bbCode | img | trustHTML | async"></p>
          <div *ngIf="element.name == 'Installationsanleitung'">
            <app-pwa-install></app-pwa-install>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    <tr class="mat-row" *matNoDataRow>
      <td class="mat-cell" colspan="4">Kein Thema zum Suchbegriff: "{{ input.value }}" gefunden.</td>
    </tr>
  </table>
</div>
