import {Directive, AfterViewInit, Input, ElementRef} from '@angular/core';

@Directive({
  selector: '[speiseplanLabel]'
})
export class SpeiseplanLabelDirective implements  AfterViewInit{
  @Input('tag') tag
  @Input('speiseplan') speiseplan
  @Input('darstellung') darstellung

  constructor(
    private element: ElementRef,
  ) { }

  ngAfterViewInit() {
    if (!this.tag.feiertag && !this.tag.zustellPause) {
      this.darstellung == 1 ? this.element.nativeElement.classList.add('vertikal') : this.element.nativeElement.classList.add('horizontal')
    }

    if (this.tag.feiertag) {
      this.darstellung == 1 ? this.element.nativeElement.classList.add('feiertag') : this.element.nativeElement.classList.add('horizontal')
      this.element.nativeElement.childNodes[1].title = this.tag.feiertag.bezeichnung
    }
    if (this.tag.zustellPause) {
      this.darstellung == 1 ? this.element.nativeElement.classList.add('zustellpause') : this.element.nativeElement.classList.add('horizontal')
      this.element.nativeElement.childNodes[1].title = this.tag.zustellPause.grund
    }
  }
}
