<web-ladeanzeige></web-ladeanzeige>
<div id="web">
  <div id="webHeader" [class.noNavigation] = "!navigation">
    <div class="maxWebContent">
      <web-toolbar></web-toolbar>
    </div>
  </div>
  <div id="webDialog" class="maxWebContent">
    <div id="webContent" [class.noNavigation] = "!navigation">
      <web-mitteilungen></web-mitteilungen>
      <router-outlet></router-outlet>
    </div>
    <div id="webSeitenleiste" *ngIf="seitenleiste">
      <app-seitenleiste></app-seitenleiste>
    </div>
  </div>
  <div id="webFooter" [class.noNavigation] = "!navigation">
    <div class="maxWebContent">
      <web-footer></web-footer>
    </div>
  </div>
</div>
