import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';
import { BestellungService } from 'services/bestellung/bestellung.service';

import * as moment from 'moment';
import {environment} from '../../../../environments/environment';
import {SpeiseplanWarenkorbUpdateComponent} from '../speiseplanWarenkorbUpdate/speiseplanWarenkorbUpdate.component';
import {MitteilungsService} from '../../../../services/prozesse/mitteilung.service';
import {SpeiseplanValidationService} from '../../services/speiseplanValidation.service';
import {SpeiseplanEinzelbestellerService} from '../../services/speiseplan-einzelbesteller.service';

@Component({
  selector: 'speiseplanMenuBestellung',
  templateUrl: './speiseplanMenuBestellung.component.html',
  styleUrls: ['./speiseplanMenuBestellung.component.css'],
})
export class SpeiseplanMenuBestellungComponent implements OnInit, AfterViewChecked {

  @Input('menu') menu
  @Input('config') config
  @Input('bestellungen') bestellungen
  @Input('bestellung') bestellung
  @Input('mandant') mandant
  @Input('gesperrt') gesperrt
  public bestell_regeln
  public menuSync = false

  constructor(
    private bestellung_service: BestellungService,
    private speiseplanValidierungService: SpeiseplanValidationService,
    private mitteilungsService: MitteilungsService,
    private speiseplanEinzellbesteller: SpeiseplanEinzelbestellerService,
  ) { }

  ngOnInit() {
    if (this.mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp && this.config){
      if (this.mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp[this.config.splanId]){
        if (this.config.gruppe != this.mandant.sonderKonfiguration.Bestellung_mengenSyncByGrp[this.config.splanId].mainGrp){
          this.menuSync = true
        }
      }
    }
    if (this.bestellung_service._bestellungen.tage[this.bestellungen.datum]) {
      this.bestellung.mengeNeu = this.bestellung_service._bestellungen.tage[this.bestellungen.datum].bestellMenues[this.bestellung.menueId].mengeNeu
    } else {
      this.bestellung.mengeNeu = this.bestellung.mengeAlt
    }

    let bestellschluss = (() => {
      if (this.config.gesperrt) { return false }
      if (this.config.bezeichnung == '') { return false }
      if (moment.default(this.menu.bestellschluss).diff(moment.default()) > 0) { return false }
      return true
    })()

    let abbestellschluss = (() => {
      if (this.config.gesperrt) { return false }
      if (this.config.bezeichnung == '') { return false }
      if (moment.default(this.menu.abbestellschluss).diff(moment.default()) > 0) { return false }
      return true
    })()

    this.bestell_regeln = {
      bestellschluss: bestellschluss,
      abbestellschluss: abbestellschluss,
      min: (() => {
        if (abbestellschluss) { return this.bestellung.mengeAlt }
        return 0
      })(),
      max: (() => {
        if (this.config.mehrfachbestellbar) { return 999 }
        if (bestellschluss) { return this.bestellung.mengeAlt }
        return 1
      })()
    }
  }

  ngAfterViewChecked() {
  }

  public bestellung_plus(element) {
    this.bestellung = this.speiseplanValidierungService.menu_zuordnung(this.bestellung, this.bestellungen)
    if (!this.config.fehler) {
      this.bestellung.mengeNeu++
    }
    this.bestellung_aktualisierung()
  }

  public bestellung_minus(element) {
    this.bestellung = this.speiseplanValidierungService.menu_zuordnung(this.bestellung, this.bestellungen)
    if (!this.config.fehler) {
      this.bestellung.mengeNeu--
    }
    this.bestellung_aktualisierung()
  }

  public bestellung_einzeln() {
    if (environment.mandant_id !== '105628-LSB'){
      this.bestellungEinzelnDefault()
    }else{
      this.bestellungEinzelnLSB()
    }
  }

  private bestellungEinzelnDefault(){
    if (this.bestell_regeln.abbestellschluss &&  this.bestellung.mengeAlt != 0){
      return
    }
    if (this.bestell_regeln.bestellschluss && this.bestellung.mengeAlt == 0){
      return
    }
    this.bestellung = this.speiseplanValidierungService.menu_zuordnung(this.bestellung, this.bestellungen)

    if (!this.config.fehler) {
      if (this.bestellung.mengeNeu === 0) {
        this.bestellung.mengeNeu = 1
      } else {
        if (!this.menuSync){
          this.bestellung.mengeNeu = 0
        }
      }
    }
    this.bestellung_aktualisierung()
  }

  private bestellungEinzelnLSB(){
    if (!(this.bestell_regeln.bestellschluss && this.bestellung.mengeAlt == 0)){
      this.bestellung = this.speiseplanValidierungService.menu_zuordnung(this.bestellung, this.bestellungen)
      if (!this.config.fehler) {
        if (this.bestellung.mengeNeu === 0) {
          this.bestellung.mengeNeu = 1
        }
        if (this.bestellung.mengeNeu == this.bestellung.mengeAlt) {
          for (let _bestellung in this.bestellungen.bestellMenues) {
            if (_bestellung != this.bestellung.menueId){
              //this.bestellungen.bestellMenues[_bestellung].mengeNeu = 0
            }
          }
        }
      }
      this.bestellung_aktualisierung()
    }
  }

  public bestellung_mehrfach() {
    this.bestellung = this.speiseplanValidierungService.menu_zuordnung(this.bestellung, this.bestellungen)
    if (this.config.fehler) {
      this.bestellung.mengeNeu = 0
    }
    if (this.bestellung.mengeNeu > 999) {
      this.bestellung.mengeNeu = 999
    }
    if (this.bestell_regeln.abbestellschluss && this.bestellung.mengeNeu <= this.bestellung.mengeAlt){
      this.bestellung.mengeNeu = this.bestellung.mengeAlt
    }
    if (this.bestell_regeln.bestellschluss && this.bestellung.mengeNeu >= this.bestellung.mengeAlt){
      this.bestellung.mengeNeu = this.bestellung.mengeAlt
    }
    this.bestellung_aktualisierung()
  }

  public bestellung_aktualisierung() {
    if (!this.config.fehler) {
      this.config.markierung = false
      this.bestellungen.bestellMenues[this.bestellung.menueId].mengeNeu = this.bestellung.mengeNeu
      this.bestellung = this.speiseplanValidierungService.menu_zuordnung_2(this.bestellung, this.bestellungen)
      this.speiseplanValidierungService.menu_gruppen(this.bestellung, this.bestellungen)
      let data = {
        menu: this.bestellung,
        mengeNeu: this.bestellung.mengeNeu,
        mengeAlt: this.bestellung.mengeAlt,
        mandant: this.mandant,
        config: this.config
      }
      this.speiseplanEinzellbesteller.statusTag(Object.assign(data, {datum: this.bestellungen.datum}), this.bestellungen)
      this.mitteilungsService.snackbar.openFromComponent(SpeiseplanWarenkorbUpdateComponent, {
        duration: 4000
      })
      this.bestellung_service.warenkorb_update.next(data)
      this.bestellung_service.bestellung_setzen(this.bestellungen)
    }
  }
}
