<div *ngIf="jsonData && currentColor" class="color-sidebar-div">
  <div id="farbanpassungHeader">
    <strong>RCS - OBS "Online-Bestellsystem" - Farbkatalog</strong>
    <mat-icon (click)="toggle_sidebar()">close</mat-icon>
  </div>
  <span id="farbkatalog-info">
    Mit diesem Tool können individuelle Farbanpassungen erstellt werden.
    Durch bearbeiten der Primären und Sekundären Farbe wird über die Farbauswahl der gewünschten Farbwerte konfiguriert.
    Die eingestellten Farben werden direkt in der Anwendung für alle Bereiche übernommen.
  </span>
  <div id="farbPicker">
    <div class="farbkatalog-primär">
      <strong>Primäre Farbe</strong>
      <div class="farbkatalog-input">
        <input type="color" id="pri" [(ngModel)]="currentColor1" (input)="updateColor('currentColor1', 'currentColorHex1', '--primary')" />
        <input type="text" [(ngModel)]="currentColorHex1" (input)="updateColorFromHex('currentColor1', 'currentColorHex1', '--primary')" />
        <div class="farbkatalog-input-icon">
          <mat-icon id="primaer" class="pri" (click)="kopiereFarbe('currentColor1', 'primaer')">content_copy</mat-icon>
          <mat-icon class="pri" (click)="bearbeiten('pri')">create</mat-icon>
          <mat-icon class="pri" (click)="reset('pri')">replay</mat-icon>
        </div>
      </div>
    </div>

    <div class="farbkatalog-sekundär">
      <strong>Sekundäre Farbe</strong>
      <div class="farbkatalog-input">
        <input type="color" id="sec" [(ngModel)]="currentColor2" (input)="updateColor('currentColor2', 'currentColorHex2', '--secondary')" />
        <input type="text" [(ngModel)]="currentColorHex2" (input)="updateColorFromHex('currentColor2', 'currentColorHex2', '--secondary')" />
        <div class="farbkatalog-input-icon">
          <mat-icon id="sekundaer" class="sec" (click)="kopiereFarbe('currentColor2', 'sekundaer')">content_copy</mat-icon>
          <mat-icon class="sec" (click)="bearbeiten('sec')">create</mat-icon>
          <mat-icon class="sec" (click)="reset('sec')">replay</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
