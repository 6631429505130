<div (click)="mitteilungenService.dialogOpen()" [class.web]="environment.type == 'web'">
  <div id="mitteilungenIcon">
    <mat-icon
      id="mitteilungenIconNormal"
      aria-label="Wichtige Mitteilung"
      [class.web]="environment.type == 'web'"
      [class.wba]="environment.type != 'web'"
    >comment</mat-icon>
  </div>
  <span *ngIf="environment.type == 'web'">Mitteilungen</span>
</div>
