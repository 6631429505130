import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bbCode'
})
export class BbCodePipe implements PipeTransform {

  REPLACEMENTS = {
    '[b]': '<b>',
    '[/b]': '</b>',
    '[u]': '<u>',
    '[/u]': '</u>',
    '[h]': '<sup>',
    '[/h]': '</sup>',
    '[v]': '<span class="menueTextCustom">',
    '[/v]': '</span>',
    '[br]': '<br>',
    '[i]': '<i>',
    '[/i]': '</i>'
}


  transform(value: any, args?: any): any {
    for (let search in this.REPLACEMENTS) {
      value = value.split(search).join(this.REPLACEMENTS[search])
    }
    if (args == 'short' && value.length > 70){
      return value.substr(0, 55) + '...<strong>mehr</strong>'
    }
    return value
  }

}
