import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipeModule} from '../../pipe/pipe.module';
import {DirectiveModule} from '../../directives/directives.module';
import {BestellungenDetailsComponent} from './component/bestellungenDetails/bestellungenDetails.component';
import {BbStylingBegrenzerPipe} from './pipe/bbStylingBegrenzer.pipe';
import {BestellungUpdateDirective} from './directive/bestellungUpdate.directive';

@NgModule({
  declarations: [
    BestellungenDetailsComponent,
    BbStylingBegrenzerPipe,
    BestellungUpdateDirective,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    RouterModule,
    FormsModule,
    PipeModule,
    DirectiveModule,
    ReactiveFormsModule,
  ],
  exports: [
    BestellungenDetailsComponent,
    BbStylingBegrenzerPipe,
    BestellungUpdateDirective,
  ],
  providers: [],
})
export class BestellungenModule {
}
