import { Injectable } from '@angular/core';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Router} from '@angular/router';
import registrierungConfig from '../../configs/registrierung-formular.config.json';
import {SnackbarComponent} from '../../submodul/obs.shared/fragmente/snackbar/snackbar.component';

@Injectable({
  providedIn: 'root'
})
export class RegistrierungService {

  constructor(
    private matSnackbar: MatSnackBar,
    private router: Router,
  ) { }

  public menuePunkte = []

  public link_to_registration(mandant) {
    if (mandant.darstellungRegistrierung == "1"){
      const index = Object.keys(mandant.registrierFormen)[0]
      if (mandant.registrierFormen[index].registrierArt == 1){
        this.router.navigateByUrl('/register-info/' + index)
      }else{
        this.router.navigateByUrl('/register-form/' + index)
      }

    }
    if (mandant.darstellungRegistrierung == "2"){
      this.router.navigateByUrl('/register-zwischenseite')
    }
  }

  public zeigeInfoDialog(text:string){
    this.matSnackbar.openFromComponent(SnackbarComponent, {
      data: [{content: text}],
        horizontalPosition: 'center',
        verticalPosition: 'bottom',
        duration: 0,
        panelClass: ['snackbar', `info`]
    })
  }

  public erstelleFormularConfig(vertragsId, mandant){
    for (let gruppe in registrierungConfig){
      for (let feldName in registrierungConfig[gruppe]){
        Object.assign(registrierungConfig[gruppe][feldName], mandant.registrierFormen[vertragsId].eingabeFelder[feldName])
        if (feldName == 'passwort2'){
          Object.assign(registrierungConfig[gruppe][feldName], mandant.registrierFormen[vertragsId].eingabeFelder['passwort'])
          registrierungConfig[gruppe][feldName].name = 'passwort2'
        }
        if (feldName == 'rechtsZustimmung'){
          let text = 'Hiermit bestätige ich, dass ich die '
          if (mandant.betreiberDaten.agb !== ''){ text += 'AGB, ' }
          if (mandant.betreiberDaten.wiederruf !== ''){ text += 'Widerrufserklärung und die ' }
          text += 'Datenschutzbestimmung akzeptiere.'
          registrierungConfig[gruppe][feldName].text = text
        }
        if (registrierungConfig[gruppe][feldName].options.length > 0){
          registrierungConfig[gruppe][feldName].type = "select"
        }
      }
    }
    return registrierungConfig
  }

  public menuPunkt(punkt){
    this.menuePunkte.push(punkt)
  }

  public menuPunktCheck(title){
    if (this.menuePunkte.includes(title)){
      return false
    } else {
      return true
    }
  }
}
