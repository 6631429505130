import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bestellhistoriePreis'
})

export class BestellhistoriePreisPipe implements PipeTransform {
    constructor(
    ) { }

    transform(value: any): any {
      value = value.toFixed(2)
      value = value.replace('.', ',')
      return value
    }
}
