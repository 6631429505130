<div id="speiseplanDetail" *ngIf="menu && mandant">
  <ng-container *ngIf="detectedPlattform == 'web'">
    <div id="speiseplanKundeWerbebanner" *ngIf="kunde">
      <werbebanner></werbebanner>
    </div>
  </ng-container>
  <div class="speiseplan-details-titel">
    <strong>{{ menu.bezeichnung }}</strong>
    <strong *ngIf="mandant.preisPositionInSplan !== 'none'">{{ menu.preis | euroPrice }}</strong>
  </div>
  <div id="speiseplanDetailsContent" [class.mobil]="detectedPlattform == 'mobil'">
    <div class="speiseplan-details-beschreibung details-abschnitt" [innerHTML]="menu.menueText | bbCode"></div>
    <ng-container *ngIf="menuAIZ">
      <sup *ngIf="menuAIZ.length != 0" [innerHTML]="menuAIZ"></sup>
    </ng-container>
    <ng-container *ngIf="menuZusatz">
      <div id="menuZusatz" [innerHTML]="menuZusatz | img | async"></div>
    </ng-container>
    <div class="speiseplan-details-bestellzeiten details-abschnitt" *ngIf="menu.bestellschluss && menu.abbestellschluss">
      <div>
        <strong>Bestellschluss</strong>
        <span class="bestellzeiten"><img src="assets/icons/bestellschluss/icon_bestellschluss.webp" />{{ menu.bestellschluss | bestellzeiten }}</span>
      </div>
      <div>
        <strong>Abbestellschluss</strong>
        <span class="bestellzeiten"><img src="assets/icons/bestellschluss/icon_abbestellschluss.webp" />{{ menu.abbestellschluss | bestellzeiten }}</span>
      </div>
    </div>
    <div *ngIf="menu.naehrwerte.length > 0" class="speiseplan-details-naehrwert details-abschnitt">
      <strong>Nährwerte</strong>
      <div class="speiseplan-details-naehrwert-tabelle">
        <div *ngIf="menu.portionsGroesse" id="speiseplanDetailsPortion">
          <span>Portionsgröße:</span>
          <span>{{ menu.portionsGroesse }}g</span>
        </div>
        <span>Nährwerte pro 100g</span>
        <div *ngFor="let naehrwert of menu.naehrwerte">
          <span>{{ naehrwert.name }}</span>
          <span>{{ naehrwert.wert }}</span>
        </div>
      </div>
    </div>
    <speiseplan-glossar [mandant]="mandant" [menu]="menu"></speiseplan-glossar>
  </div>
</div>

<div id="speiseplanDetailsFooter" mat-dialog-actions align="end" *ngIf="detectedPlattform == 'mobil'">
  <button mat-flat-button color="primary" (click)="closeDialog()">Schließen</button>
</div>
