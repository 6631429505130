import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { FormDatenService } from 'services/prozesse/form-daten.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { KundeService } from 'services/kunden/kunde.service';
import { DialogService } from 'services/prozesse/dialog.service';
import { Router } from '@angular/router';

@Component({
  selector: 'web-registrierung-kopf',
  templateUrl: './web-registrierung-kopf.component.html',
  styleUrls: ['./web-registrierung-kopf.component.css'],
})
export class WebRegistrierungKopfComponent implements OnInit, AfterViewInit {

  constructor(
    public dialog_service: DialogService,
    private mandant_service: MandantenService,
    private form_daten_service: FormDatenService,
    public kunde_service: KundeService,
		private router: Router,
  ) { }

  public form_daten
  public vertrag
  public vertrag_titel
  public vertrags_text
  public caterer
  public page

  ngOnInit() {
    this.mandant_service.mandant_get().subscribe(
      (mandant: any) => {
        this.caterer = mandant.betreiberDaten
        this.vertrag = this.router.url.split('/')[2]
        this.vertrag_titel = mandant.registrierFormen[this.vertrag].name
        this.vertrags_text = atob(mandant.registrierFormen[this.vertrag].infoTextHTML)
        this.form_daten = this.form_daten_service.form
        this.page = this.router.url.split('/')[1]
      }
    )
   }

  ngAfterViewInit() {

  }
}
