import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'error'
})
export class ErrorPipe implements PipeTransform {

  transform(error: any): string {
    if (error){
      if (error.pattern){
        return 'Ungültige Eingabe';
      }
      return error
    }
    return error
  }

}
