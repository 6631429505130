<ng-container *ngIf="formGroup && feldConfig">
  <div [formGroup]="formGroup" [class]="feldConfig.name" [id]="feldConfig.name">
    <div class="registrationFeldHeader">
      <label>
        <span>{{ feldConfig.text }}</span>
        <strong *ngIf="feldConfig && feldConfig.pflicht"> *</strong>
      </label>
      <mat-icon
        *ngIf="feldConfig.description"
        [title]="feldConfig.description"
        (click)="registrationService.zeigeInfoDialog(feldConfig.description)"
      >info</mat-icon>
    </div>
    <input
      [class.registrationFormFehler]="formGroup.controls[feldConfig.name].errors"
      [formControlName]="feldConfig.name" [tabIndex]="tabIndex"
      type="text"
    />
    <span
      class="registrationBeschreibungFehler"
      *ngIf="formGroup.controls[feldConfig.name].hasError">
      {{ formGroup.controls[feldConfig.name].errors }}
    </span>
  </div>
</ng-container>
