import { Component, ElementRef, Output, ViewChild, Input, OnInit } from '@angular/core';
import * as moment from 'moment';
import { EventEmitter } from '@angular/core';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {
  DateRange,
  DefaultMatCalendarRangeStrategy,
  MAT_DATE_RANGE_SELECTION_STRATEGY,
} from '@angular/material/datepicker';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import 'moment/locale/de';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';

@Component({
  selector: 'web-bestellhistorie_picker',
  templateUrl: './web-bestellhistorie_picker.component.html',
  styleUrls: ['./web-bestellhistorie_picker.component.css'],
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'de-DE'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
    {provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy,
    }
  ]
})
export class WebBestellhistoriePickerComponent implements OnInit{
  @Input('zeitraum') api_zeitraum
  @Output() zeitraum_update = new EventEmitter()
  @ViewChild('picker') picker: ElementRef
  public bestellung
  public zeitraum = {
    von: undefined,
    bis: undefined
  }
  public moment = moment
  public dateRange: DateRange<any> | null;
  public newTime = {
    start: undefined,
    ende: undefined
  }

  constructor(
    private seitenleistenService: SeitenleisteService,
  ) {
    moment.locale('de')
  }

  ngOnInit() {
    this.zeitraum.von = this.api_zeitraum.von
    this.zeitraum.bis = this.api_zeitraum.bis
  }

  init(zeitraum = null){
    if (!zeitraum){
      zeitraum = {
        von: moment.default().locale('de').startOf('week'),
        bis: moment.default().locale('de').endOf('week')
      }
    }
    this.zeitraum = zeitraum
    this.aktualisieren({start: zeitraum.von, end: zeitraum.bis})
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
  }

  aktualisieren(datum){
    this.zeitraum.von = moment.default(datum.start)
    this.zeitraum.bis = moment.default(datum.end)
    this.zeitraum_update.emit(this.zeitraum)
    this.seitenleistenService.emitterSeitenleistenInhalt(['speiseplan_laden', this.zeitraum])
  }

  aktuelle_woche() {
    this.zeitraum.von = moment.default().locale('de').startOf('week')
    this.zeitraum.bis = moment.default().locale('de').endOf('week')
    this.zeitraum_update.emit(this.zeitraum)
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
    this.seitenleistenService.emitterSeitenleistenInhalt(['speiseplan_laden', this.zeitraum])
  }

  aktueller_monat() {
    this.zeitraum.von = moment.default().locale('de').startOf('month')
    this.zeitraum.bis = moment.default().locale('de').endOf('month')
    this.zeitraum_update.emit(this.zeitraum)
    this.dateRange = new DateRange(moment.default(this.zeitraum.von), moment.default(this.zeitraum.bis))
    this.seitenleistenService.emitterSeitenleistenInhalt(['speiseplan_laden', this.zeitraum])
  }

  public selectedChange(event){
    if (!this.dateRange?.start || this.dateRange?.end) {
      this.dateRange = new DateRange<Date>(event, null);
    }
    else {
      const start = this.dateRange.start;
      const end = event;
      if (end < start) {
        this.dateRange = new DateRange<Date>(end, start);
      }
      else {
        this.dateRange = new DateRange<Date>(start, end);
      }
    }
    if (this.dateRange.end != null){
      this.aktualisieren(this.dateRange)
    }
  }

  markiereBestellungen(){
    return (date: Date) => {
      let _date = moment.default(date)
      const isoDate = _date.format('YYYY-MM-DD')
      let bestellungen = 0
      if (this.bestellung.tage[isoDate]){
        for (let index in this.bestellung.tage[isoDate].bestellMenues){
          if (this.bestellung.tage[isoDate].bestellMenues[index].mengeAlt > 0){
            bestellungen ++
          }
        }
        if (bestellungen > 0){
          return 'special-date'
        }
        return
      }
    };
  }
}


