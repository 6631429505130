import {AfterViewInit, Directive, ElementRef, Input, OnChanges, Renderer2, ViewContainerRef} from '@angular/core';
import { environment } from 'environments/environment';
import {DbReaderService} from '../../../services/db-reader/db-reader.service';


@Directive({
  selector: '[speiseplanTag]'
})
export class SpeiseplanTagDirective implements AfterViewInit, OnChanges{
  @Input() speiseplan_tag
  @Input() darstellung
  @Input() modus
  os = environment.type == 'web' ? 'Web' : 'App'
  constructor(
    public viewContainerRef: ViewContainerRef,
    private element: ElementRef,
    private db: DbReaderService,
    private renderer: Renderer2,
  ) { }


  ngAfterViewInit(){
    if (this.modus == 1 || this.modus == 2){
      this.modus = 'woche'
    }
    else if (this.modus == 3){
      this.modus = 'monat'
    }
    this._speiseplan_modus()
  }


  ngOnChanges(){
    this._speiseplan_modus()
  }

  _speiseplan_modus(){
    this.element.nativeElement.classList = ['speiseplan-kunde-grid']
    let laenge = Object.keys(this.speiseplan_tag.value.tagesMenues).length
    if (this.os == 'Web'){
      if (this.modus == 'woche' || this.modus == 1 || this.modus == 2){
        if (this.darstellung == 1){
          this.element.nativeElement.classList.add('vertical')
        }else{
          this.element.nativeElement.classList.add(laenge <= 5 ? 'horizontal1' : laenge <= 6 ? 'horizontal2' : laenge <= 10 ? 'horizontal3' : laenge <= 12 ? 'horizontal4' : 'horizontal5')
        }
      }
      if (this.modus == 'monat' || this.modus == 3){
        this.element.nativeElement.classList.add(laenge <= 5 ? 'horizontal1' : laenge <= 6 ? 'horizontal2' : laenge <= 10 ? 'horizontal3' : laenge <= 12 ? 'horizontal4' : 'horizontal5')
      }
    } else {
      this.db.get('tagWoche', 'Options').subscribe((value) => {
        if (value === true) {
          this.element.nativeElement.classList.add('appTag')

        } else {
          this.element.nativeElement.classList.add('appWoche')
        }
      })
    }
    this.element.nativeElement.classList.add('splanTag')
  }
}



