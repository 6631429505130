import { Directive, ElementRef, AfterViewInit } from "@angular/core";
import {HttpBackend, HttpClient} from '@angular/common/http';

@Directive({
  selector: '[gitVersion]'
})
export class GitVersionDirective implements AfterViewInit {

  constructor(
    private element: ElementRef,
    private http: HttpClient,
    private handler: HttpBackend,
  ) {
    this.http = new HttpClient(this.handler)
  }

  ngAfterViewInit() {
    this.http.get('./assets/version.txt', {responseType: 'text'}).subscribe(version => {
      console.log(version)
      this.element.nativeElement.innerHTML = version.split('/').slice(-1)
    })
  }
}
