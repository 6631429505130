<div id="registrierenVertrag">
  <div id="registrierenVertragKopf">
    <strong>zwischen</strong>

    <br>
    <ng-container *ngIf="!formGroup">
      <strong>Ihnen</strong>
    </ng-container>
    <ng-container *ngIf="formGroup">
      <span>{{ formGroup['vertragspartner_anrede'] }} {{ formGroup['vertragspartner_vorname'] }} {{ formGroup['vertragspartner_name'] }}</span>
      <span>{{ formGroup['vertragspartner_strasse'] }}</span>
      <span>{{ formGroup['vertragspartner_plz'] }} {{ formGroup['vertragspartner_ort'] }}</span>
    </ng-container>
    <br>
    <i>im folgenden Auftraggeber</i>
    <br>

    <strong>und</strong>

    <br>
    <span>{{ mandant.betreiberDaten.name }}</span>
    <span>{{ mandant.betreiberDaten.adresse.strasse }}</span>
    <span>{{ mandant.betreiberDaten.adresse.plz }} {{ mandant.betreiberDaten.adresse.ort }}</span>
    <br>
    <i>im folgenden Auftragnehmer</i>
    <br>
  </div>
  <div [innerHTML]="mandant.registrierFormen[vertragsId].infoTextHTML | base64"></div>
</div>
