import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bbCodeBegrenzer'
})

export class BbStylingBegrenzerPipe implements PipeTransform {

  REPLACEMENTS = {
    '[b]': '<b>',
    '[/b]': '</b>',
    '[u]': '<u>',
    '[/u]': '</u>',
    '[h]': '<sup>',
    '[/h]': '</sup>',
    '[v]': '<span class="menueTextCustom">',
    '[/v]': '</span>',
    '[br]': '<br>',
    '[i]': '<i>',
    '[/i]': '</i>'
  }

  transform(value: any): any {
    if (value.length > 0){
      for (let search in this.REPLACEMENTS) {
        if (search == '[br]'){
          return value.split('[br]')[0] + '...'
        }
        value = value.split(search).join(this.REPLACEMENTS[search])
      }
    }
    return value
  }
}
