import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeiseplanDarstellungDirective } from './speiseplan-darstellung.directive';
import { FormularInputDirective } from './formular-input.directive';
import { SpeiseplanLeerDirective } from './speiseplan-leer.directive';
import { RegistrierungFormularRendererDirective } from './registrierung-formular-renderer.directive';
import { RegistrierungFormularParserDirective } from './registrierung-formular-parser.directive';
import {GitVersionDirective} from './gitVersion.directive';
import {RegistrierungZusammenfassungRendererDirective} from './registrierung-zusammenfassung-renderer.directive';
import {RegistrierungZusammenfassungParserDirective} from './registrierung-zusammenfassung-parser.directive';
import {DynamicComponentDirective} from './dynamicComponent.directive';

@NgModule({
  imports: [
    CommonModule,
  ],
  declarations: [
    SpeiseplanDarstellungDirective,
    FormularInputDirective,
    SpeiseplanLeerDirective,
    RegistrierungFormularRendererDirective,
    RegistrierungFormularParserDirective,
    GitVersionDirective,
    RegistrierungZusammenfassungRendererDirective,
    RegistrierungZusammenfassungParserDirective,
    DynamicComponentDirective,
  ],
  exports: [
    SpeiseplanDarstellungDirective,
    FormularInputDirective,
    SpeiseplanLeerDirective,
    RegistrierungFormularRendererDirective,
    RegistrierungFormularParserDirective,
    GitVersionDirective,
    RegistrierungZusammenfassungRendererDirective,
    RegistrierungZusammenfassungParserDirective,
    DynamicComponentDirective,
  ]
})
export class DirectiveModule { }
