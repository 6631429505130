<div id="matDialogContent" class="dialog-content">
  <ng-container *ngIf="data.type == 'good'">
    <div id="verifyContentHeader">
      <mat-icon>mark_email_read</mat-icon>
    </div>
    <h3><strong>Wir haben Ihnen eine E-Mail geschickt</strong></h3>
    <div class="verifyContentData" >
      <p>Vielen Dank für Ihre Registrierung.<br> Bitte beachten Sie, dass der Vertrag erst gültig ist, wenn er von<br><br><strong>{{data.mandant.betreiberDaten.name}}</strong><br><br> bestätigt wurde.</p>
    </div>
  </ng-container>
  <ng-container *ngIf="data.type == 'bad'">
    <div id="verifyContentHeader">
      <mat-icon>unsubscribe</mat-icon>
    </div>
    <h3><strong>Verifizierungslink abgelaufen</strong></h3>
    <div class="verifyContentData">
      <p>Ihr Registrierungslink ist abgelaufen.<br> Das passiert, wenn Ihre Registrierung länger als 72 Stunden her ist oder Sie bereits den Link genutzt haben.<br><br>
        Sollten Sie sich nicht sicher sein, schauen Sie bitte nach, ob Sie bereits die Bestätigung erhalten haben. Andernfalls registrieren Sie sich erneut.</p>
    </div>
  </ng-container>
  <div id="matDialogContentFooter">
    <button class="option-yes" (click)="onConfirm()">Zurück zum Login</button>
  </div>
</div>
