import {AfterViewInit, Directive, ElementRef, Input, Renderer2, ViewChild, ViewContainerRef} from '@angular/core';
import {MatExpansionPanel} from '@angular/material/expansion';
import {RegistrierungService} from '../services/registrierung/registrierung.service';

@Directive({
  selector: '[appRegistrierungFormularParser]'
})
export class RegistrierungFormularParserDirective implements AfterViewInit{

  @Input() formAbschnitt
  @Input() formConfig
  @Input() viewChild

  constructor(
    private el: ElementRef,
    private renderer: Renderer2,
    private registrierungService: RegistrierungService,
  ) { }

  ngAfterViewInit() {
    let results = 0
    let alleFelder = 0
    let pflichtFelder = 0
    for (let feldName in this.formConfig[this.formAbschnitt]){
      alleFelder ++
      if (!this.formConfig[this.formAbschnitt][feldName].active){
        results ++
      }
      if (this.formConfig[this.formAbschnitt][feldName].pflicht){
        pflichtFelder ++
      }
    }
    if (results == alleFelder){
      this.el.nativeElement.style.display = 'none'
      this.registrierungService.menuPunkt(this.el.nativeElement.id)
    }
    if (pflichtFelder > 0){
      if (this.viewChild){
        this.viewChild.open()
      }
      const strong = this.renderer.createElement('strong')
      this.renderer.appendChild(strong, this.renderer.createText('*'));
      this.renderer.appendChild(document.getElementById(`${this.formAbschnitt}Karte`), strong)
    }
  }
}
