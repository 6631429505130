import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'form'
})
export class FormPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    //if (feld.match(/_/g)){
  //     let split = feld.split('_')
  //     form_control.setValue(this.kunde[split[0]][split[1]])
  // }else{
  //     form_control.setValue(this.kunde[feld])
  // }
    return 'kunde.' + value
  }

}
