<ng-container *ngIf="formGroup && feldConfig">
  <div [formGroup]="formGroup" [class]="feldConfig.name">
    <div class="registrationFeldHeader">
      <label>
        <span>{{ feldConfig.text }}</span>
        <strong *ngIf="feldConfig && feldConfig.pflicht"> *</strong>
      </label>
      <mat-icon
        *ngIf="feldConfig.description"
        [title]="feldConfig.description"
        (click)="registrationService.zeigeInfoDialog(feldConfig.description)"
      >info</mat-icon>
    </div>
    <div class="passwortInputContainer">
      <input
        [class.registrationFormFehler]="formGroup.controls[feldConfig.name].errors"
        [formControlName]="feldConfig.name"
        [tabIndex]="tabIndex"
        [type]="visibility ? 'text' : 'password'"
        autocomplete="new-password"
        maxlength="20"
      />
      <mat-icon (click)="zeigePasswort()">{{visibility ? 'visibility_off' : 'visibility'}}</mat-icon>
    </div>
    <span class="registrationBeschreibungFehler" *ngIf="formGroup.controls[feldConfig.name].hasError">{{ formGroup.controls[feldConfig.name].errors | error }}</span>
  </div>
</ng-container>
