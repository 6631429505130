import {Component, Input, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {AppStateService} from 'services/app/app-state.service';
import { environment } from "environments/environment";
import {ApiCallService} from 'services/api/api-call.service';

@Component({
  selector: 'bestellungenDetails',
  templateUrl: './bestellungenDetails.component.html',
  styleUrls: ['./bestellungenDetails.component.css'],
})

export class BestellungenDetailsComponent implements OnInit {

  @Input('mandant') mandant = null
  @Input('menu') menu

  constructor(
    public platform: Platform,
    public apiCallService: ApiCallService,
  ) { }

  public modus:any = new BehaviorSubject(false)
  public detectedPlattform: string
  public menuAIZ
  public menuZusatz
  public kunde

  ngOnInit() {
    this.detectedPlattform = environment.type
  }

  slider(modus){
    let freiraum = document.getElementById('freiraum')
    if (modus == 'auf'){
      freiraum.style.width = '315px';
      this.modus = true;
    }
    else if (modus == 'zu'){
      freiraum.style.width = '50px';
      this.modus = false;
    }
  }

  menuZusatzContent() {
    let command = this.detectedPlattform == 'web' ? 'Web_Detail_Menuetextergaenzung' : 'App_Detail_Menuetextergaenzung'
    this.apiCallService.request('/public', 'content/get', {
      mandantId: environment.mandant_id ,
      'contentItemIds': JSON.stringify([]),
      zweck: command,
      lang: 'de'
    }).subscribe((result:any) => {
      this.menuZusatz = atob(result[0].content)
    })
  }
}
