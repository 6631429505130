import { Pipe, PipeTransform } from '@angular/core';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Pipe({
  name: 'menu'
})


// Meine zweite Pipe :D

export class MenuPipe implements PipeTransform {
    constructor(
        public mandant_service: MandantenService,
    ){}

  transform(mandant: any, args?: any): any {
    let name = "Speiseplan"
    if (Object.keys(mandant.speisePlanIds).length >= 2){
      name = "Speisepläne"
    }
    return name

  }

}
