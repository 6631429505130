import { AfterViewInit, Component, OnInit} from '@angular/core';
import { subscribeOn } from 'rxjs/operators';
import { KundeService } from 'services/kunden/kunde.service'

@Component({
    selector: 'web-registrierung-einrichtung',
    templateUrl: './web-registrierung-einrichtung.component.html',
    styleUrls: ['./web-registrierung-einrichtung.component.css'],
})
export class WebRegistrierungEinrichtungComponent implements OnInit, AfterViewInit {

    constructor(
        private kunde_service: KundeService,
    ) { }

    ngOnInit() {
     }

    ngAfterViewInit() { }

}
