import { AfterViewInit, Component, OnInit } from '@angular/core';
import {SeitenleisteService} from '../../seitenleiste.service';
import {Router} from '@angular/router';
import {RegistrierungService} from '../../../../services/registrierung/registrierung.service';

@Component({
	selector: 'registrierung-seitenleiste',
	templateUrl: './registrierung.seitenleiste.component.html',
	styleUrls: ['./registrierung.seitenleiste.component.css'],
})
export class RegistrierungSeitenleisteComponent implements OnInit, AfterViewInit {

  public mandant
  public vertrag
  public vertragsZustand: boolean
  public agb
  public widerruf
  public datenschutz
  public gesetzestexte = []
  public registrierungsSchritt
  public registrierungDarstellung

	constructor(
    private seitenleistenService: SeitenleisteService,
    public registrierungsService: RegistrierungService,
  ) { }

	ngOnInit() {
    this.registrierungDarstellung = this.mandant.darstellungRegistrierung
    this.agb = {name: 'Allgemeine Geschäftsbedingungen', typ: 'agb', text: this.mandant.betreiberDaten.agb}
    this.datenschutz = {name: 'Datenschutzbestimmung', type: 'datenschutz', text: this.mandant.betreiberDaten.datenschutz}
    this.widerruf = {name: 'Widerrufserklärung', type: 'widerruf', text: this.mandant.betreiberDaten.widerruf}
    let ges_alt = [this.agb, this.datenschutz, this.widerruf]
    for (let [pos, inhalt] of ges_alt.entries()) {
      if (inhalt.text != "") {
        this.gesetzestexte.push(ges_alt[pos])
      }
    }
	}

	ngAfterViewInit() {
  }

  public inhaltAktivieren(navigationsPunkt){
    this.seitenleistenService.emitterSeitenleistenInhalt({'navigation': navigationsPunkt})
  }

  public formularAbschicken(){
    this.seitenleistenService.emitterSeitenleistenInhalt({'formular': 'absenden'})
  }

  public formularVertrag(){
    this.seitenleistenService.emitterSeitenleistenInhalt({'vertrag': 'aktion'})
  }

  public formularBearbeiten(){
    this.seitenleistenService.emitterSeitenleistenInhalt({'formular': 'bearbeiten'})
  }

  public formularAbbrechen(){
    this.seitenleistenService.emitterSeitenleistenInhalt({'formular': 'abbrechen'})
  }
}
