import { Component, Input, OnInit, AfterViewChecked } from '@angular/core';

@Component({
  selector: 'speiseplanMenuTelefon',
  templateUrl: './speiseplanMenuTelefon.component.html',
  styleUrls: ['./speiseplanMenuTelefon.component.css'],
})
export class SpeiseplanMenuTelefonComponent implements OnInit, AfterViewChecked {

  @Input('bestellung') bestellung

  constructor() { }

  ngOnInit() {
    this.bestellung.mengeNeu = this.bestellung.mengeAlt
  }

  ngAfterViewChecked() {
  }
}
