<ng-container *ngIf="formGroup && feldConfig">
  <div [formGroup]="formGroup" [class]="feldConfig.name">
    <div class="registrationFeldHeader">
      <label>
        <span>{{ feldConfig.text }}</span>
        <strong *ngIf="feldConfig && feldConfig.pflicht"> *</strong>
      </label>
      <mat-icon
        *ngIf="feldConfig.description"
        [title]="feldConfig.description"
        (click)="registrationService.zeigeInfoDialog(feldConfig.description)"
      >info</mat-icon>
    </div>
    <select
      [class.registrationFormFehler]="formGroup.controls[feldConfig.name].errors"
      [formControlName]="feldConfig.name" [tabIndex]="tabIndex"
      type="text"
    >
      <option *ngFor="let option of feldConfig.options" [value]="option">{{ option }}</option>
    </select>
    <span
      class="registrationBeschreibungFehler"
      *ngIf="formGroup.controls[feldConfig.name]"
    >{{ formGroup.controls[feldConfig.name].errors | error }}</span>
  </div>
</ng-container>
