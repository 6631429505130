<div id="speiseplan-kalender" *ngIf="zeitraum">
  <div class="speiseplan-kalender-zeitraum">
    <button
      matRipple
      (click)="navigation(zeitraum_nav.zurueck)"
      [disabled]="!(zeitraum_nav.zurueck >= 0)"
      [class.web]="dP == 'web'"
      [class.mobil]="environment.type == 'mobil'"
    >
      <mat-icon aria-hidden="false" aria-label="Zeitraum zurück">chevron_left</mat-icon>
    </button>

    <div id="speiseplan-kalender-datum" (click)="dP == 'web' ? menue_sichtbarkeit(): splanService('openDialog', 'kalender')" [class.web]="dP == 'web'">
      <span *ngIf="modus == 'tag'" > {{ aktuelles_datum.start | kalenderDatum: 'dd.MM.yyyy'}} | {{ aktuelles_datum.start | datum:'wochentag'}}</span>
      <span *ngIf="modus == 'woche'" >KW {{ aktuelles_datum.kw }} | {{ aktuelles_datum.start | kalenderDatum:'dd.MM.YYYY'}} - {{ aktuelles_datum.ende | kalenderDatum:'dd.MM.YYYY'}}</span>
      <span *ngIf="modus == 'monat'" >{{ aktuelles_datum.monat | monat }}</span>
    </div>

    <speiseplan-submenu *ngIf="dP != 'web'" [kunde]="kunde" [zeitraum]="aktuelles_datum" ></speiseplan-submenu>

    <button
      matRipple
      (click)="navigation(zeitraum_nav.vor)"
      [disabled]="!zeitraum_nav.vor"
      [class.web]="dP == 'web'"
      [class.mobil]="environment.type == 'mobil'"
    >
      <mat-icon aria-hidden="false" aria-label="Zeitpunkt Vor">chevron_right</mat-icon>
    </button>
  </div>

  <kalender-picker *ngIf="dP == 'web'" [zeitraum]="zeitraum" [aktuelles_datum]="aktuelles_datum" [modus]="modus"></kalender-picker>
</div>
