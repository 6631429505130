import {Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges} from '@angular/core';
import {element} from 'protractor';

@Directive({
  selector: '[appSpeiseplanLeer]'
})
export class SpeiseplanLeerDirective implements OnChanges{

  @Input('speiseplanMenu') speiseplanMenu

  constructor(
    private renderer: Renderer2,
    private element: ElementRef
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    const currentMenu = changes.speiseplanMenu.currentValue
    if (Object.keys(currentMenu).length == 0 && document.getElementsByClassName('speiseplanLeer').length == 0){
      const div = this.renderer.createElement('div')
      this.renderer.addClass(div, 'speiseplanLeer')

      const matIcon = this.renderer.createElement('mat-icon')
      this.renderer.appendChild(matIcon, this.renderer.createText('no_meals'))
      this.renderer.addClass(matIcon, 'mat-icon');
      this.renderer.addClass(matIcon, 'material-icons');
      this.renderer.appendChild(div, matIcon)
      this.renderer.appendChild(this.element.nativeElement, div)
      this.element.nativeElement.style.height = '100%'
    }
    if (Object.keys(currentMenu).length !== 0 && document.getElementsByClassName('speiseplanLeer')){
      const div = document.getElementsByClassName('speiseplanLeer')[0]
      if (div){
        this.renderer.removeChild(this.element.nativeElement, div)
      }
      this.element.nativeElement.style.height = 'unset'
    }
  }

}
