import {Component, OnInit} from '@angular/core';

@Component({
  selector: "speiseplanWarenkorbUpdate",
  templateUrl: "./speiseplanWarenkorbUpdate.component.html",
  styleUrls: ["./speiseplanWarenkorbUpdate.component.css"],
})
export class SpeiseplanWarenkorbUpdateComponent implements OnInit {
  constructor(){}

  ngOnInit() {}
}
