import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { WebLoginComponent } from 'components/web/web-login/web-login.component';
import { HomeComponent } from './../../submodul/obs.shared/componenten/home/home.component';
import { WebFehlerComponent } from 'components/web/web-fehler/web-fehler.component';
import { WebSpeiseplanKundeComponent } from 'components/web/web-speiseplan-kunde/web-speiseplan-kunde.component';
import { WebRechtComponent } from 'components/web/web-rechtliches/web-rechtliches.component';
import { KontaktComponent } from '../../submodul/obs.shared/componenten/kontakt/kontakt.component';
import { WebSpeiseplanMandantComponent } from 'components/web/web-speiseplan-mandant/web-speiseplan-mandant.component';
import { WebWarenkorbComponent } from 'components/web/web-warenkorb/web-warenkorb.component';
import { WebBestellhistorieComponent } from 'components/web/web-bestellhistorie/web-bestellhistorie.component'
import { WebProfileComponent } from 'components/web/web-profile/web-profile.component';
import { AuthGuardService } from 'services/guards/auth-guard-serivce';
import { WebBenachrichtigungenComponent } from 'components/web/web-benachrichtigungen/web-benachrichtigungen.component';
import { WebSpeiseplanOverviewComponent } from 'components/web/web-speiseplan-overview/web-speiseplan-overview.component';
import { StartupNavigationGuard } from 'services/guards/startup-navigation.guard';
import { SpeiseplanMandantGuard } from 'services/guards/speiseplan-mandant.guard';
import { WebRegistrierungInfoComponent } from 'components/web/web-registrierung/web-registrierung-info/web-registrierung-info.component';
import { WebRegistrierungEinrichtungComponent } from 'components/web/web-registrierung/web-registrierung-einrichtung/web-registrierung-einrichtung.component';
import { WebRegistrierungFormComponent } from 'components/web/web-registrierung/web-registrierung-form/web-registrierung-form.component';
import { WebRegistrierungZwischenComponent } from 'components/web/web-registrierung/web-registrierung-zwischen/web-registrierung-zwischen.component';
import { WebRegistrierungAbschlussComponent } from 'components/web/web-registrierung/web-registrierung-abschluss/web-registrierung-abschluss.component';
import { EmailVerifikationComponent } from '../../submodul/obs.shared/componenten/email-verifikation/email-verifikation.component';
import {
  SammelbestellerDialogComponent
} from '../../submodul/obs.speiseplan/sammelbesteller/sammelbestellerDialog/sammelbestellerDialog.component';

const routes: Routes = [
  {path: '', component: HomeComponent, canActivate: [StartupNavigationGuard]},
  {path: 'home', component: HomeComponent},
  {path: 'login', component: WebLoginComponent},
  {path: 'passwort-vergessen/:id', component: WebLoginComponent},
  {path: 'verify-email/:id', component: WebLoginComponent},
  {path: 'speiseplan-mandant/:id', component: WebSpeiseplanMandantComponent},
  {path: 'speiseplan-kunde', component: WebSpeiseplanKundeComponent, canActivate: [AuthGuardService]},
  {path: 'sammelbesteller', component: SammelbestellerDialogComponent},
  {path: 'warenkorb', component: WebWarenkorbComponent, canActivate: [AuthGuardService]},
  {path: 'rechtliches', component: WebRechtComponent},
  {path: 'rechtliches/:id', component: WebRechtComponent},
  {path: 'fehler', component: WebFehlerComponent},
  {path: 'kontakt', component: KontaktComponent},
  {path: 'bestellhistorie', component: WebBestellhistorieComponent, canActivate: [AuthGuardService]},
  {path: 'bestellhistorie/:zeitraum', component: WebBestellhistorieComponent, canActivate: [AuthGuardService]},
  {path: 'profil', component: WebProfileComponent, canActivate: [AuthGuardService]},
  {path: 'benachrichtigungen', component: WebBenachrichtigungenComponent, canActivate: [AuthGuardService]},
  {path: 'speiseplan-overview', component: WebSpeiseplanOverviewComponent, canActivate: [SpeiseplanMandantGuard]},
  {path: 'register-info/:id', component: WebRegistrierungInfoComponent},
  {path: 'register-einrichtung', component: WebRegistrierungEinrichtungComponent},
  {path: 'register-form/:id', component: WebRegistrierungFormComponent},
  {path: 'register-zwischenseite', component: WebRegistrierungZwischenComponent},
  {path: 'register-abschluss/:id', component: WebRegistrierungAbschlussComponent},
  {path: 'email-verifikation/:id', component: EmailVerifikationComponent},

  // redirect pfade
  {path: 'register', redirectTo: 'register-zwischenseite', pathMatch: 'full'},
  {path: 'contact', redirectTo: 'kontakt', pathMatch: 'full'},
  {path: 'reset_password/:id', component: EmailVerifikationComponent, pathMatch: 'full' },
  {path: 'verify_email/:id', component: EmailVerifikationComponent, pathMatch: 'full' },
]

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, { enableTracing: false, useHash: true })
  ],
  declarations: [],
})
export class RoutingModule { }
