import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';

@Component({
  selector: 'app-formular-text-input',
  templateUrl: './formular-text-input.component.html',
  styleUrls: ['./formular-text-input.component.css']
})
export class FormularTextInputComponent implements OnInit {

  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @Input() tabIndex
  @HostBinding('className') componentClass: string;

  constructor(
    public registrationService: RegistrierungService
  ) { }

  ngOnInit(): void {
    if (this.feldConfig){
      this.componentClass = this.feldConfig.name;
    }
  }
}
