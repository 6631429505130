import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import {BehaviorSubject, zip} from 'rxjs';
import { MandantenService } from '../mandanten/mandanten.service';
import * as moment from 'moment/moment';
import {Router} from '@angular/router';

@Injectable()
export class GuthabenAufladenSopro {

  constructor(

  ) {
  }

  public soproMandanten = [
    '104890-aubg-kita',
    '104890-aubg-frisch',
    '104890-aubg-schule',
    'rcsls',
    'rcsjg'
  ]

  mandantenCheckup(mandant){
    return this.soproMandanten.find(id => id == mandant)
  }
}
