<div id="emailVerifikationDialog" [class.mobil]="environment.type == 'mobil'">
  <div id="emailVerifikationHeader">
    <mat-icon>mark_email_read</mat-icon>
  </div>
  <div id="emailVerifikationDialogMitteilung">
    Die Rechnung wurde bei {{ data.mandant.betreiberDaten.name }} angefordert. Ihre Rechnung wird Ihnen per E-Mail zugestellt.
    Es ist zu beachten, dass der Versand 1-2 Werktage in Anspruch nehmen kann.
    Der Versand erfolgt an die hinterlegte E-Mail-Adresse vom Essenteilnehmer.
  </div>
  <div id="emailVerifikationFooter">
    <button mat-button (click)="rechnungenAnfordern()">OK</button>
  </div>
</div>
