(window as any).global = window;
(window as any).global.Buffer = require('buffer').Buffer;
(window as any).process = {};

export class ApiNeuKundeClass{

  public anmeldeZeit = ""
  public bankverbindung = new ApiBankverbindung()
  public einrichtung = new ApiEinrichtung()
  public essensteilnehmer = new ApiPerson()
  public freiText = ""
  public leistungsBeginn = ""
  public but = false
  public ipAdresse = ""
  public rechPerMail = false
  public rechtsZustimmung = false
  public rechnungskunde = new ApiPerson()
  public passwort = ""
  public passwort2 = ""
  public vertragsArt = ""
  public vertragspartner = new ApiPerson()
  public zahlungsart = ""
  public zahlintervall = ""
  public kostenzusage: ""
  public karte: ""
  public zahlungseinwilligung: ""

  constructor(vertragsArt:string, formGroup) {
    this.vertragsArt = vertragsArt
    Object.entries(formGroup).forEach((feld: any) => {
      if (feld[0].match('_')){
        let geteilterFeldname = feld[0].split('_')
        this[geteilterFeldname[0]][geteilterFeldname[1]] = feld[1]
      }else{
        if (feld[0] == 'passwort' || feld[0] == 'passwort2' ){
          this[feld[0]] = Buffer.from(feld[1], 'utf-8').toString('base64')
        }else{
          this[feld[0]] = feld[1]
        }
      }
    })
  }
}

class ApiPerson{
  public anrede = ""
  public vorname = ""
  public name = ""
  public gebTag = ""
  public telefon = ""
  public eMail = ""
  public strasse = ""
  public ort = ""
  public ortsteil = ""
  public plz = ""
  public land = ""
  constructor() {
  }
}

class ApiBankverbindung{
  public iban = ""
  public bic = ""
  public kontoinhaber = ""
}

class ApiEinrichtung{
  public info = ""
  public nameEinrichtung = ""
  public nameGruppe = ""
  constructor() {
  }
}
