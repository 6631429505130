import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'passwort'
})
export class PasswortPipe implements PipeTransform {

  transform(value: string): any {

    return value.replace(/./g, '*');
  
  }

}