import {Injectable} from '@angular/core';
import {SpeiseplanDatenService} from './speiseplan-daten.service';
import {KundeService} from '../../../services/kunden/kunde.service';
import {switchMap} from 'rxjs/internal/operators';
import {SammelbestellerFilter} from '../sammelbesteller/sammelbestellerFilter/sammelbestellerFilter.config';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class SpeiseplanSammelbestellerService {

  public sammelbestellerFilter: BehaviorSubject<SammelbestellerFilter>

  constructor(
    private kundeService: KundeService,
    private speiseplanDaten: SpeiseplanDatenService,
  ) {}

  public initFilter(){
    const sammelbestellerFilterConfig = new SammelbestellerFilter()
    return this.kundeService._kunde_get().pipe(
      switchMap((sammelbesteller:any) => {
        sammelbestellerFilterConfig.sammelbesteller = sammelbesteller
        return this.speiseplanDaten.speiseplanZeitraum(sammelbesteller.mandantId, sammelbesteller.bestellerId) }),
      switchMap(apiZeitraum => {
        sammelbestellerFilterConfig.apiZeitraum = apiZeitraum
        return this.sammelbestellerFilter = new BehaviorSubject<SammelbestellerFilter>(sammelbestellerFilterConfig)
      })
    )
  }

  public filterAktualisieren(config){
    this.sammelbestellerFilter.next(config)
  }
}

