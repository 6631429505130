import {AfterViewInit, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SammelbestellerFilter} from '../sammelbestellerFilter/sammelbestellerFilter.config';
import * as moment from 'moment/moment';
import {SpeiseplanZeitraumService} from '../../services/speiseplan-zeitraum.service';
import {SpeiseplanSammelbestellerService} from '../../services/speiseplan-sammebesteller.service';

@Component({
	selector: 'app-sammelbestellerKalenderPicker',
	templateUrl: './sammelbestellerKalenderPicker.component.html',
	styleUrls: ['./sammelbestellerKalenderPicker.component.css'],
})
export class SammelbestellerKalenderPickerComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input('sammelbestellerFilterConfig') filterConfig: SammelbestellerFilter;
  @Input('status') status;

  public zeitraum
  public zeitraumNav = {
    vor: undefined,
    zurueck: undefined
  }
  public apiZeitraum
  public aktuellesDatum: any

  constructor(
    private speiseplanZeitraum: SpeiseplanZeitraumService,
    public speiseplanSammelbestellerService: SpeiseplanSammelbestellerService,
  ) {
    moment.locale('de');
  }

	ngOnInit() {
    this.filterConfig.filterDatum = moment.utc().format('YYYY-MM-DD')
    this.apiZeitraum = this.filterConfig.apiZeitraum
    this.zeitraumNav = this.speiseplanZeitraum.ermittleZeitraum(this.filterConfig.filterDatum)
  }

	ngAfterViewInit() {
    this.speiseplanSammelbestellerService.sammelbestellerFilter.subscribe(config => {
      this.aktuellesDatum = config.filterDatum
    })
  }

  ngOnDestroy() {
  }

  public setzeStatus(){
    this.speiseplanZeitraum.status.next(this.status ? false : true)
  }

  public navigation(datum){
    // hier muss der auswahl bestätigen btn genutzt werden
    this.filterConfig.filterDatum = moment.utc(datum).format('YYYY-MM-DD')
    this.zeitraumNav = this.speiseplanZeitraum.ermittleZeitraum(datum)
  }
}
