export class Revision{
  public mandant_commands:Array<string> = [
    'mandant/get',
    'kunde/verify',
    'kunde/commit',
    'kunde/get',
    'kunde/login',
    'kunde/update',
    'kunde/validate',
    'speiseplan/zeitraum',
  ]

  public kunden_commands:Array<string> = [
    // 'kunde/update',
    'kunde/validate',
    'speiseplan/kunde',
    'bestellungen/commit',
    'bestellungen/get',
    'bestellungen/validate',
    'kunde/get',
  ]
}
