// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import {Platform} from '@angular/cdk/platform';

@Injectable()
export class MailWeiterleitungGuard  {
  constructor(
    private platform: Platform
  ) { }

  canActivate(): boolean {
    if (!this.platform.ANDROID || !this.platform.IOS){
      return true
    }
  }
}
