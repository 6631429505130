<div
  id="support"
  *ngIf="support"
  [class.support-web]="environment.type == 'web'"
  [class.support-mobil]="environment.type == 'mobil'"
>
  <div id="supportContent">
    <div id="kontakt-information" [class.full-width]="!support.mailerAktiv && support.sonderKonfiguration.contactform === 'false'">
      <div id="button_div" [innerHTML]="kontaktText" class="contentblock">
      </div>
    </div>
    <form *ngIf="support.mailerAktiv && support.sonderKonfiguration.contactform !== 'false'" id="kontakt-form" [formGroup]="form" (ngSubmit)="OnSubmitTemplateBased(form.value)">
      <div class="kontaktdaten">
        <strong>Ihre Kontaktdaten</strong>
        <div [ngClass]="environment.type == 'web' ? 'kontakt-felder-web' : 'kontakt-felder-mobil'">
          <div id="kontaktName">
            <label>Name / Firma <sup><strong>*</strong></sup></label>
            <input type="text" formControlName="name" tabindex="1">
          </div>
          <div id="kontaktStrasse">
            <label>Straße</label>
            <input type="text" formControlName="strasse" tabindex="3">
          </div>
          <div id="kontaktOrt">
            <label>PLZ / Ort</label>
            <input type="text" formControlName="ort" tabindex="5">
          </div>
          <div id="kontaktTelefon">
            <label>Telefon</label>
            <input type="text" formControlName="telefon" tabindex="4">
          </div>
          <div id="kontaktEmail">
            <label>E-Mail <sup><strong>*</strong></sup></label>
            <input type="email" formControlName="email" email tabindex="2">
          </div>
          <div kontaktKundennummer>
            <label>Kundennummer</label>
            <input type="text" formControlName="kundennummer" tabindex="6">
          </div>
        </div>
      </div>
      <div class="nachricht">
        <strong>Ihre Nachricht <sup><strong>*</strong></sup></strong>
        <br>
        <textarea id="kontakt_message" type="text" placeholder="Ihre Nachricht an uns..."
                  formControlName="nachricht" tabindex="7"></textarea>
      </div>
      <button class="kontakt_button" type="submit" [disabled]="!form.valid" (click)="onSubmit()" (click)="clear()">
        Senden *
      </button>
      <div id="hinweis_text">
        <span [class.disabled]="!form.valid">* auf Grundlage der <a (click)="datAuf()">Datenschutzerklärung</a></span>
      </div>
    </form>
  </div>
</div>
