import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {SammelbestellerDialogComponent} from './sammelbesteller/sammelbestellerDialog/sammelbestellerDialog.component';
import {SammelbestellerFilterComponent} from './sammelbesteller/sammelbestellerFilter/sammelbestellerFilter.component';
import {MaterialModule} from './material.module';
import {SpeiseplanKalenderComponent} from './einzelbesteller/speiseplan-kalender/speiseplan-kalender.component';
import { SpeiseplanSubmenuComponent, SpeiseplanSubmenuContentComponent } from '../obs.speiseplan/einzelbesteller/speiseplan-submenu/speiseplan-submenu.component';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipeModule} from '../../pipe/pipe.module';
import {DirectiveModule} from '../../directives/directives.module';
import {SpeiseplanServicesModule} from './services/speiseplan-services.module';
import {SammelbestellerKalenderComponent} from './sammelbesteller/sammelbestellerKalender/sammelbestellerKalender.component';
import {SharedModule} from '../obs.shared/shared.module';
import {
  SammelbestellerKalenderPickerComponent
} from './sammelbesteller/sammelbestellerKalenderpicker/sammelbestellerKalenderPicker.component';
import {SammelbestellerGruppeComponent} from './sammelbesteller/sammelbestellerGruppe/sammelbestellerGruppe.component';
import {
  SammelbestellerEssensteilnehmerComponent
} from './sammelbesteller/sammelbestellerEssensteilnehmer/sammelbestellerEssensteilnehmer.component';
import {SpeiseplanTagContentComponent} from './einzelbesteller/speiseplanTagContent/speiseplanTagContent.component';
import {SpeiseplanTagDirective} from './directive/speiseplanTag.directive';
import {SpeiseplanTagLabelComponent} from './einzelbesteller/speiseplanTagLabel/speiseplanTagLabel.component';
import {SpeiseplanMenuComponent} from './einzelbesteller/speiseplanMenu/speiseplanMenu.component';
import {SpeiseplanMenuService} from './services/speiseplanMenu.service';
import {SpeiseplanMenuBestellungComponent} from './einzelbesteller/speiseplanMenuBestellung/speiseplanMenuBestellung.component';
import {SpeiseplanMenuBeschreibungComponent} from './einzelbesteller/speiseplanMenuBeschreibung/speiseplanMenuBeschreibung.component';
import {SpeiseplanSondertagComponent} from './einzelbesteller/speiseplanZustellpause/speiseplanSondertag.component';
import {SpeiseplanLabelDirective} from './directive/speiseplanLabel.directive';
import {SpeiseplanService} from './services/speiseplan.service';
import {SpeiseplanValidationService} from './services/speiseplanValidation.service';
import {SpeiseplanDetailsComponent} from './einzelbesteller/speiseplanDetails/speiseplanDetails.component';
import {SpeiseplanTagIconComponent} from './einzelbesteller/speiseplanTagIcon/speiseplanTagIcon.component';
import {SpeiseplanWarenkorbUpdateComponent} from './einzelbesteller/speiseplanWarenkorbUpdate/speiseplanWarenkorbUpdate.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {SpeiseplanMenuTelefonComponent} from './sopro/speiseplanMenuTelefon/speiseplanMenuTelefon.component';
import {MenueSperreSopro} from './sopro/menuSperre.sopro';

@NgModule({
  declarations: [
    SammelbestellerDialogComponent,
    SammelbestellerFilterComponent,
    SammelbestellerKalenderComponent,
    SammelbestellerGruppeComponent,
    SammelbestellerEssensteilnehmerComponent,
    SpeiseplanKalenderComponent,
    SpeiseplanSubmenuComponent,
    SpeiseplanSubmenuContentComponent,
    SammelbestellerKalenderPickerComponent,

    SpeiseplanTagLabelComponent,
    SpeiseplanTagContentComponent,
    SpeiseplanMenuComponent,
    SpeiseplanMenuBeschreibungComponent,
    SpeiseplanMenuBestellungComponent,
    SpeiseplanSondertagComponent,
    SpeiseplanDetailsComponent,
    SpeiseplanWarenkorbUpdateComponent,

    SpeiseplanLabelDirective,
    SpeiseplanTagDirective,
    SpeiseplanTagIconComponent,

    SpeiseplanMenuTelefonComponent,
  ],
  imports: [
    BrowserModule,
    MaterialModule,
    CommonModule,
    RouterModule,
    FormsModule,
    PipeModule,
    DirectiveModule,
    ReactiveFormsModule,
    MaterialModule,
    SpeiseplanServicesModule,
    SharedModule,
    MatTooltipModule,
  ],
  exports: [
    SpeiseplanKalenderComponent,
    SpeiseplanSubmenuComponent,
    SpeiseplanSubmenuContentComponent,

    SpeiseplanTagLabelComponent,
    SpeiseplanTagContentComponent,

    SpeiseplanMenuComponent,
    SpeiseplanTagDirective,
    SpeiseplanTagIconComponent,
  ],
  providers: [
    SpeiseplanMenuService,
    SpeiseplanValidationService,
    SpeiseplanService,
    MenueSperreSopro,
  ],
})
export class SpeiseplanModule {
}
