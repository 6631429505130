import { Pipe, PipeTransform } from '@angular/core';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Pipe({
    name: 'zustellpipe'
})

export class ZustellPipe implements PipeTransform {
    constructor(
    ) { }

    transform(value: any, object, tag): any {
        if (object != null) {
            for (var obj in object) {
                var datefrom = object[obj].von;
                var dateto = object[obj].bis;
                if (dateto !== '1970-01-01' && tag <= dateto && tag >= datefrom) {
                  console.log(tag)
                  console.log(object)
                    return obj
                }
              if ((dateto == '1970-01-01') && (tag >= datefrom)) {
                console.log(obj)
                return obj
              }
            }
        }
    }

}
