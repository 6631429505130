<div id="seitenleiste_optionen">
  <div id="registrierungLinks">
    <a href="#vertrag_text" (click)="inhaltAktivieren('vertragstext')"  *ngIf="registrierungsService.menuPunktCheck('vertrag_text')"><mat-icon>keyboard_arrow_right</mat-icon>Verköstigungsvertrag</a>
    <a href="#vp" (click)="inhaltAktivieren('vertragspartner')" *ngIf="registrierungsService.menuPunktCheck('vp')"><mat-icon>keyboard_arrow_right</mat-icon>Vertragspartner</a>
    <a href="#et" (click)="inhaltAktivieren('essensteilnehmer')" *ngIf="registrierungsService.menuPunktCheck('et')"><mat-icon>keyboard_arrow_right</mat-icon>Essensteilnehmer</a>
    <a href="#rechnungskunde" (click)="inhaltAktivieren('rechnungskunde')" *ngIf="registrierungsService.menuPunktCheck('rk')"><mat-icon>keyboard_arrow_right</mat-icon>Rechnungskunde</a>
    <a href="#bankverbindung" (click)="inhaltAktivieren('bankverbindung')" *ngIf="registrierungsService.menuPunktCheck('bankverbindung')"><mat-icon>keyboard_arrow_right</mat-icon>Bankverbindung</a>
    <a href="#zahlung" (click)="inhaltAktivieren('zahlung')" *ngIf="registrierungsService.menuPunktCheck('zahlung')"><mat-icon>keyboard_arrow_right</mat-icon>Zahlung</a>
    <a href="#einrichtung" (click)="inhaltAktivieren('einrichtung')" *ngIf="registrierungsService.menuPunktCheck('einrichtung')"><mat-icon>keyboard_arrow_right</mat-icon>Einrichtung</a>
    <a href="#anmeldung" (click)="inhaltAktivieren('anmeldung')" *ngIf="registrierungsService.menuPunktCheck('anmeldung')"><mat-icon>keyboard_arrow_right</mat-icon>Anmeldung</a>
    <a href="#zusatz" (click)="inhaltAktivieren('zusatz')" *ngIf="registrierungsService.menuPunktCheck('zusatz')"><mat-icon>keyboard_arrow_right</mat-icon>Zusatzinformationen</a>
  </div>
  <div id='form-btn'>
    <button id="formularAbsenden" (click)="formularAbschicken()">{{ registrierungsSchritt == 1 ? 'Registrierung bestätigen' : 'Vertrag anbahnen' }}</button>
    <button id="formularVertrag" *ngIf="vertrag.registrierArt !== 2 && registrierungsSchritt == 1" (click)="formularVertrag()">{{vertragsZustand ? 'Vertrag minimieren' : 'Vertrag maximieren'}}</button>
    <button id="formularBearbeiten" *ngIf="registrierungsSchritt == 2" (click)="formularBearbeiten()">Registrierung bearbeiten</button>
    <!--<button id="formularZwischen" *ngIf="registrierungDarstellung == 2 && registrierungsSchritt == 1" (click)="formularZwischen()" class="form-btn">Zurück zur Auswahl</button>-->
    <button id="formularAbbrechen" (click)="formularAbbrechen()" class="form-btn">Registrierung verwerfen</button>
  </div>
  <ng-container *ngFor="let data of gesetzestexte">
    <rechtstexte *ngIf="data.text" [Titel]="data.name" [Inhalt]="data.text"></rechtstexte>
  </ng-container>
</div>
<div>
  <span class="hinweis">* = Pflichtfelder</span>
</div>
