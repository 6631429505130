import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import {zip} from 'rxjs';

@Component({
  selector: 'web-mitteilungen',
  templateUrl: './web-mitteilungen.component.html',
  styleUrls: ['./web-mitteilungen.component.css'],
})

export class WebMitteilungenComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('mitteilungen') mitteilungen: ElementRef
  @Input('art') public art
  @Input('feld_error') public feld_error

  public mitteilung: any = false;
  public kunde
  public mandant

  constructor(
    private mitteilungen_service: MitteilungsService,
    private kunde_service: KundeService,
    private mandant_service: MandantenService,
  ) { }

  ngOnInit() {
    this.mitteilungen_service.mitteilungen.subscribe((mitteilung) => {
      this.mitteilung = mitteilung
      if (mitteilung){
        if(mitteilung.typ == ("goodMsg" || "neutralMsg")){
          setTimeout(() =>{
            this.close()
          }, 10000)
        }
      }
    })
  }

  ngAfterViewInit() {
    zip(
      window.localStorage.getItem('active') ? this.kunde_service.kunde_get(): undefined,
      this.mandant_service.mandant_get(),
      this.mitteilungen_service.mitteilungen
    ).subscribe((response) => {
      this.kunde = response[0]
      this.mandant = response[1]
      this.mitteilung = response[2]
      if (this.mitteilung){
        if (this.mitteilung.art == 'dialog'){
          document.getElementById('seitenanordnung').style.height = 'calc(100% - 55px)';
        }
      }
    })
  }

  ngOnDestroy() {
    this.mitteilungen_service.mitteilungen.unsubscribe()
  }

  close() {
    if (this.mitteilung.art == 'dialog'){
      if (document.getElementById('seitenanordnung')){
        document.getElementById('seitenanordnung').style.height = 'calc(100% - 15px)';
      }
    }
    this.mitteilung = false;
    this.mitteilungen_service.mitteilungen.next(false)
  }


}
