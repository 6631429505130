import { Pipe, PipeTransform } from '@angular/core';
import { AsyncSubject } from "rxjs";
import { environment } from "../environments/environment";
import { DomSanitizer } from "@angular/platform-browser";
import { DbReaderService } from "../services/db-reader/db-reader.service";
import { ApiCallService } from 'services/api/api-call.service';

@Pipe({
  name: 'symbol'
})
export class SymbolPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
    private db: DbReaderService,
    private api_call_service: ApiCallService,
  ) {}

  transform(value: any, args?: any): any {
    let symbol_id = value
    let http_body = {
      'mandantId': environment.mandant_id,
      'fileId': symbol_id
    }

    let observer = new AsyncSubject()

    if (symbol_id === 'fnf'){
        observer.next('assets/theme/img/img_not_found.svg')
        observer.complete()
        return observer
    }

    this.db.get(symbol_id, 'Cache').subscribe(result => {
        if (result){
          let url = window.URL.createObjectURL(result)
          observer.next(this.sanitizer.bypassSecurityTrustUrl(url))
          observer.complete()
        }else{
          this.api_call_service.request('/img', 'files/get', http_body).subscribe((blob:any) => {
          let url = window.URL.createObjectURL(blob)
          let sanitized_url = this.sanitizer.bypassSecurityTrustUrl(url)
          this.db.add(blob, symbol_id, 'Cache')
          observer.next(sanitized_url)
          observer.complete()
        })
      }
    })
    return observer
  }
}
