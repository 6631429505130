import { Component, OnInit } from '@angular/core';
import {zip} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';
import { AppStateService } from 'services/app/app-state.service';
import {environment} from '../../../../environments/environment';
@Component({
  selector: 'app-registrierung-info',
  templateUrl: './registrierung-info.component.html',
  styleUrls: ['./registrierung-info.component.css']
})
export class RegistrierungInfoComponent implements OnInit {

  public vertrag
  public mandant
  public plattform = environment.type

  constructor(
    private aktivierteRoute: ActivatedRoute,
    private mandantenService: MandantenService,
    private appStateService: AppStateService,
    private router: Router
  ) {
    this.appStateService.set_page_name('Neukundenregistrierung');
    this.appStateService.set_seitenname('registrierungsInfoseite')
  }

  ngOnInit(): void {
    zip(this.aktivierteRoute.paramMap, this.mandantenService.mandant_get()).subscribe((daten:any) => {
      this.vertrag = daten[1].registrierFormen[daten[0].params['id']]
      this.mandant = daten[1]
    })
  }

  public zueruckZurZwischenseite(){
    this.router.navigateByUrl('register-zwischenseite')
  }
}
