import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {BestellhistorieSeitenleisteService} from './bestellhistorieSeitenleistenService';
import {Subscription} from 'rxjs';

@Component({
	selector: 'bestellhistorie-seitenleiste',
	templateUrl: './bestellhistorie.seitenleiste.component.html',
	styleUrls: ['./bestellhistorie.seitenleiste.component.css'],
})
export class BestellhistorieSeitenleisteComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild('bestellhistorie_picker') bestellhistorie_picker
  public api_zeitraum
  public zeitraumVorgabe = null
  public bestellung: any
  public mandant
  public bestellungGesamtpreis = 0
  private subscription: Subscription
  public preis_anzeige

	constructor(
    private bestellhistorieSeitenleistenService: BestellhistorieSeitenleisteService,
	) { }

	ngOnInit() {
    this.subscription = this.bestellhistorieSeitenleistenService.bestellhistoriePreis.subscribe(bestellhistoriePreis => {
      this.bestellungGesamtpreis = bestellhistoriePreis
    })
	}

	ngAfterViewInit() {
    this.bestellhistorie_picker.bestellung = this.bestellung
    this.bestellhistorie_picker.init(this.zeitraumVorgabe)
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
