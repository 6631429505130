import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speiseplanpreis',
  pure: false
})
export class SpeiseplanPreisPipe implements PipeTransform {

  transform(value: any, ...args: any): any {
    if (args[0] == 'none') {
      return '';
    }
    else if (args[1] == 'text' && args[0] == 'menue') {
      let waehrung = '';
      if (value.waehrung === 'EUR') {
        waehrung = '€';
      }
      let preis = value.betrag.toFixed(2) + ' ' + waehrung;
      return preis.replace('.', ',')
    }
    else {
      let waehrung = '';
      if (value.waehrung === 'EUR') {
        waehrung = '€';
      }
      let preis = value.betrag.toFixed(2) + ' ' + waehrung;
      return preis.replace('.', ',')
    }
  }

}
