import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiCallService } from 'services/api/api-call.service';

@Component({
  selector: 'speiseplan-pdf',
  templateUrl: './speiseplan-pdf.component.html',
  styleUrls: ['./speiseplan-pdf.component.css']
})
export class SpeiseplanPdfComponent implements OnInit {

  constructor(
    private api_service: ApiCallService,
  ) { }

  ngOnInit(){}

  downloadPDF(item) {
    let parameter = { path: item.value.url.split('?')[0] + '/' + item.value.internPath }
    this.api_service.request('/file', 'file', parameter).subscribe(
      (result: any) => {
        const blobUrl = URL.createObjectURL(result);
        window.open(blobUrl, '_blank').focus();
      },
      error => {
      })
    // value.splanPdfs.print()
    // je nachdem wie das mit dem Proxy gehandhabt wird, muss dann hier der Link benutzt werden um sich eine PDF ranzuholen.
  }

}
