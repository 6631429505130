import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pseudo'
})
export class PseudonymPipe implements PipeTransform {

  randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  transform(value: any, args?: any): any {
    var result = ''
    for (let i = 0; i < value.length; i++) {
      result += this.randomChars.charAt(Math.floor(Math.random() * this.randomChars.length));
    }
    return result
  }

}
