import {Component, OnDestroy, OnInit, AfterViewInit, Input} from '@angular/core';
import {Subscription} from 'rxjs';
import {MitteilungenService} from '../mitteilungen.service';
import {environment} from '../../../../../environments/environment';
import {BenachrichtigungService} from '../../../../../services/prozesse/benachrichtigung.service';

@Component({
    selector: 'mitteilungen-icon',
    templateUrl: './mitteilungen-icon.component.html',
    styleUrls: ['./mitteilungen-icon.component.css'],
})
export class MitteilungenIconComponent implements OnInit, OnDestroy, AfterViewInit {

  @Input() kunde
  public mitteilungenLink:boolean = false
  public mitteilungenWichtigBadge:boolean = false
  public environment = environment
  private mitteilungenSubscription: Subscription
  private mitteilungenUpdateSubscription: Subscription

  constructor(
    public mitteilungenService: MitteilungenService,
  ) {}

  ngOnInit(){
      this.mitteilungenService.holeMitteilungen()
    //}
  }

  ngAfterViewInit() {
    let service = this.kunde ? this.mitteilungenService.mitteilungen_kunde : this.mitteilungenService.mitteilungen_global
    this.mitteilungenSubscription = service.subscribe(mitteilungen => {
      if (mitteilungen.global || mitteilungen.mandant){
        this.mitteilungenLink = true
      }else {
        this.mitteilungenLink = false
      }
    })
    this.mitteilungenUpdateSubscription = this.mitteilungenService.mitteilungenUpdate.subscribe(mitteilungen => {
      if (mitteilungen){
        if (this.mitteilungenService.pruefeMitteilungenObLeer()){
          this.mitteilungenService.dialogOpen()
        }
      }
    })
  }

  ngOnDestroy() {
    this.mitteilungenService.kundeSubscription.unsubscribe()
    this.mitteilungenSubscription.unsubscribe()
    this.mitteilungenUpdateSubscription.unsubscribe()
  }
}
