import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppComponent } from 'application/web/app.component';
import { RegistrierungModule } from "../../submodul/obs.registrierung/component/registrierung.module";
import {RoutingModule} from 'application/web/routing.module';
import {RouterModule} from '@angular/router';
import {WebModule} from 'components/web/web.module';
import {ServicesModule} from 'services/services.module';
import { PipeModule } from 'pipe/pipe.module';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { environment } from 'environments/environment';
import { ApiCallService } from 'services/api/api-call.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import {BehaviorSubject, timer} from 'rxjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MaterialModule } from './material.module';
import {SharedModule} from 'submodul/obs.shared/shared.module';
import {StateService} from '../../services/app/state.service';
import {ServiceWorkerService} from '../../services/navigation/service-worker.service';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {SeitenleisteModule} from '../../submodul/obs.seitenleiste/seitenleiste.module';
import {SpeiseplanService} from '../../submodul/obs.speiseplan/services/speiseplan.service';
import {SpeiseplanModule} from '../../submodul/obs.speiseplan/speiseplan.module';
import {ContentBlockService} from '../../services/prozesse/contentBlock.service';

// ModularesModulImport

export function init_mandant(api: ApiCallService, db: DbReaderService, mandant_s: MandantenService, state_s: StateService, contentblock: ContentBlockService) {
  return () => {
    return new Promise((resolve) => {
      let parameter = { mandantId: environment.mandant_id }
      api.request('/public', 'mandant/get', parameter).subscribe((mandant: any) => {
        mandant_s.mandant_id = new BehaviorSubject(environment.mandant_id)
        state_s.mandant.next(mandant)
        contentblock.contentBlockRevision(mandant.sonderKonfiguration.API_1_5.contentBlockListe)
        db.add(mandant, environment.mandant_id, 'Mandant')
        db.add(environment.mandant_id, 'aktiv', 'Mandant')
        timer(500).subscribe(() => resolve(true))
      })
    });
  }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
      CommonModule,
      ReactiveFormsModule,
      FormsModule,
      BrowserModule,
      BrowserAnimationsModule,
      HttpClientModule,
      RouterModule,
      RoutingModule,
      WebModule,
      ServicesModule,
      PipeModule,
      MaterialModule,
      SharedModule,
      RegistrierungModule,
      SeitenleisteModule,
      SpeiseplanModule,
      ServiceWorkerModule.register('ngsw-worker.js', {
        enabled: environment.production,
        // Register the ServiceWorker as soon as the app is stable
        // or after 30 seconds (whichever comes first).
        registrationStrategy: 'registerWhenStable:30000'
      }),
    ],
    providers: [
      ServiceWorkerService,
      { provide: APP_INITIALIZER, useFactory: init_mandant, deps: [ApiCallService, DbReaderService, MandantenService, StateService, ContentBlockService], multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
