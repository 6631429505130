import { Component, OnInit } from '@angular/core';
import { KundeService } from 'services/kunden/kunde.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';

@Component({
    selector: 'web-benachrichtigungen',
    templateUrl: './web-benachrichtigungen.component.html',
    styleUrls: ['./web-benachrichtigungen.component.css']
})
export class WebBenachrichtigungenComponent implements OnInit {

  public einrichtungs_info
  public message
  public info
  public flag
  public kunde

  constructor(
    private mitteilung_service: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
    private kunden_service: KundeService,
    ){}

  ngOnInit() {
    this.kunden_service._kunde_get().subscribe((kunde) => {
      this.kunde = kunde
      this.benachrichtigung_service.setze_benachrichtigungen(kunde)
			this.info = kunde.einrichtung.info
		})
    this.mitteilung_service.benachrichtigungen.subscribe(item => {
      this.flag = item
      if(this.flag == true) {
        let div = document.getElementById('einrichtung-texte')
        div.classList.add("border")
      }
    })

    this.message = this.mitteilung_service.mitteilungen.getValue()
  }

  read(){
    this.benachrichtigung_service.benachrichtigung_stummschalten()
    let div = document.getElementById('einrichtung-texte')
      div.classList.remove("border")
  }
}
