export class SeitenleisteInhalt {

  public compInstanz
  public compParams: Object

  constructor(compInstanz, compParams) {
    this.compInstanz = compInstanz
    this.compParams = compParams
  }

}
