<div id="bestellhistorie" *ngIf="zeitraum && api_zeitraum">
  <div class="datePicker" [class.wba]="mobil">
    <div id="zeitraumKalender" [class.wbaKalender]="mobil">
      <strong>Zeitraumauswahl</strong>
      <mat-calendar
        #picker
        *ngIf="bestellung"
        (selectedChange)="selectedChange($event)"
        [dateClass]="markiereBestellungen()"
        [maxDate]="api_zeitraum.bis"
        [minDate]="api_zeitraum.von"
        [selected]="dateRange"
        startView="month">
      </mat-calendar>
    </div>
    <div id="zeitraumOptionen" [class.wbaKalender]="mobil">
      <div id="bestellhistorie_zeitraum">
        <strong>Aktueller Zeitraum</strong>
        <div id="zeitraumInput">
          <input #input value="{{ zeitraum.von | datum }} - {{ zeitraum.bis | datum}}">
          <mat-icon (click)="aktueller_zeitraum(input.value)">check</mat-icon>
        </div>
      </div>
      <div class="zeitraumOptions">
        <button class="modus" (click)="aktueller_tag()">Aktueller Tag</button>
        <button class="modus" (click)="aktuelle_woche()">Aktuelle Woche</button>
        <button class="modus" (click)="aktueller_monat()">Aktueller Monat</button>
      </div>
    </div>
  </div>
</div>
