import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {SpeiseplanValidationService} from './speiseplanValidation.service';
import {SpeiseplanBestellenService} from './speiseplan-bestellen.service';
import {SpeiseplanStackService} from './speiseplan-stack.service';
import {SpeiseplanDatenService} from './speiseplan-daten.service';
import {SpeiseplanSammelbestellerService} from './speiseplan-sammebesteller.service';
import {SpeiseplanFilterService} from './speiseplan-filter.service';
import {SpeiseplanEinzelbestellerService} from './speiseplan-einzelbesteller.service';
import {SpeiseplanZeitraumService} from './speiseplan-zeitraum.service';
import {SpeiseplanDarstellungService} from './speiseplan-darstellung.service';

@NgModule({
  imports: [
    CommonModule,
  ],
  providers: [
    SpeiseplanValidationService,
    SpeiseplanBestellenService,
    SpeiseplanStackService,
    SpeiseplanDatenService,
    SpeiseplanSammelbestellerService,
    SpeiseplanFilterService,
    SpeiseplanEinzelbestellerService,
    SpeiseplanZeitraumService,
    SpeiseplanDarstellungService,
  ],
  declarations: [
  ]
})
export class SpeiseplanServicesModule { }
