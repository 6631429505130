import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Component, OnInit, Inject } from '@angular/core';
import {ConfirmDialogModel} from '../confirm-dialog/confirm-dialog.components';

@Component({
  selector: 'app-video-dialog',
  templateUrl:  './video-dialog.components.html',
  styleUrls: ['./video-dialog.components.css']
})
export class VideoDialogComponent implements OnInit {
  title: string;
  video: string;

  constructor(
    public dialogRef: MatDialogRef<VideoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VideoDialogModel
  ) {
    this.title = data.title
    this.video = data.video
  }

  ngOnInit() {
  }
}

export class VideoDialogModel {

  constructor(public title: string, public video: string) {
  }
}
