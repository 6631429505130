import { Component, OnInit, Input } from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {GesetzDialogComponent} from '../../dialog/gesetz-dialog/gesetz-dialog.component';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'rechtstexte',
  templateUrl: './rechtstexte.component.html',
  styleUrls: ['./rechtstexte.component.css']
})
export class RechtstexteComponent implements OnInit {

  @Input('Titel') titel: string
  @Input('Inhalt') inhalt: string

  constructor(
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
  }

  openDialog(titel, inhalt){
      const dialogRef = this.dialog.open(GesetzDialogComponent, {
        data: {
          title: titel,
          data: this.domSanitizer.bypassSecurityTrustHtml(inhalt),
          panelClass: 'mat-dialog-container',
        }
      })
  }

}
