import { Pipe, PipeTransform } from '@angular/core';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Pipe({
    name: 'gtexte'
})

export class GesetzestextePipe implements PipeTransform {
    constructor(
    ) { }

    transform(value: any): any {
       if (value == "Widerruf"){
           return "Widerrufserklärung"
       }
       return value
    }

}
