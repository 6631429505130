import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { MandantenService } from '../mandanten/mandanten.service';
import menu_config from 'configs/menu.config.json';
import { KundeService } from '../kunden/kunde.service';

@Injectable()
export class DialogService {

  public app_menu = true
  public menu_tiles: boolean = false
  public route_observer
  public menu_conf_tiles = menu_config.mandant
  public homelink: string
  private activePage = new BehaviorSubject('')
  getActivePage = this.activePage.asObservable();
  private splanbez = new BehaviorSubject('')
  getsplanbez =  this.splanbez.asObservable();
  private splanMode = new BehaviorSubject('')
  getsplanMode =  this.splanMode.asObservable();

  constructor(
    private router: Router,
    private mandant_service: MandantenService,
    private kunde_service: KundeService
  ) { }

  public menu_config(mandant, kunde) {
    if (kunde) {
      return menu_config.kunde
    }
    if (mandant) {
      let _menu_config = menu_config.mandant
      _menu_config.public = []
        for (let id in mandant.speisePlanIds) {
          _menu_config.public.push({
            name: mandant.speisePlanIds[id].bezeichnung,
            link: ['speiseplan-mandant/' + mandant.speisePlanIds[id].splanId],
            class: 'speiseplan',
            icon: "restaurant-24px.svg",
          })
        }
        return _menu_config
    }
  }

  public activePage_get(page){
    this.activePage.next(page)
  }

  public splanbez_get(value){
    this.splanbez.next(value)
  }

  public splanMode_get(mode){
    this.splanMode.next(mode)
  }

  public routes_observer(callback) {
    this.route_observer = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (event instanceof NavigationEnd) {
          callback();
        }
      })
  }
}
