import {Injectable} from '@angular/core';


@Injectable()
export class SpeiseplanStackService {

  constructor() {}

}

