import { Component, OnInit } from '@angular/core';
import { MandantenService } from '../../../../services/mandanten/mandanten.service';
import { KundeService } from '../../../../services/kunden/kunde.service';
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { map } from 'rxjs/operators'
import { MitteilungsService } from '../../../../services/prozesse/mitteilung.service'
import { timer } from 'rxjs';
import { AppStateService } from 'services/app/app-state.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {RevisionService} from '../../../../services/api/revision.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {environment} from '../../../../environments/environment';
import {Platform} from '@angular/cdk/platform';
import {BenachrichtigungService} from '../../../../services/prozesse/benachrichtigung.service';
import {GesetzDialogComponent} from '../../dialog/gesetz-dialog/gesetz-dialog.component';

@Component({
  selector: 'kontakt',
  templateUrl: './kontakt.component.html',
  styleUrls: ['./kontakt.component.css'],
})

export class KontaktComponent implements OnInit {

  constructor(
    private kunden_service: KundeService,
    private app_state_service: AppStateService,
    private mandanten_service: MandantenService,
    private mitteilungen_service: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
    private revision_service: RevisionService,
    public dialog: MatDialog,
    private domSanitizer: DomSanitizer,
    public platform: Platform,
  ) {
    this.app_state_service.set_page_name('Kontakt');
    this.app_state_service.set_seitenname('kontakt')
    this.app_state_service.set_page_navigation('normal');
  }

  public support: any
  public kunde: any
  public form = new UntypedFormGroup({
    name: new UntypedFormControl('', Validators.required),
    kundennummer: new UntypedFormControl(''),
    strasse: new UntypedFormControl(''),
    ort: new UntypedFormControl(''),
    telefon: new UntypedFormControl(''),
    email: new UntypedFormControl('', Validators.compose([Validators.required, Validators.email])),
    nachricht: new UntypedFormControl('', Validators.required),
  })
  public datenschutz: SafeHtml
  public kontaktText: SafeHtml
  public detectedPlattform: string

  ngOnInit() {
    if (this.platform.ANDROID) {
      this.detectedPlattform = 'ANDROID'
    }
    else if (this.platform.IOS) {
      this.detectedPlattform = 'IOS'
    }
    else {
      this.detectedPlattform = 'WEB'
    }
    this.mandanten_service.mandant_get()
      .pipe(map((mandant) => { return <any>mandant }))
      .subscribe((mandant) => {
        this.support = mandant
        this.datenschutz = this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.datenschutz)
        this.kontaktText = this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.kontakt)
      })

    if (this.kunden_service.kunde) {
      let $kunde = this.kunden_service._kunde_get().subscribe((result: any) => {
        this.benachrichtigung_service.setze_benachrichtigungen(this.kunde)
        this.kunde = result
        this.form.controls.email.setValue(this.kunde.essensteilnehmer.eMail)
        this.form.controls.kundennummer.setValue(this.kunde.kundenNr)
        this.form.controls.name.setValue(this.kunde.essensteilnehmer.vorname)
        this.form.controls.ort.setValue(this.kunde.essensteilnehmer.plz + " " + this.kunde.essensteilnehmer.ort)
        this.form.controls.strasse.setValue(this.kunde.essensteilnehmer.strasse)
        this.form.controls.telefon.setValue(this.kunde.essensteilnehmer.telefon)
      })
    }
  }

  onSubmit() {
    if (this.detectedPlattform == 'WEB'){
      this.mitteilungen_service.setMitteilung('8502')
    }
    else {
      this.mitteilungen_service.set_snackbar('8502')
    }
  }

  clear(){
    timer(100).subscribe(() => {
      this.form.controls.email.setValue("")
      this.form.controls.kundennummer.setValue("")
      this.form.controls.name.setValue("")
      this.form.controls.ort.setValue("")
      this.form.controls.strasse.setValue("")
      this.form.controls.telefon.setValue("")
    })
  }

  OnSubmitTemplateBased(kunden_daten) {
    this.mitteilungen_service.setMitteilung('8502');
    this.mandanten_service.kontakt_commit(kunden_daten).subscribe(() => { })
    this.form.controls.nachricht.setValue("")
  }

  datAuf(){
    this.revision_service.revision_mandant('mandant/get')
    setTimeout(() => {
      const dialogRef = this.dialog.open(GesetzDialogComponent, {
        data: {
          title: 'Datenschutzerklärung',
          data: this.datenschutz,
          panelClass: 'mat-dialog-container',
        }
      })
    }, 100)
  }

  protected readonly environment = environment;
}
