import {AfterViewInit, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Platform} from '@angular/cdk/platform';
import {AppStateService} from '../../../../services/app/app-state.service';
import { environment } from "../../../../environments/environment";
import {ApiCallService} from '../../../../services/api/api-call.service';
import {KundeService} from '../../../../services/kunden/kunde.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ContentBlockService} from '../../../../services/prozesse/contentBlock.service';

@Component({
  selector: 'speiseplanDetails',
  templateUrl: './speiseplanDetails.component.html',
  styleUrls: ['./speiseplanDetails.component.css'],
})

export class SpeiseplanDetailsComponent implements OnInit, OnDestroy {

  @Input('mandant') mandant = null
  @Input('menuTag') menuTag
  @Input('observer') speiseplanDetailsObserver: BehaviorSubject<boolean | object>

  constructor(
    public platform: Platform,
    private app_state_service: AppStateService,
    public apiCallService: ApiCallService,
    private kundeService: KundeService,
    private dialog: MatDialog,
    private content_service: ContentBlockService,
  ) { }

	public menu = null
  public modus:any = new BehaviorSubject(false)
  public detectedPlattform: string
  public nutritionals: boolean = false
  private observerSupscription
  public menuAIZ
  public menuZusatz
  public kunde

  ngOnInit() {
    this.detectedPlattform = environment.type
    this.kundeService._kunde_get().subscribe(kunde => {
      this.kunde = kunde
      if (this.detectedPlattform != 'web'){
        if (kunde){
          this.menuAIZ = [...this.menu.allergene, ...this.menu.inhaltsstoffe, ...this.menu.zusatzstoffe];
        } else {
          this.menuAIZ = [...this.menu.allergeneIds, ...this.menu.inhaltsstoffeIds, ...this.menu.zusatzstoffeIds];
        }
        if (this.menu.menueText.includes('*')){
          this.menuZusatzContent()
        }
      }
      if (this.detectedPlattform == 'web'){
        const observerSupscription = this.speiseplanDetailsObserver.subscribe(menu => {
          this.menu = menu
          if (menu && menu['allergene']){
            this.menuAIZ = [...menu['allergene'], ...menu['inhaltsstoffe'], ...menu['zusatzstoffe']]
          }
          if (this.menu.menueText.includes('*')){
            this.menuZusatzContent()
          }
        })
      }
    })
  }

  ngOnDestroy() {
    if (this.observerSupscription){
      this.observerSupscription.unsubscribe
    }
  }

  slider(modus){
    let freiraum = document.getElementById('freiraum')
    if (modus == 'auf'){
      freiraum.style.width = '315px';
      this.modus = true;
    }
    else if (modus == 'zu'){
      freiraum.style.width = '50px';
      this.modus = false;
    }
  }

  public hide(){
    this.app_state_service.foodplan_popup = false;
  }

  menuZusatzContent() {
    let zweck = this.detectedPlattform == 'web' ? 'Web_Detail_Menuetextergaenzung' : 'App_Detail_Menuetextergaenzung'
    this.content_service.getContentBlock(zweck).subscribe(result => {
      if (result){
        this.menuZusatz = result
      }
    })
  }

  closeDialog() {
    this.dialog.closeAll()
  }
}
