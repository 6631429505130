import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateWeekday'
})
export class DateWeekdayPipe implements PipeTransform {

  private weekdays = ['SO', 'MO', 'DI', 'MI', 'DO', 'FR', 'SA']

   transform(splan_day: any): any {
     const date = new Date(splan_day.datum);
     let weekday = this.weekdays[date.getDay()];
     return `${weekday} - ${splan_day.feiertag.bezeichnung}`;
   }

}
