import { Pipe, PipeTransform, Renderer2 } from '@angular/core';

@Pipe({
  name: 'mitteilung'
})
export class MitteilungPipe implements PipeTransform {

  public value
  constructor(
		private renderer: Renderer2
  ){}

  transform(value: any, kunde: any, mandant: any, art: any): any {
    if (art != 'feld'){
      this.value = value
      if (/(<mandant>)/gm.test(value)){
        value = value.split('<mandant>').join(mandant.betreiberDaten.name)
      }
      else if (/(<kunde>)/gm.test(value) && kunde){
        value = value.split('<kunde>').join(kunde.essensteilnehmer.vorname)
        this.value = value
      }
      else if (/(<Kontakt>)/gm.test(value)){
        return value.replace(/<Kontakt>/g, '<a href="/#/rechtliches?id=Kontakt">Kontakt</a>');
      }
      else if (art == 'global' || art == 'kritisch'){
        let div = this.renderer.createElement('div')
        this.renderer.addClass(div, 'großer_code')
        let h1 = this.renderer.createElement('h1')
        this.renderer.setProperty(h1, 'innerHTML', this.value.split(':')[0])
        this.renderer.appendChild(div, h1)
        return this.value
      }
      else if (art == 'dialog'){
        return this.value
      }
    }
    return value
  }
}
