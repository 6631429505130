import {Injectable} from '@angular/core';
import {async, BehaviorSubject, of} from 'rxjs';

@Injectable()
export class FarbkatalogService {

  public kunde: any = false
  public update: BehaviorSubject<any> = new BehaviorSubject(true)
  public response: any
  public mandanten = [
    'Demo',
    'rcsls',
    'rcsjg',
    'rcsmg',
    'rcsfm',
    'rcskb',
    'rcsrh',
    'rcssj',
  ]

  constructor() {}

  public hexToRgbA(hex){
    var c;
    if(/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)){
      c= hex.substring(1).split('');
      if(c.length== 3){
        c= [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c= '0x'+c.join('');
      return 'rgba('+[(c>>16)&255, (c>>8)&255, c&255].join(',')+',1)';
    }
    throw new Error('Bad Hex');
  }

}
