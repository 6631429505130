<mat-accordion>
  <mat-expansion-panel (opened)="status = true" (closed)="status = false" class="filterPanel" [expanded]="status">
    <mat-expansion-panel-header [class.filterHeaderAfter]="status" class="filterHeader" #filter (click)="filter._toggle()" >
      <mat-panel-title>
        <app-sammelbestellerKalenderPicker [sammelbestellerFilterConfig]="sammelbestellerFilterConfig" [status]="status"></app-sammelbestellerKalenderPicker>
      </mat-panel-title>
      <mat-panel-description>
        <button class="auswahlBtn" *ngIf="status" (click)="auswahlBestaetigen()">Auswahl bestätigen</button>
        <mat-icon (click)="filter._toggle()" id="filterIcon">filter_list</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <div id="sammelbestellerFilterStepper">
      <div id="filterStepTabs">
        <div matRipple (click)="setzeTab('tab1', tab1)" [class.aktiv] = "filterTab === 'tab1'" class="filterRipple">
          <div>
            <mat-icon>calendar_monthly</mat-icon>
            <span>Zeitraum</span>
          </div>
          <mat-icon *ngIf="filterTab === 'tab1'">arrow_right</mat-icon>
        </div>
        <div matRipple (click)="setzeTab('tab2', tab2)" [class.aktiv] = "filterTab === 'tab2'" class="filterRipple">
          <div>
            <mat-icon>groups</mat-icon>
            <span>Gruppe</span>
          </div>
          <mat-icon *ngIf="filterTab === 'tab2'">arrow_right</mat-icon>
        </div>
        <div matRipple (click)="setzeTab('tab3', tab3)" [class.aktiv] = "filterTab === 'tab3'" class="filterRipple">
          <div>
            <mat-icon>people</mat-icon>
            <span>Essensteilnehmer</span>
          </div>
          <mat-icon *ngIf="filterTab === 'tab3'">arrow_right</mat-icon>
        </div>
      </div>
      <div id="filterStepContent">
        <ng-container [ngTemplateOutlet]="filterTemplate"></ng-container>
      </div>
    </div>

    <ng-template #tab1>
      <app-sammelbestellerKalender [sammelbestellerFilterConfig]="sammelbestellerFilterConfig"></app-sammelbestellerKalender>
    </ng-template>
    <ng-template #tab2>
      <app-sammelbestellerGruppe [sammelbestellerFilterConfig]="sammelbestellerFilterConfig"></app-sammelbestellerGruppe>
    </ng-template>
    <ng-template #tab3>
      tab 3 content
    </ng-template>
  </mat-expansion-panel>
</mat-accordion>
