import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'pflicht'
})
export class PflichtPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value.pflicht) {
          return (value.text + '*')
    }
    return value.text
  }
}
