import { Injectable } from '@angular/core';
import { ApiCallService } from '../api/api-call.service';
import { IBestellung, IBestellzeitraum, IPreis } from 'services/interface/bestellung.interface'
import { MandantenService } from '../mandanten/mandanten.service';
import { map, switchMap } from 'rxjs/operators';
import {BehaviorSubject, zip} from 'rxjs';
import {KundeService} from '../kunden/kunde.service';
import {DbReaderService} from '../db-reader/db-reader.service';
import {SpeiseplanEinzelbestellerService} from '../../submodul/obs.speiseplan/services/speiseplan-einzelbesteller.service';

@Injectable()
export class BestellungService {

  public _bestellungen: IBestellzeitraum = {
    von: '',
    bis: '',
    tage: {},
    error: false,
    gesamtPreis: <IPreis>{ betrag: 0, waehrung: 'EUR' },
    bestellBetrag: <IPreis>{ betrag: 0, waehrung: 'EUR' }
  }
  public _bestellungen_meta = {
    anzahl: 0
  }
  public _bestellungen_anzahl = new BehaviorSubject(0)
  public _menutexte: any = {}
  public bestellungen: any = {}
  public warenkorb_update = new BehaviorSubject<any>(false)
  public neueBestellungen = new BehaviorSubject<any>(false)

  constructor(
    private api_call_service: ApiCallService,
    private mandant_service: MandantenService,
    private kundenService: KundeService,
    private dbReaderService: DbReaderService,
    private einzelBestellerService: SpeiseplanEinzelbestellerService,
  ) {
  }

  public bestellung_kunde(besteller_id: string, von: string, bis: string) {
    let payload = {
      'mandantId': this.mandant_service.mandant_id.getValue(),
      'bestellerId[0]': besteller_id,
      'von': von,
      'bis': bis
    }
    return this.api_call_service.request('/call', 'bestellungen/get', payload)
  }

  public bestellung_setzen(aktualisierung) {
    let aenderung = false
    for (let menu_id in aktualisierung.bestellMenues) {
      if (aktualisierung.bestellMenues[menu_id].mengeNeu != aktualisierung.bestellMenues[menu_id].mengeAlt) {
        aenderung = true
      }
    }

    if (aenderung) {
      this._bestellungen.tage[aktualisierung.datum] = aktualisierung
    } else {
      delete this._bestellungen.tage[aktualisierung.datum]
    }
    this._bestellungen_anzahl.next(Object.keys(this._bestellungen.tage).length)
    this._bestellungen_meta.anzahl = Object.keys(this._bestellungen.tage).length
    return this.bestellungen
  }

  public bestellungen_leeren() {
    this._bestellungen = <IBestellzeitraum>{
      von: '',
      bis: '',
      tage: {},
      error: false,
      gesamtPreis: <IPreis>{ betrag: 0, waehrung: 'EUR' },
      bestellBetrag: <IPreis>{ betrag: 0, waehrung: 'EUR' }
    }
    this._bestellungen_meta.anzahl = 0;
    this._bestellungen_anzahl.next(0)
    this.einzelBestellerService.bearbeitet.next([])
  }

  public bestellung_validate(besteller_id) {
    const bestellTage:any = Object.keys(this._bestellungen.tage)
    bestellTage.forEach((part, index, array:any) => {
      array[index] = Math.floor(new Date(part).getTime() / 1000)
    });
    const ersterBestellTag = Math.min(...bestellTage)
    const letzterBestellTag = Math.max(...bestellTage)
    this._bestellungen.von = new Date(ersterBestellTag * 1000).toISOString().split('T')[0]
    this._bestellungen.bis = new Date(letzterBestellTag * 1000).toISOString().split('T')[0]

    let _bestellaenderungen: IBestellung = {
      kunden: { [besteller_id]: this._bestellungen },
      menuetexte: this._menutexte
    }
    let parameter = {
      mandantId: this.mandant_service.mandant_id.getValue(),
      bestellerId: besteller_id,
      bestellaenderungen: JSON.stringify(_bestellaenderungen)
    }
    return this.api_call_service.request('/call', 'bestellungen/validate', parameter)
  }

  public bestellung_commit(besteller_id) {
    let _bestellungen = this._bestellungen

    return this.bestellung_kunde(besteller_id, this._bestellungen.von, this._bestellungen.bis).pipe(
      map((bestellungen: any) => { return bestellungen.kunden[besteller_id].tage }),
      switchMap((bestellungen) => {
        for (let tag in bestellungen) {
          if (!_bestellungen.tage[tag]) {
            for (let bestellmenu in bestellungen[tag].bestellMenues) {
              bestellungen[tag].bestellMenues[bestellmenu].mengeNeu = bestellungen[tag].bestellMenues[bestellmenu].mengeAlt
            }
            _bestellungen.tage[tag] = bestellungen[tag]
          }
        }
        let _bestellaenderungen: IBestellung = {
          kunden: { [besteller_id]: _bestellungen },
          menuetexte: this._menutexte
        }
        let parameter = {
          mandantId: this.mandant_service.mandant_id.getValue(),
          bestellerId: besteller_id,
          bestellaenderungen: JSON.stringify(_bestellaenderungen)
        }
        return this.api_call_service.request('/call', 'bestellungen/commit', parameter).pipe(
          map((bestellungen: any) => {
            this.bestellungenCommitAktualisiereGuthaben(bestellungen)
            return bestellungen
        }))
      }))
  }

  private bestellungenCommitAktualisiereGuthaben(bestellungen) {
    if (bestellungen){
      zip(
        this.dbReaderService.get('active', 'User'),
        this.kundenService._kunde_get()
      ).subscribe((internResponse) => {
        if (internResponse[1].guthaben){
          let kunde:any = internResponse[1]
          kunde.kundeId = internResponse[0]
          kunde.guthaben -= bestellungen.kunden[kunde.bestellerId].bestellBetrag.betrag
          console.log(kunde)
          this.kundenService.aktualisiereKundeInDerDatenbank(kunde)
        }
      })
    }
  }
}

