import {Injectable} from '@angular/core';


@Injectable()
export class SpeiseplanBestellenService {

  constructor() {}

}

