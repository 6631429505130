import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'webToolbarPubSplan',
  templateUrl: './webToolbarPubSplan.component.html',
  styleUrls: ['./webToolbarPubSplan.component.css'],
})
export class WebToolbarPubSplanComponent implements OnInit, OnDestroy {
  constructor(public router: Router) {}

  @Input('mandant') mandant: any;
  public splanName = 'Speisepläne';
  public splanLinkSelect: boolean = false;
  public speiseplanIdFromUrl: string | null = null;
  public routerSubscription: Subscription;

  ngOnInit() {
    this.setzeSpeiseplaene(this.router.url);
    this.routerSubscription = this.router.events.subscribe(routerEvent => {
      if (routerEvent instanceof NavigationEnd) {
        this.setzeSpeiseplaene(routerEvent.url);
      }
    });
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
  }

  public mandantRegistrierFormenZaehlen() {
    return Object.keys(this.mandant.speisePlanIds).length;
  }

  public setzeSpeiseplaene(link: string) {
    if (link.match('speiseplan-mandant')) {
      let arrayUrl = link.split('/');
      let speiseplanId = arrayUrl[arrayUrl.length - 1];
      this.speiseplanIdFromUrl = speiseplanId;

      let speiseplanObjekt: any = Object.values(this.mandant.speisePlanIds).find((item: any) => item.splanId === speiseplanId);

      if (speiseplanObjekt) {
        this.splanName = speiseplanObjekt.bezeichnung;
      } else {
        this.splanName = "Speiseplan";
      }
      this.splanLinkSelect = true;
    } else if (link.match('speiseplan-overview')) {
      this.splanName = 'Speisepläne';
      this.splanLinkSelect = true;
      this.speiseplanIdFromUrl = null;
    } else {
      this.splanName = 'Speisepläne';
      this.splanLinkSelect = false;
      this.speiseplanIdFromUrl = null;
    }
  }
}
