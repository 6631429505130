import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'leer'
})
export class LeerzeichenPipe implements PipeTransform {
  transform(value: string): string {
    value = value.replace(/ /g, '_');
    value = value.replace(/&/g, 'und');
    value = value.replace(/[()]/g, '');
    value = value.replace(/[.]/g, '');
    return value
  }
}
