import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SeitenleisteService} from '../../seitenleiste.service';
import {Router} from '@angular/router';

@Component({
	selector: 'registrierung-info-seitenleiste',
	templateUrl: './registrierung-info.seitenleiste.component.html',
	styleUrls: ['./registrierung-info.seitenleiste.component.css'],
})
export class RegistrierungInfoSeitenleisteComponent implements OnInit, AfterViewInit {

  @Input('mandant') mandant

	constructor(
    private seitenleistenService: SeitenleisteService,
    private router: Router,
	) { }

  public registrierungDarstellung


	ngOnInit() {
    this.registrierungDarstellung = this.mandant.darstellungRegistrierung
	}

	ngAfterViewInit() {
  }

  public formularAbbrechen(){
    this.router.navigate(['register-zwischenseite']);
  }
}
