import {ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {
  LeistungsbeginnBundeslandComponent
} from '../../submodul/obs.shared/fragmente/formular-felder/leistungsbeginnBundesland/leistungsbeginnBundesland.component';

export class RegistrierungsLeistungsBeginnRegel {

  public optionen = []
  public date
  public year

  constructor(
    private viewContainerRef: ViewContainerRef,
    private mandant
  ){
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    this.year = today.getFullYear();
    this.date = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    this.optionen.push({"land": 'Heute', "datum": (this.year + '-' + mm + '-' + dd)})
  }

  public init(componentRefStack, formGroup: FormGroup, feldConfig){
    componentRefStack['leistungsBeginn'].destroy()
    this.optionenErstellen()
    feldConfig['leistungsBeginn']['options'] = this.optionen
    componentRefStack['leistungsBeginn'] = this.viewContainerRef.createComponent(LeistungsbeginnBundeslandComponent)
    componentRefStack['leistungsBeginn'].instance.mandant = this.mandant
    componentRefStack['leistungsBeginn'].instance.formGroup = formGroup
    componentRefStack['leistungsBeginn'].instance.feldConfig = feldConfig['leistungsBeginn']
  }

  public optionenErstellen(){
    for (let option of this.mandant.sonderKonfiguration.leistungsbeginn){
      let year, month, day
      if (option[this.year]){
        [year, month, day] = option[this.year].split('-').map(Number);
      } else {
        for (let i = 1; i < option; i++) {
          const aktuellesDatum = new Date(option[i].split('-').map(Number));
          const jetzt = new Date();
          if (aktuellesDatum > jetzt) {
            [year, month, day] = option[i].split('-').map(Number);
            break;
          }
        }
      }
      if (new Date(year, month - 1, day) > this.date){
        this.optionen.push({"land" : option.land, "datum": option[this.year]})
      } else {
        if (option[this.year + 1]){
          this.optionen.push({"land" : option.land, "datum": option[this.year + 1]})
        }
      }
    }
  }
}
