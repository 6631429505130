import { AfterViewInit, Component, OnInit } from '@angular/core';
import {SeitenleisteInhalt} from '../../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {SeitenleisteService} from '../../../../submodul/obs.seitenleiste/seitenleiste.service';
import {RegistrierungZwischenSeitenleisteComponent} from '../../../../submodul/obs.seitenleiste/components/registrierung-zwischen/registrierung-zwischen.seitenleiste.component';
import {MandantenService} from '../../../../services/mandanten/mandanten.service';

@Component({
	selector: 'web-registrierung-zwischen',
	templateUrl: './web-registrierung-zwischen.component.html',
	styleUrls: ['./web-registrierung-zwischen.component.css'],
})
export class WebRegistrierungZwischenComponent implements OnInit, AfterViewInit {

	constructor(
    private mandant_service: MandantenService,
    private seitenleistenService: SeitenleisteService,
  ) { }

  public mandant

	ngOnInit() {
    this.mandant_service.mandant_get().subscribe(
      (mandant: any) => {
        this.mandant = mandant
        this.seitenleistenAufruf()
      }
    )
  }

	ngAfterViewInit() {
  }

  seitenleistenAufruf(){
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      RegistrierungZwischenSeitenleisteComponent, {
        mandant: this.mandant
      })
    )
  }
}
