import {AfterViewInit, Component, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';
import { BestellungService } from 'services/bestellung/bestellung.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import { IBestellzeitraum } from 'services/interface/bestellung.interface';
import { KundeService } from 'services/kunden/kunde.service';
import { ConfirmDialogModel, ConfirmDialogComponent } from '../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {BehaviorSubject, Subscription, timer, zip} from 'rxjs';
import {MandantenService} from '../../../services/mandanten/mandanten.service';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';
import {WarenkorbSeitenleisteComponent} from '../../../submodul/obs.seitenleiste/components/warenkorb/warenkorb.seitenleiste.component';
import {SeitenleisteInhalt} from '../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {SpeiseplanService} from '../../../submodul/obs.speiseplan/services/speiseplan.service';
import * as moment from 'moment';

@Component({
  selector: 'web-warenkorb',
  templateUrl: './web-warenkorb.component.html',
  styleUrls: ['./web-warenkorb.component.css'],
})
export class WebWarenkorbComponent implements OnInit, AfterViewInit {

  public emitterSubscription: Subscription
	public kunde: any
  public mandant
  public bestellungen: IBestellzeitraum
  public bestellungen_meta
  public menutexte
  public preis_anzeige
  public speiseplan
  public aufgeklapptesMenu
  result: string = '';
  public aktiveMenues

  constructor(
    private bestellungen_service: BestellungService,
    private kunde_service: KundeService,
    private message_service: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
    private mandant_service: MandantenService,
    private router: Router,
    private dialog: MatDialog,
    private seitenleistenService: SeitenleisteService,
    private speiseplanService: SpeiseplanService,
  ) { }

  ngOnInit() {
    this.seitenleistenService.emitterSeitenleiste = new BehaviorSubject<any>(null)
    this.emitterSubscription = this.seitenleistenService.emitterSeitenleiste.subscribe(event => {
      this.seitenLeistenTrigger(event)
    })
    zip(
      this.mandant_service.mandant_get(),
      this.kunde_service.kunde_get()
    ).subscribe((response: any) => {
      this.mandant = response[0]
      this.kunde = response[1]
      this.preis_anzeige = this.mandant.preisPositionInSplan != 'none';
      this.benachrichtigung_service.setze_benachrichtigungen(this.kunde)
      this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
      if (Object.keys(this.bestellungen_service._bestellungen.tage).length > 0) {
        let bestellungenIndex = Object.keys(this.bestellungen_service._bestellungen.tage)
        let von = moment.default(bestellungenIndex[0]).isBefore(bestellungenIndex[bestellungenIndex.length - 1]) ? bestellungenIndex[0] : bestellungenIndex[bestellungenIndex.length - 1]
        let bis = moment.default(bestellungenIndex[bestellungenIndex.length - 1]).isAfter(bestellungenIndex[0]) ? bestellungenIndex[bestellungenIndex.length - 1] : bestellungenIndex[0]
        this.speiseplanService.speiseplan_kunde(
          this.kunde.bestellerId,
          von,
          bis
        ).subscribe((apiResponse) => {
          this.speiseplan = {
            menu: apiResponse.kunden[this.kunde.kundeId].speisePlanTage,
            menutexte: apiResponse.menuetexte,
            feiertage: this.mandant.feiertage,
            zustellpause: Object.keys(this.kunde.zustellPausen).length > 0 ? this.kunde.zustellPausen : null,
          }
        })
        this.bestellungen_service.bestellung_validate(this.kunde.bestellerId)
          .pipe(map(validation => { return <any>validation }))
          .subscribe((validation) => {
            this.bestellungen = validation.kunden[this.kunde.bestellerId]
            this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
            this.menutexte = this.bestellungen_service._menutexte
            this.seitenleistenAufruf(this.bestellungen, this.bestellungen_meta, this.preis_anzeige)
            if(this.bestellungen.error){
              this.message_service.setMitteilung(this.bestellungen.error['msgId'])
            }
          }, (error) => {
          }
        )
      } else {
        setTimeout(() => {
          this.seitenleistenAufruf(null, this.bestellungen_meta, this.preis_anzeige)
        }, 100)
      }
    })
  }

  ngAfterViewInit() {
    this.bestellungen_service.neueBestellungen.subscribe(aktiv => {
      if (aktiv){
        this.ngOnInit()
        this.bestellungen_service.neueBestellungen.next(false)
      }
    })
  }

  seitenleistenAufruf(bestellungen, bestellungen_meta, preis_anzeige){
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      WarenkorbSeitenleisteComponent, {
        bestellungen: bestellungen,
        bestellungen_meta: bestellungen_meta,
        preis_anzeige: preis_anzeige,
      })
    )
  }

  public bestellung_commit() {
    this.kunde_service._kunde_get().subscribe((kunde) => {
			this.kunde = kunde
      this.benachrichtigung_service.setze_benachrichtigungen(kunde)
      this.bestellungen_service.bestellung_commit(kunde.bestellerId).subscribe((apiResponse:any) => {
        let zeitraum = { von: this.bestellungen.von, bis: this.bestellungen.bis }
        this.bestellungen_service.bestellungen_leeren()
        this.bestellungen = this.bestellungen_service._bestellungen
        this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
        this.menutexte = this.bestellungen_service._menutexte
				timer(600).subscribe(() => {
					this.message_service.setMitteilung('8401')
					this.router.navigate(['bestellhistorie', { zeitraum: JSON.stringify(zeitraum) }])
				})
      },
        (error) => {
        }
      )
    })
  }


  confirmDialog(): void {
    const message = `Möchten Sie alle Bestelländerungen aus dem Warenkorb entfernen?`;

    const dialogData = new ConfirmDialogModel("Confirm Action", message);

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      maxWidth: "400px",
      data: dialogData
    });
    dialogRef.afterClosed().subscribe(dialogResult => {
      this.result = dialogResult;
      if (dialogResult == true) {
        this.bestellung_stornieren()
      }
    });
  }

  public bestellung_stornieren() {
    this.bestellungen_service.bestellungen_leeren()
    this.bestellungen = this.bestellungen_service._bestellungen
    this.bestellungen_meta = this.bestellungen_service._bestellungen_meta
    this.menutexte = this.bestellungen_service._menutexte
    this.message_service.setMitteilung('8201')
  }

  public seitenLeistenTrigger(event){
    if (event){
      this[event]()
    }
  }

  public offneTageZusammenklappen(aktuellerTag){
    this.aufgeklapptesMenu = aktuellerTag
  }

  ngOnDestroy() {
    this.emitterSubscription.unsubscribe()
  }
}
