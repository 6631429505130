import {Injectable} from '@angular/core';
import * as moment from 'moment/moment';
import * as zeitraumKlassen from './speiseplan-kalender.class';
import {KalenderDialogComponent} from '../../../obs.shared/dialog/kalender-dialog/kalender-dialog.component';
import {DbReaderService} from 'services/db-reader/db-reader.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SpeiseplanService} from 'submodul/obs.speiseplan/services/speiseplan.service';
import {MitteilungsService} from 'services/prozesse/mitteilung.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';
import {Subject} from 'rxjs';
import {environment} from '../../../../environments/environment';

@Injectable()
export class SpeiseplanKalenderService {
  public kalenderTrigger: Subject<any> = new Subject();
  public picker
  public api_zeitraum
  public zeitraum: Array<any>
  public modus: any = ''
  public path
  public aktuelles_datum
  public object: any
  public bestellschluss
  public kunde
  public disabeled = false


  constructor(
    private db: DbReaderService,
    public dialog: MatDialog,
    private mitteilungsService: MitteilungsService,
    private snackBar: MatSnackBar,
  ) {
    moment.locale('de');
  }

  zeitraumLaden() {
    let zeitraum: Array<any> = []
    if (this.modus == 'monat') {
      let monatstart = moment.utc(this.api_zeitraum.von).startOf('month')
      let monatende = moment.utc(this.api_zeitraum.bis)
      while (monatstart.unix() < monatende.unix()) {
        zeitraum.push(new zeitraumKlassen.Zeitraum_Monat(monatstart))
        monatstart.add(1, 'month')
      }
    }
    if (this.modus == 'woche') {
      let wochenstart = moment.utc(this.api_zeitraum.von).startOf('week')
      let wochenende = moment.utc(this.api_zeitraum.bis)
      while (wochenstart.unix() < wochenende.unix()) {
        zeitraum.push(new zeitraumKlassen.Zeitraum_Woche(wochenstart))
        wochenstart.add(7, 'd')
      }
    }
    if (this.modus == 'tag') {
      let tagesanfang = moment.utc(this.api_zeitraum.von).startOf('day')
      let tagesende = moment.utc(this.api_zeitraum.bis).endOf('day')
      while (tagesanfang.unix() <= tagesende.unix()) {
        zeitraum.push(new zeitraumKlassen.Zeitraum_Tag(tagesanfang))
        tagesanfang.add(1, 'days')
      }
    }
    this.zeitraum = zeitraum
    let timestamp = this.modus === 'woche' ? moment.utc().startOf('week').unix() : this.modus === 'tag' ? moment.utc().startOf('day').unix() : moment.utc().startOf('month').unix()
    let index = this.zeitraum.findIndex(element => (element.index == timestamp))
    if (index !== -1) {
      this.aktualisieren(index)
    } else {
      this.aktWoche()
      if (environment.type != 'web'){
        this.mitteilungsService.set_snackbar('8202', 0)
      } else {
         this.mitteilungsService.setMitteilung('8202')
      }
      this.disabeled = true
    }
  }

  datumsauswahl(timestamp) {
    let index = this.zeitraum.findIndex((el) => el.index == timestamp)
    this.aktualisieren(index)
  }

  aktualisieren(_index) {
    let index = parseInt(_index)
    this.aktuelles_datum = this.zeitraum[index]
    this.kalenderTrigger.next(['aktualisieren', [this.aktuelles_datum, this.zeitraum, index]])
  }

  openDialog(type) {
    if (type == 'bestellschluss') {
    } else if (type == 'kalender') {
      this.db.get('bestellschluss', 'Cache').subscribe((value) => {
        const dialogRef = this.dialog.open(KalenderDialogComponent, {
          panelClass: 'wba-container',
          height: '500px',
          data: {
            title: 'Zeitraumauswahl',
            data: [this.zeitraum, type, this.modus, this.object, this.aktuelles_datum],
            type: type,
          }
        }).afterClosed()
          .subscribe(index => {
            if (index != null) {
              this.datumsauswahl(index)
              this.snackBar.dismiss()
            }
          })
      })
    }
  }

  aktWoche() {
    this.aktuelles_datum = this.modus === 'woche' ? new zeitraumKlassen.Zeitraum_Woche(moment.utc().toISOString()) : new zeitraumKlassen.Zeitraum_Monat(moment.utc().toISOString())
    this.kalenderTrigger.next(['aktualisieren', [this.aktuelles_datum, this.zeitraum]])
  }
}

