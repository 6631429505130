import {Injectable } from '@angular/core';
import {BehaviorSubject, of} from 'rxjs';
import {Platform} from '@angular/cdk/platform';


@Injectable()
export class PlattformService {

  public erkanntePlattform = new BehaviorSubject<any>(false)

  constructor(
    public platform: Platform,
  ) {  }

  public erkennePlattform() {
    if (this.platform.ANDROID) {
      this.erkanntePlattform.next('ANDROID')
    }
    else if (this.platform.IOS) {
      this.erkanntePlattform.next('IOS')
    }
    else {
      this.erkanntePlattform.next('WEB')
    }
  }

}
