import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ConfirmDialogModel} from '../../../obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {Router} from '@angular/router';

@Component({
  selector: 'app-registrierung-erfolgreich',
  templateUrl: './registrierung-erfolgreich.component.html',
  styleUrls: ['./registrierung-erfolgreich.component.css']
})
export class RegistrierungErfolgreichComponent implements OnInit, AfterViewInit {

  public mandant

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmDialogModel,
    public dialogRef: MatDialogRef<RegistrierungErfolgreichComponent>,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.mandant = this.data
  }

  ngAfterViewInit() {}

  onDismiss(): void {
    localStorage.removeItem('registrierungForm')
    this.dialogRef.close(false);
    this.router.navigateByUrl('/login')
  }

}
