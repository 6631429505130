<div class="offentlicherSpeiseplaene" *ngIf="mandantRegistrierFormenZaehlen() <= 3">
  <a [routerLink]="'/speiseplan-mandant/' + speisePlanId.value.splanId"
     [class.router-link-active]="router.url.includes('speiseplan-mandant') && speiseplanIdFromUrl !== speisePlanId.value.splanId || speiseplanIdFromUrl === speisePlanId.value.splanId"
     class="offentlicherSpeiseplanMenuLink"
     *ngFor="let speisePlanId of mandant.speisePlanIds | keyvalue"
  >
    <mat-icon>restaurant</mat-icon>
    <span *ngIf="speiseplanIdFromUrl && speiseplanIdFromUrl === speisePlanId.value.splanId">{{ speisePlanId.value.bezeichnung }}</span>
    <span *ngIf="speiseplanIdFromUrl && speiseplanIdFromUrl !== speisePlanId.value.splanId">{{ splanName }}</span>
    <span *ngIf="!speiseplanIdFromUrl">{{ speisePlanId.value.bezeichnung }}</span>
  </a>
</div>

<ng-container *ngIf="mandantRegistrierFormenZaehlen() > 3">
  <a routerLink="speiseplan-overview"
     [class.router-link-active]="splanLinkSelect"
     class="offentlicherSpeiseplanMenuLink"
  >
    <mat-icon>restaurant</mat-icon>
    <span>{{ splanName }}</span>
  </a>
</ng-container>
