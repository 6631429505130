<ng-container *ngIf="environment.type == 'web'">
  <table *ngIf="kunde" mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="rechnungTabelle">
    <ng-container matColumnDef="datum">
      <th mat-header-cell *matHeaderCellDef class="datum">Datum</th>
      <td mat-cell *matCellDef="let element" class="datum"> {{element.datum | date:'dd.MM.YYYY'}} </td>
    </ng-container>

    <ng-container matColumnDef="zeitraum">
      <th mat-header-cell *matHeaderCellDef class="zeitraum">Rechnungszeitraum</th>
      <td mat-cell *matCellDef="let element" class="zeitraum">
        {{element.zeitraum[0] * 1000 | date:'dd.MM.YYYY'}} - {{element.zeitraum[1] * 1000 | date:'dd.MM.YYYY'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="nr">
      <th mat-header-cell *matHeaderCellDef class="nr">Nummer</th>
      <td mat-cell *matCellDef="let element" class="nr"> {{element.nr}} </td>
    </ng-container>

    <ng-container matColumnDef="betrag">
      <th mat-header-cell *matHeaderCellDef class="betrag">Betrag</th>
      <td mat-cell *matCellDef="let element" class="betrag">
        <span [ngClass]="element.offen ? 'nichtBeglichen' : 'beglichen'" >{{element.betrag}}</span>
      </td>
    </ng-container>

    <ng-container matColumnDef="offen">
      <th mat-header-cell *matHeaderCellDef class="status">Status der Rechnung</th>
      <td mat-cell *matCellDef="let element" class="status">
      <span [ngClass]="element.offen ? 'nichtBeglichen' : 'beglichen'" >
        {{ !element.offen ? 'Betrag bezahlt' : 'Betrag offen'}}
      </span>
      </td>
    </ng-container>

    <ng-container matColumnDef="anfordern">
      <th mat-header-cell *matHeaderCellDef class="anfordern" >Rechnungen anfordern</th>
      <td mat-cell *matCellDef="let element" class="anfordern" [class.mobil]="environment.type == 'mobil'">
        <div (click)="rechnungAnfordern(element.nr)">
          <mat-icon [matTooltip]="'Rechnung anfordern'" >mail</mat-icon>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</ng-container>

<ng-container *ngIf="environment.type == 'mobil'">
  <mat-accordion>
    <mat-expansion-panel *ngFor="let rechnung of rechnungen" >
      <mat-expansion-panel-header>
        <mat-panel-title>
          <strong>
            {{rechnung.zeitraum[0] * 1000 | date:'dd.MM.YYYY'}} - {{rechnung.zeitraum[1] * 1000 | date:'dd.MM.YYYY'}}
          </strong>
          <span [ngClass]="rechnung.offen ? 'nichtBeglichen' : 'beglichen'" >Betrag: {{rechnung.betrag}}</span>
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div id="rechnungContent">
        <div>
          <strong>Datum</strong>
          <span>{{rechnung.datum | date:'dd.MM.YYYY'}}</span>
        </div>
        <div>
          <strong>Nummer</strong>
          <span>{{rechnung.nr}}</span>
        </div>
        <div>
          <strong>Status der Rechnung</strong>
          <span [ngClass]="rechnung.offen ? 'nichtBeglichen' : 'beglichen'">{{ !rechnung.offen ? 'Betrag bezahlt' : 'Betrag offen'}}</span>
        </div>
        <button (click)="rechnungAnfordern(rechnung.nr)">
          <mat-icon [matTooltip]="'Rechnung anfordern'" >mail</mat-icon>
          <span>Rechnung anfordern</span>
        </button>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<div id="rechnungenLeer" *ngIf="kunde.rechnungsdaten == ''" [class.web]="environment.type == 'web'">
  <mat-icon>receipt_long</mat-icon>
</div>
