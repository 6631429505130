import { Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

@Pipe({
  name: 'video'
})
export class VideoPipe implements PipeTransform {

  constructor(
    private domSanitizer: DomSanitizer
  ) {}

  transform(args?: any): any {
    if (args.includes('[video_bestellen]')){
      const replacement = `<video width="225" height="400" controls src="https://rcs.de/images/WBA Essen bestellen ganz einfach.mp4 " type="video/mp4"></video>`;
      const replaced = args.replace(/\[video_bestellen]/g, replacement)
      return this.domSanitizer.bypassSecurityTrustHtml(replaced);
    }
    return args
  }
}
