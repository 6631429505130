import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {Component, OnInit, Inject, AfterViewInit } from '@angular/core';

@Component({
  selector: 'bilder-karussell',
  templateUrl:  './bilder-karussell.component.html',
  styleUrls: ['./bilder-karussell.component.css'],
})

export class BilderKarussellComponent implements OnInit, AfterViewInit {

  public plattform = this.data.plattform
  public pwaInstallManual = {
    'ANDROID': [
      'Im Chrome das Funktionsmenü öffnen (Burgermenü am oberen rechten Rand).',
      'Die Option „App installieren“ wählen im Menü auswählen.',
      'Mit dem Button „Installieren“ im Dialog bestätigen.'
    ],
    'SAFARI': [
      'Im Safari das Teilen-Menü öffnen (Menüleiste im untereren Bereich).',
      'Die Option „Zum Home-Bildschirm“ wählen im Menü auswählen.',
      'Sie können bei Bedarf den Titel für das App-Icon anpassen und mit „Hinzufügen“ bestätigen.'
    ]
  }
  public aktiv = 1

  constructor(
    public dialogRef: MatDialogRef<BilderKarussellComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BilderKarussellModel
  ) {}

  ngOnInit() {
  }

  ngAfterViewInit() {
  }

  public close(){
    this.dialogRef.close()
  }

  public bildWechseln(zahl){
    this.aktiv = zahl
  }
}

export class BilderKarussellModel {

  constructor(public plattform: string) {
  }
}

