import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'speiseplanAufklappen'
})
export class SpeiseplanAufklappenPipe implements PipeTransform {

  transform(menus: any, ): any {
    if(Object.keys(menus).length === 1){
      return true
    }
    return false
  }
}