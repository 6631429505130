import { Component, OnInit } from '@angular/core';
import { DialogService } from 'services/prozesse/dialog.service';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Component({
	selector: 'app-web-speiseplan-overview',
	templateUrl: './web-speiseplan-overview.component.html',
	styleUrls: ['./web-speiseplan-overview.component.css']
})
export class WebSpeiseplanOverviewComponent implements OnInit {

	_this = this
	public menu_conf
	public mandant: any
  public kleinerBildschirm = false
  public menu_tiles = this.dialog_service.menu_tiles

	constructor(
		public dialog_service: DialogService,
		public mandant_service: MandantenService,
	) { }


	ngOnInit() {
		this.mandant_service.mandant_get().subscribe((mandant) => {
      this.kleinerBildschirm = window.innerWidth <= 1500;
			this.menu_conf = this.dialog_service.menu_config(mandant, 0)
		})
	}

	sendSplanBez(vari){
		this.dialog_service.splanbez_get(vari)
	}
}
