<div
  *ngFor="let menu of tag.value.tagesMenues | speiseplanTagesmenus:speiseplan.menutexte; let last = last; let i = index"
  class="speiseplanMenu"
  [class.darstellung]="darstellung != 1 && os == 'web'"
  id="menu{{menu.menueNr}}"
>
  <speiseplanMenu
    id="splanMenu"
    [class.speiseplanMenuWba]="os != 'web'"
    [mandant]="mandant"
    [kunde]="kunde"
    [menu]="menu"
    [speiseplanMenuDatum]="tag.key"
    [speiseplan]="speiseplan"
    [config]="config"
  ></speiseplanMenu>
</div>
