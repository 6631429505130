import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { KundeService } from 'services/kunden/kunde.service'
import {SeitenleisteInhalt} from '../../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {SeitenleisteService} from '../../../../submodul/obs.seitenleiste/seitenleiste.service';
import {RegistrierungInfoSeitenleisteComponent} from '../../../../submodul/obs.seitenleiste/components/registrierung-info/registrierung-info.seitenleiste.component';
import {Router} from '@angular/router';

@Component({
  selector: 'web-registrierung-info',
  templateUrl: './web-registrierung-info.component.html',
  styleUrls: ['./web-registrierung-info.component.css'],
})
export class WebRegistrierungInfoComponent implements OnInit, AfterViewInit {

  constructor(
    private kunde_service: KundeService,
    private mandant_service: MandantenService,
    private seitenleistenService: SeitenleisteService,
    private router: Router,
  ) { }

  public mandant
	public methode
	public vertrag
  public vertrag_methode
  public vertrag_titel
  public vertrags_text
  public link:string

  ngOnInit() {
    this.mandant_service.mandant_get().subscribe(
      (mandant: any) => {
        this.mandant = mandant
				this.methode = mandant.darstellungRegistrierung
				this.vertrag = Object.keys(mandant.registrierFormen)
        const vertragsTextId = this.router.url.split('/')[this.router.url.split('/').length - 1]
				this.vertrag_methode = mandant.registrierFormen[vertragsTextId].registrierArt
        this.link = "/#/register_form/" + vertragsTextId
        this.vertrag_titel = mandant.registrierFormen[vertragsTextId].name
				this.vertrags_text = atob(mandant.registrierFormen[vertragsTextId].infoTextHTML)
        this.seitenleistenAufruf()
      }
    )

  }

  ngAfterViewInit() { }

  seitenleistenAufruf(){
    this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
      RegistrierungInfoSeitenleisteComponent, {
        mandant: this.mandant
      })
    )
  }
}
