import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'aiztext'
})
export class AizTextPipe implements PipeTransform {

  REPLACEMENTS = {
    '[b]': '<b>',
    '[/b]': '</b>',
    '[u]': '<u>',
    '[/u]': '</u>',
    '[h]': '<sup>',
    '[/h]': '</sup>',
    '[v]': '<span class="menueTextCustom">',
    '[/v]': '</span>',
    '[br]': '<br>',
    '[i]': '<i>',
    '[/i]': '</i>',
}


  transform(args?: any): any {
    if (args){

      // menu['aiz'] = []
      // menu['aiz'] = menu['aiz'].concat(args.allergeneIds, args.inhaltsstoffeIds, args.zusatzstoffeIds)
    }
    return args
  }
}
