import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {SpeiseplanEinzelbestellerService} from '../../services/speiseplan-einzelbesteller.service';

@Component({
  selector: 'speiseplanTagIcon',
  templateUrl: 'speiseplanTagIcon.component.html',
  styleUrls: ['./speiseplanTagIcon.component.css'],
})
export class SpeiseplanTagIconComponent implements OnInit, OnDestroy{

  @Input() bestellungen
  @Input() config
  @Input() speiseplan
  @Input() tag

  public bestellt = false
  public bearbeiteterTag
  public bearbeitet = false

  constructor(
    public speiseplanEinzellbesteller : SpeiseplanEinzelbestellerService
  ) {}

  ngOnInit() {
    this.bestellt = this.speiseplanEinzellbesteller.bestellungErkennen(this.bestellungen)
    this.bearbeiteterTag = this.speiseplanEinzellbesteller.bearbeitet.subscribe(result => {
      this.bestellungenBearbeitet(result)
    })
  }

  ngOnDestroy() {
    this.bearbeiteterTag.unsubscribe()
  }

  bestellungenBearbeitet(tage){
    if (tage.length == 0) {
      this.bearbeitet = false
    }else if (typeof tage === 'boolean') {
      this.bearbeitet = tage
    } else {
      for (let datum of tage){
        if (datum){
          if (datum == this.bestellungen.datum){
            this.bearbeitet = true
            break
          } else {
            this.bearbeitet = false
          }
        } else {
          this.bearbeitet = false
        }
      }
    }
  }
}
