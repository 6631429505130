import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { ApiCallService } from '../api/api-call.service';
import { MandantenService } from '../mandanten/mandanten.service';
import {Observable} from 'rxjs';

@Injectable()
export class DateiService {

  constructor(
    private api_call_service: ApiCallService,
    private mandant_service: MandantenService,
    ){}

  public file_get(file_id: string){
    const payload = {
        'mandantId': this.mandant_service.mandant_id.getValue(),
        'fileId': file_id
    }
    return this.api_call_service.request('/img', 'files/get', payload);
  }

  public speiseplanPDF(datei:any): Observable<any>{
    let apiSubfolder = ''
    if (environment.url == 'https://proxy.mms-rcs.de'){
      apiSubfolder = 'api-files/live-API'
    }else{
      apiSubfolder = datei.url.split('?')[0]
    }
    return this.api_call_service.request('/file', '', { path: `${apiSubfolder}/${datei.internPath}` })
  }
}
