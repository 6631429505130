import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../environments/environment';

@Pipe({
  name: 'hauptmenuText'
})
export class HauptmenuTextPipe implements PipeTransform {

  transform(text: any): any {
    if (environment.type == 'web'){
      if (text.length > 100){
        return text.substr(0, 100) + '...'
      }
    }
    return text
  }
}
