import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-registrierung-vertrag',
  templateUrl: './registrierung-vertrag.component.html',
  styleUrls: ['./registrierung-vertrag.component.css']
})
export class RegistrierungVertragComponent implements OnInit {

  @Input() vertragsId: string
  @Input() mandant
  @Input() formGroup: string | null

  constructor() {}

  ngOnInit(): void {}
}
