<div class="kalenderContainer">
  <div class="kalenderZeitraum">
    <mat-calendar
      class="kalender"
      [minDate]="apiZeitraum.von"
      [maxDate]="apiZeitraum.bis"
      [(selected)]="aktuellesDatum"
      (selectedChange)="aktualisieren($event)"
      startView="month">
    </mat-calendar>
  </div>
  <div class="kalenderOptionen">
    <button (click)="aktuelleWoche()" [disabled]="!startWoche" [class.disabled]="!startWoche">Aktuelle Woche</button>
    <button (click)="aktuellerMonat()" [disabled]="!startMonat" [class.disabled]="!startMonat">Aktueller Monat</button>
  </div>
</div>
