import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tagwoche'
})

export class TagWochePipe implements PipeTransform {

  transform(value: any) {
    let modus
    (value === true) ? modus = 'Tag' : modus = 'Woche'
    return modus
  }
}
