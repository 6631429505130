<div id="wartungsabgleich">
  <mat-icon class="heartbeat-slower">construction</mat-icon>
  <strong>3003: Anwendung im Wartungsmodus</strong>
  <span>Wir führen Wartungsarbeiten durch.</span>
  <span>Bitte versuchen Sie es zu einem späteren Zeitpunkt noch einmal.</span>
  <div id="rechtsTexteContainer" [class.wbaContainer]="os != 'web'" *ngIf="dataSource">
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 rechtsTexteTable" [class.tableWba]="os != 'web'">
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef></th>
        <ng-container *matCellDef="let element">
          <mat-expansion-panel mat-cell class="rechtsTextePanel" [class.panelWba]="os != 'web'" *ngIf="element.content != ''">
            <mat-expansion-panel-header>
              <mat-panel-title [innerHTML]="'<strong>' + element.name + '</strong>'" class="rechtsTexteTitel">
              </mat-panel-title>
              <mat-panel-description>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <p [innerHTML]="element.content | bbCode | img | trustHTML | async"></p>
          </mat-expansion-panel>
        </ng-container>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

</div>
