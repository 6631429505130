<div id="kalenderPicker" *ngIf="aktuellesDatum">
    <button matRipple (click)="navigation(zeitraumNav.zurueck)" [disabled]="!zeitraumNav.zurueck || status">
      <mat-icon aria-hidden="false" aria-label="Zeitraum zurück">chevron_left</mat-icon>
    </button>
    <div id="speiseplan-kalender-datum" (click)="setzeStatus()" [class.disabled]="status">
      <span> {{ aktuellesDatum | kalenderDatum: 'dd.MM.yyyy'}} | {{ aktuellesDatum | datum:'wochentag'}}</span>
    </div>
    <button matRipple (click)="navigation(zeitraumNav.vor)" [disabled]="!zeitraumNav.vor || status">
      <mat-icon aria-hidden="false" aria-label="Zeitpunkt Vor">chevron_right</mat-icon>
    </button>
</div>
