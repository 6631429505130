import {AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import {SpeiseplanSammelbestellerService} from '../../services/speiseplan-sammebesteller.service';
import {Subscription} from 'rxjs';

@Component({
	selector: 'app-sammelbestellerDialog',
	templateUrl: './sammelbestellerDialog.component.html',
	styleUrls: ['./sammelbestellerDialog.component.css'],
})
export class SammelbestellerDialogComponent implements OnInit, AfterViewInit, OnDestroy {

  public filterErgebnis
  private filterSubscription: Subscription

  constructor(
    public sammelbestellerSpeiseplanService: SpeiseplanSammelbestellerService,
  ) { }

	ngOnInit() {
    this.filterSubscription = this.sammelbestellerSpeiseplanService.initFilter().subscribe(filter => {
      this.filterErgebnis = {...filter}
    })
  }

	ngAfterViewInit() {}

  ngOnDestroy() {
    this.filterSubscription.unsubscribe()
  }
}
