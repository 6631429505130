import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup } from '@angular/forms';
import { zip } from 'rxjs';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';

import profil_config from 'configs/profil.config.json';
import { ActivatedRoute, Router } from '@angular/router';
import { AppStateService } from 'services/app/app-state.service';
import { MitteilungsService } from 'services/prozesse/mitteilung.service';
import {Platform} from '@angular/cdk/platform';
import {environment} from '../../../../environments/environment';
import {SeitenleisteInhalt} from '../../../obs.seitenleiste/seitenleisteInhalt.class';
import {ProfilSeitenleisteComponent} from '../../../obs.seitenleiste/components/profil/profil.seitenleiste.component';
import {SeitenleisteService} from '../../../obs.seitenleiste/seitenleiste.service';

@Component({
  selector: 'profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css'],
})
export class ProfilComponent implements OnInit {

  public form
  public mandant
  public kunde_id
  public kunde
  public profil = profil_config
  public config = [
    'neues_passwort',
    'neues_passwort_wiederholt'
  ]
  public hide: boolean = true
  public detectedPlattform: string

  constructor(
    private mandant_service: MandantenService,
    private kunde_service: KundeService,
    private app_state: AppStateService,
    private active_route: ActivatedRoute,
    public platform: Platform,
    private router: Router,
    private mitteilungs_service: MitteilungsService,
    private seitenleistenService: SeitenleisteService,
  ) { }

  ngOnInit() {
    if (environment.type == 'mobil') {
      this.detectedPlattform = 'MOBIL'
    } else {
      this.detectedPlattform = 'WEB'
    }
    this.active_route.params.subscribe((kunde_id) => {
      this.kunde_id = kunde_id
      this.init()
    })
    this.app_state.set_page_name('Benutzer bearbeiten');
    this.app_state.set_seitenname('profil')
  }

  public init() {
    let mandant_response = this.mandant_service.mandant_get()
    let kunden_response = this.kunde_service.kunde_get()
    let kunden_login = this.kunde_service._kunde_get()
    let form_controls = {}
    zip(mandant_response, kunden_response, kunden_login).subscribe((result) => {
      this.mandant = result[0]
      let mandant: any = result[0]
      this.kunde = result[1]
      this.kunde['kundeId'] = result[2].kundeId
      for (let feld in mandant.aendKundenFelder) {
        let form_control = new FormControl()
        if (!mandant.aendKundenFelder[feld]) {
          form_control.disable()
        } else {
          form_control.enable()
        }
        if (feld.match(/_/g)) {
          let split = feld.split('_')
          form_control.setValue(this.kunde[split[0]][split[1]])
        } else {
          form_control.setValue(this.kunde[feld])
        }
        form_controls[feld] = form_control
      }
      form_controls['neues_passwort'] = new FormControl({ value: '', disabled: false })
      form_controls['neues_passwort_wiederholt'] = new FormControl({ value: '', disabled: false })
      form_controls['passwort'] = new FormControl({ value: '', disabled: false })
      this.form = new FormGroup(form_controls)
    })
  }

  public reset() {
    this.form.controls['neues_passwort'].reset()
    this.form.controls['neues_passwort_wiederholt'].reset()
    if (environment.type == 'web'){
      this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
        ProfilSeitenleisteComponent, {
          mandant: this.mandant,
          kunde: this.kunde,
        })
      )
    }
    if (environment.type == 'mobil'){
      this.router.navigate(['profile'])
    }
  }

  change({ target }) {
    let feldname
    if (target.getAttribute('id') == 'passwort' && this.detectedPlattform == 'WEB'){
      feldname = 'passwort'
    }
    else {
      feldname = target.getAttribute('formControlName')
    }
    this.form.controls[feldname].error = false
    if (feldname.match(/_/g) && !this.config.includes(feldname)) {
      let _feldname = feldname.split('_')
      this.kunde[_feldname[0]][_feldname[1]] = target.value
    } {
      this.kunde[feldname] = target.value
    }
  }

  onSubmit() {
      this.kunde_service.kunde_update(this.kunde).subscribe((result) => {
        let password = this.kunde.neues_passwort ? this.kunde.neues_passwort : this.kunde.passwort
        this.kunde_service.kunde_session_update(password).subscribe((result) => {
            if (this.detectedPlattform != 'WEB'){
              this.mitteilungs_service.set_snackbar('1007')
              this.router.navigateByUrl('profile')
            } else {
              this.mitteilungs_service.setMitteilung('1007')
              this.reset()
            }
          }, (error) => {
        })
      },
        (error) => {
          if (error.error.content.msgId == 99002) {
            if (this.detectedPlattform != 'WEB'){
              this.mitteilungs_service.set_snackbar('99002_A', 5000)
            }
            else {
              this.mitteilungs_service.setMitteilung('99002_A')
            }
            Object.entries(error.error.content.params).forEach(fehler => {
              this.form.controls[fehler[0]].setErrors(this.mitteilungs_service.checkMitteilung(fehler[1]).content)
            })
          }
        }
      )
  }

  public istBankdaten(): boolean {
    let felder = 0
    if (this.form.controls.bankverbindung_iban && this.form.controls.bankverbindung_iban.value){
      felder ++
    }
    if (this.form.controls.bankverbindung_bic && this.form.controls.bankverbindung_bic.value){
      felder ++
    }
    if (felder > 0){
      return true
    }
    return false
  }
}
