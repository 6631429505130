import {Component, ViewChild, ElementRef, Input, OnInit} from '@angular/core';

@Component({
  selector: 'speiseplan-glossar',
  templateUrl: './speiseplanGlossar.component.html',
  styleUrls: ['./speiseplanGlossar.component.css']
})
export class SpeiseplanGlossarComponent implements OnInit{

  @Input('mandant') mandant
  @ViewChild('glossar_liste') glossar_liste: ElementRef
  public glossar = [
    { 'name': 'Allergene', 'data': {} },
    { 'name': 'Inhaltsstoffe', 'data': {} },
    { 'name': 'Zusatzstoffe', 'data': {} },
  ]
  public allergene: boolean = false
  public allergeneKeys
  public inhaltsstoffeKeys
  public zusatzstoffeKeys

  constructor() { }

  ngOnInit() {
    this.speiseplanGlossarErstellen()
  }

  public speiseplanGlossarErstellen() {
    this.allergene = false
    this.allergeneKeys = this.mandant.allergene
    this.inhaltsstoffeKeys = this.mandant.inhaltsstoffe
    this.zusatzstoffeKeys = this.mandant.zusatzstoffe
    this.alle_AIZ(this.allergeneKeys, this.inhaltsstoffeKeys, this.zusatzstoffeKeys)

    this.glossar[0].data = Object.keys(this.mandant.allergene).map((k) => this.mandant.allergene[k])
    this.glossar[1].data = Object.keys(this.mandant.inhaltsstoffe).map((k) => this.mandant.inhaltsstoffe[k])
    this.glossar[2].data = Object.keys(this.mandant.zusatzstoffe).map((k) => this.mandant.zusatzstoffe[k])
  }

  public alle_AIZ(allergeneKeys, inhaltsstoffeKeys, zusatzstoffeKeys){
    let allergene = [], inhalt = [], zusatz = []
    let value, keys: any
    for ([keys, value] of Object.entries(allergeneKeys)){allergene.push(value.schluessel)}; this.allergeneKeys = allergene
    for ([keys, value] of Object.entries(inhaltsstoffeKeys)){inhalt.push(value.schluessel)}; this.inhaltsstoffeKeys = inhalt
    for ([keys, value] of Object.entries(zusatzstoffeKeys)){zusatz.push(value.schluessel)}; this.zusatzstoffeKeys = zusatz
  }

  public statusWechsel(item){
    item.expanded = item.expanded ? false : true
  }

  public statusAnzeigen(item){
    return item.expanded
  }
}
