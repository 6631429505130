import { Component, OnInit } from '@angular/core';
import {WerbebannerService} from './werbebanner.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'werbebanner',
  templateUrl: './werbebanner.component.html',
  styleUrls: ['./werbebanner.component.css']
})
export class WerbebannerComponent implements OnInit {

  public werbebannerContent

  constructor(
    public werbebannerService: WerbebannerService,
  ){}

  ngOnInit() {
    this.werbebannerService.ankuendigungsContentPopup().subscribe((content) => {
      this.werbebannerContent = this.werbebannerService.ankuendigungsBannerContent
    })
  }

  protected readonly environment = environment;
}
