import { HttpEvent, HttpHandler, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { KundeService } from '../kunden/kunde.service';
import { RevisionService } from 'services/api/revision.service';
import {RouterService} from '../navigation/router.service';
import {MandantenService} from '../mandanten/mandanten.service';

@Injectable()
export class HTTPInterceptorService {

  constructor(
    public router: Router,
    public kunde_service: KundeService,
    private revisions_service: RevisionService,
    private routerService: RouterService,
    private mandantService: MandantenService,
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          if (event.body.token) {
            if (event.body.content){
              localStorage.setItem(event.body.content.kundeId, event.body.token)
            } else {
              this.kunde_service.kunde_logout(localStorage.getItem('active'))
              this.mandantService.mandant_get().subscribe(mandant => {
                this.routerService.startNavigation(mandant, '3010')
              })
            }
          }
          // this.revisions_service.revision_mandant(req.body.command)
          // this.revisions_service.revision_kunde(req.body.command)
          return event = event.clone({ body: event.body.content })
        }
      })
    ) as Observable<HttpEvent<any>>
  }
}
