<div id="bilderKarussell">
  <div id="bilder_karussell_titel">
    <strong>Anleitung zur Installation</strong>
    <mat-icon (click)="close()">close</mat-icon>
  </div>
  <div id="bilderkarusellGrp">
    <div class="BilderKarusselContent">
      <img src="assets/icons/pwa-anleitung/{{ plattform }}/Anleitung{{aktiv}}.webp"/>
      <span>{{ this.pwaInstallManual[plattform][aktiv - 1] }}</span><br/>
    </div>
    <div id="buttonGrp">
      <button [class.active]="aktiv == 1" (click)="bildWechseln(1)">1</button>
      <button [class.active]="aktiv == 2" (click)="bildWechseln(2)">2</button>
      <button [class.active]="aktiv == 3" (click)="bildWechseln(3)">3</button>
    </div>
  </div>
</div>
