import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MandantenService } from 'services/mandanten/mandanten.service';
import {DomSanitizer} from '@angular/platform-browser';
import {SeitenleisteService} from '../../../submodul/obs.seitenleiste/seitenleiste.service';
import {SeitenleisteInhalt} from '../../../submodul/obs.seitenleiste/seitenleisteInhalt.class';
import {
  RechtlichesSeitenleisteComponent
} from '../../../submodul/obs.seitenleiste/components/rechtliches/rechtliches.seitenleiste.component';

@Component({
    selector: 'web-rechtliches',
    templateUrl: './web-rechtliches.component.html',
    styleUrls: ['./web-rechtliches.component.css'],
})

export class WebRechtComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    private mandantservice: MandantenService,
    private seitenleistenService: SeitenleisteService,
    private route: ActivatedRoute,
    private domSanitizer: DomSanitizer,
  ) {}

  public rechts_txt:any
  public rechts_content:any
  public content: string
  public name: string
  public mandant
  public kuendigung
  public subscription


  ngOnInit() {
    this.route.queryParams.subscribe(params => {
      this.subscription = this.mandantservice.mandant_get().subscribe((mandant) => {
        if (params.id == 'Kündigung'){
          if (!/kuendigen_step1/gm.test(mandant.sonderKonfiguration.API_1_5.terminateContractURL)){
            window.location.href = mandant.sonderKonfiguration.API_1_5.terminateContractURL
          }
        }
        this.seitenleistenService.updateSeitenleiste(new SeitenleisteInhalt(
          RechtlichesSeitenleisteComponent, {
            mandant: mandant,
            aktuelleUrl: params.id
          })
        )
        this.mandant = mandant
        this.kuendigung = mandant.sonderKonfiguration.API_1_5.terminateContractURL
        this.rechts_content = [
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.agb), name: 'AGB' },
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.datenschutz), name: 'Datenschutz' },
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.widerruf), name: 'Widerruf' },
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.impressum), name: 'Impressum' },
          { content: this.domSanitizer.bypassSecurityTrustHtml(mandant.betreiberDaten.kontakt), name: 'Kontakt' },
          { content: {}, name: 'FAQ' },
          { content: {}, name: 'Kündigung' }
        ]
        this.rechts_txt = params.id
        this.content = this.rechts_content.filter((element) => {
          if (element.name == params.id){
            this.name = element.name;
            return element
          }
        })[0].content
      })
    });
  }

  ngAfterViewInit() {
    const element = document.getElementById('recht-content');
    element.scrollIntoView();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }
}
