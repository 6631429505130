import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable()
export class BestellhistorieSeitenleisteService {

  public bestellhistoriePreis: BehaviorSubject<any> = new BehaviorSubject(0)

  constructor() {}
}
