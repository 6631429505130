import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'euroPrice',
  pure: false
})
export class EuroPricePipe implements PipeTransform {

  transform(value: any): any {
    if (value){
      let waehrung = '';
      if (value.waehrung === 'EUR'){
        waehrung = '€';
      }
      if (typeof value === 'object'){
        if (value.betrag != 0){
          let preis =  value.betrag.toFixed(2) + ' ' + waehrung;
          return preis.replace('.', ',')
        }
        else {
          return '0,00' + waehrung;
        }
      }else{
        if (typeof value === 'number'){
          let preis = value.toFixed(2) + ' ' + waehrung;
          return preis.replace('.', ',')
        }
      }
    }
    return ''
  }

}
