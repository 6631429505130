import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'datum',
})
export class DatumPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value != ""){
      if (args == 'wochentag') {
        return moment.default(value).locale('de').format('dddd')
      }
      return moment.default(value).locale('de').format('DD.MM.YYYY')
    }
    else{
      return value;
    }
  }

}
