import {AfterViewInit, Directive, ElementRef, Input, ViewContainerRef} from '@angular/core';
import {environment} from '../../../environments/environment';

@Directive({
  selector: '[registrierungsEinrichtungBezeichnung]'
})
export class RegistrierungsEinrichtungBezeichnung implements AfterViewInit{

  constructor(
    private elementRef: ElementRef,
  ){}

  ngAfterViewInit() {
    if (environment.mandant_id == '104890-aubg-schule'){
      this.elementRef.nativeElement.innerHTML = 'Name der Schule'
    }
    if (environment.mandant_id == '104890-aubg-frisch'){
      this.elementRef.nativeElement.innerHTML = 'Name des Unternehmens'
    }
  }
}
