import {Pipe, PipeTransform, Renderer2} from '@angular/core';
import { SymbolPipe } from './symbol.pipe';
import {DomSanitizer} from '@angular/platform-browser';
import {DbReaderService} from '../services/db-reader/db-reader.service';
import {ApiCallService} from '../services/api/api-call.service';
import {of, zip} from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'img'
})
export class ImgPipe implements PipeTransform {

  constructor(
    private sanitizer: DomSanitizer,
    private db: DbReaderService,
    private api_call_service: ApiCallService
  ) {}

  transform(safeContent) {
    if (safeContent){
      let text = safeContent
      let bildUrls
      const bildIds = []
      const bildObservables = []
      const regex = /\{{(.*?)\}}/g
      const symbol = new SymbolPipe(this.sanitizer, this.db, this.api_call_service)
      while (bildUrls = regex.exec(text)) {
        bildIds.push(bildUrls[0])
        bildObservables.push(symbol.transform(bildUrls[1]))
      }
      if (bildIds.length > 0){
        return zip(...bildObservables).pipe(map(blobs => {
          blobs.forEach((blob: any, index) => {
            text = text.replace(bildIds[index], blob.changingThisBreaksApplicationSecurity)
          })
          return this.sanitizer.bypassSecurityTrustHtml(text)
        }))
      }
      return of(this.sanitizer.bypassSecurityTrustHtml(text))
    }
  }
}
