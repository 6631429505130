import {Injectable, ViewContainerRef} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {NavigationEnd, Router} from '@angular/router';
import {SeitenleisteInhalt} from './seitenleisteInhalt.class';

@Injectable()
export class SeitenleisteService {

  public zeigeSeitenleiste: BehaviorSubject<any> = new BehaviorSubject(true)
  public inhaltSeitenleiste: BehaviorSubject<any> = new BehaviorSubject(false)
  public emitterSeitenleiste: BehaviorSubject<any> = new BehaviorSubject<any>(null)
  private keineSeitenleisteRouten: Array<string> = ["login", "home", "speiseplan-overview", "reset_password", "verify_email"]

  constructor(private router: Router) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd){
        const mainUrl = event.url.split('/')[1]
        if (this.keineSeitenleisteRouten.includes(mainUrl)){
          this.zeigeSeitenleiste.next(false)
        }
        if(!this.keineSeitenleisteRouten.includes(mainUrl) && !this.zeigeSeitenleiste.getValue()){
          this.zeigeSeitenleiste.next(true)
        }
      }
    })
  }

  public updateSeitenleiste(seitenleisteInhalt: SeitenleisteInhalt){
    this.inhaltSeitenleiste.next(seitenleisteInhalt)
  }

  public renderSeitenleisteInhalt(viewContainer: ViewContainerRef){
    viewContainer.clear()
    const inhaltSeitenleiste = this.inhaltSeitenleiste.getValue()
    const compRef = viewContainer.createComponent(inhaltSeitenleiste.compInstanz)
    for(let paramName in inhaltSeitenleiste.compParams){
      compRef.instance[paramName] = inhaltSeitenleiste.compParams[paramName]
    }
  }

  public emitterSeitenleistenInhalt(inhalt:any){
    this.emitterSeitenleiste.next(inhalt)
  }
}

