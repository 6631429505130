<div id="matDialogContent"  class="dialog-content">
  <div id="matDialogContentHeader">
    <strong>{{title}}</strong>
    <mat-icon mat-dialog-close >close</mat-icon>
  </div>
  <div>
    <video width="225" height="400" controls>
      <source  src="{{video}}" type="video/mp4">
    </video>
  </div>
</div>
