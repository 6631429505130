<ng-container *ngIf="formGroup && feldConfig">
  <div [formGroup]="formGroup" [class]="feldConfig.name" class="checkbox">
    <mat-checkbox [class.registrationFormFehler]="formGroup.controls[feldConfig.name].errors" [formControlName]="feldConfig.name" [tabIndex]="tabIndex">
      <div class="registrationFeldHeader">
        <label>
          <strong *ngIf="feldConfig && feldConfig.pflicht"> *</strong>
          <span>{{ feldConfig.text }}</span>
        </label>
        <mat-icon
          *ngIf="feldConfig.description"
          [title]="feldConfig.description"
          (click)="registrationService.zeigeInfoDialog(feldConfig.description)"
        >info</mat-icon>
      </div>
    </mat-checkbox>
  </div>
  <span
    class="registrationBeschreibungFehler"
    *ngIf="formGroup.controls[feldConfig.name].hasError"
  >{{ formGroup.controls[feldConfig.name].errors }}</span>
</ng-container>
