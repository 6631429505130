import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {


  transform(args?: any): any {
    if (args == true){
		  return 'ja'
    } 
    else {
      return 'nein'
    }
    
  }
}