import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'bestellzeiten'
})
export class BestellzeitenPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    return moment.default(value).format('DD.MM.YYYY | HH:mm') + ' Uhr'
  }

}
