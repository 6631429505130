import {Component, HostBinding, Input, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {RegistrierungService} from '../../../../../services/registrierung/registrierung.service';

@Component({
  selector: 'app-formular-freitext-input',
  templateUrl: './formular-freitext-input.component.html',
  styleUrls: ['./formular-freitext-input.component.css']
})
export class FormularFreitextInputComponent implements OnInit {
  @Input() public formGroup: UntypedFormGroup
  @Input() feldConfig
  @Input() tabIndex
  @HostBinding('className') componentClass: string;

  constructor(
    public registrationService: RegistrierungService
  ) { }

  ngOnInit(): void {
    this.componentClass = this.feldConfig.name;
  }
}
