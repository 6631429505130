import { AfterViewInit, Directive, ElementRef, Input, OnChanges } from "@angular/core";

@Directive({
  selector: '[bestellungUpdate]'
})
export class BestellungUpdateDirective implements AfterViewInit{
  @Input() bestellung

  constructor(private element: ElementRef) {
  }

  ngAfterViewInit(){
    if (this.bestellung.mengeNeu == this.bestellung.mengeAlt){
      this.element.nativeElement.classList.add('normal')
    }
    if (this.bestellung.mengeNeu > this.bestellung.mengeAlt){
      this.element.nativeElement.classList.add('bestellung')
      this.element.nativeElement.innerHTML = '+' + this.element.nativeElement.innerHTML
    }
    if (this.bestellung.mengeNeu < this.bestellung.mengeAlt){
      this.element.nativeElement.classList.add('abbestellung')
    }
  }
}
