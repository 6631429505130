import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'bestellschlussicon'
})
export class BestellSchlussIconPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value.abbestellschluss && value.bestellschluss){
      return 'assets/icons/bestellschluss/icon_keine_bestellung.webp'
    }
    if (value.abbestellschluss && !value.bestellschluss){
      return 'assets/icons/bestellschluss/icon_abbestellschluss.webp'
    }
    if (value.bestellschluss && !value.abbestellschluss){
      return 'assets/icons/bestellschluss/icon_bestellschluss.webp'
    }
    return 'assets/icons/bestellschluss/icon_bestellung.webp'
  }

}
