import {Pipe, PipeTransform } from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';
import {Observable } from 'rxjs';
import {map} from 'rxjs/operators';

@Pipe({
  name: 'trustHTML'
})
export class TrustHTMLPipe implements PipeTransform {

  constructor(
    private domSanitizer: DomSanitizer
  ) {}

  transform(unsafeHTML: Observable<any>) {
    return unsafeHTML.pipe(map(html => {
      return this.domSanitizer.bypassSecurityTrustHtml(html)['changingThisBreaksApplicationSecurity'] }))
  }
}
