import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  public loadingStatus: BehaviorSubject<boolean> = new BehaviorSubject(false);

  setzeLadeanzeige(status: boolean): void {
    this.loadingStatus.next(status);
  }
}
