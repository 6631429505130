import {Injectable} from '@angular/core';
import {BehaviorSubject, Subject} from 'rxjs';
import * as moment from 'moment';

@Injectable()
export class SpeiseplanZeitraumService {
  public aktuellesDatum = new BehaviorSubject<any>(false);
  public status = new BehaviorSubject<any>(false);

  constructor(
  ) {}

  public ermittleZeitraum(datum){
    return {
      vor: moment.utc(datum).add(1, 'd').format('YYYY-MM-DD'),
      zurueck: moment.utc(datum).add(-1, 'd').format('YYYY-MM-DD')
    }
  }
}

