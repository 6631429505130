import { Injectable } from '@angular/core';
import { neuKunde } from '../interface/registrierung.interface';
@Injectable()

export class FormDatenService {

	public form :neuKunde = {
		anmeldeZeit: "",
		bankverbindung: {
			kontoinhaber: "",
			iban: "",
			bic: ""
		},
		einrichtung: {
			info: '',
			nameGruppe: "",
			nameEinrichtung: ""
		},
		essensteilnehmer: {
			anrede: "",
			vorname: "",
			name: "",
			gebTag: "",
			telefon: "",
			eMail: "",
			strasse: "",
			ort: "",
			ortsteil: "",
			plz: "",
			land: "Deutschland"
		},
		freiText: "",
		ipAdresse: "",
		leistungsBeginn: "",
		rechPerMail: false,
		rechtsZustimmung: false,
		rechnungskunde: {
			anrede: "",
			vorname: "",
			name: "",
			gebTag: "",
			telefon: "",
			eMail: "",
			strasse: "",
			ort: "",
			ortsteil: "",
			plz: "",
			land: "Deutschland"
		},
		passwort: "",
		passwort2: "",
		vertragsArt: "rcsfmv909764904",
		vertragspartner: {
			anrede: "",
			vorname: "",
			name: "",
			gebTag: "",
			telefon: "",
			eMail: "",
			strasse: "",
			ort: "",
			ortsteil: "",
			plz: "",
			land: "Deutschland"
		},
		zahlungsart: "",
		zahlintervall: "",
		kostenzusage: false,
		karte: "",
		but: false,
		zahlungseinwilligung: false
	}
}
