<div id="seitenleiste">
  <div id="seitenleisteImage">
    <img [src]="logo" (error)="onImageError()">
  </div>
  <div id="seitenleistenInhalt">
    <div>
      <ng-container #inhalt></ng-container>
    </div>
  </div>
</div>
