import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DirectiveModule } from 'directives/directives.module';
import { PipeModule } from 'pipe/pipe.module';
import { WebLoginComponent } from './web-login/web-login.component';
import { WebFehlerComponent } from './web-fehler/web-fehler.component';
import { WebLadeanzeigeComponent } from './web-ladeanzeige/web-ladeanzeige.component';
import { WebSpeiseplanKundeComponent } from './web-speiseplan-kunde/web-speiseplan-kunde.component';
import { WebRechtComponent } from './web-rechtliches/web-rechtliches.component';
import { WebMitteilungenComponent } from './web-mitteilungen/web-mitteilungen.component';
import { WebSpeiseplanMandantComponent } from './web-speiseplan-mandant/web-speiseplan-mandant.component';
import { WebWarenkorbComponent } from './web-warenkorb/web-warenkorb.component';
import { WebBestellhistorieComponent } from './web-bestellhistorie/web-bestellhistorie.component';
import { WebProfileComponent } from './web-profile/web-profile.component';
import { WebProfilComponent } from './web-profil/web-profil.component';
import { WebSpeiseplanOverviewComponent } from './web-speiseplan-overview/web-speiseplan-overview.component';
import { WebToolbarComponent } from './web-toolbar/web-toolbar.component';
import { MaterialModule } from 'application/web/material.module';
import { WebRegistrierungEinrichtungComponent } from './web-registrierung/web-registrierung-einrichtung/web-registrierung-einrichtung.component';
import { WebRegistrierungFormComponent } from './web-registrierung/web-registrierung-form/web-registrierung-form.component';
import { WebRegistrierungZwischenComponent } from './web-registrierung/web-registrierung-zwischen/web-registrierung-zwischen.component';
import { WebRegistrierungInfoComponent } from './web-registrierung/web-registrierung-info/web-registrierung-info.component';
import { WebRegistrierungKopfComponent } from './web-registrierung/web-registrierung-kopf/web-registrierung-kopf.component';
import { WebDialogComponent } from './web-dialog/web-dialog.component';
import { WebBenachrichtigungenComponent } from './web-benachrichtigungen/web-benachrichtigungen.component';
import { WebPdfComponent } from './web-pdf/web-pdf.component';
import { WebRegistrierungAbschlussComponent } from './web-registrierung/web-registrierung-abschluss/web-registrierung-abschluss.component';
import { WebFooterComponent } from './web-footer/web-footer.component';
import {RegistrierungModule} from '../../submodul/obs.registrierung/component/registrierung.module';
import {SharedModule} from '../../submodul/obs.shared/shared.module';
import {WebSpeiseplanHeaderComponent} from './web-speiseplan-header/web-speiseplan-header.component';
import {SpeiseplanModule} from '../../submodul/obs.speiseplan/speiseplan.module';
import {WebToolbarPubSplanComponent} from './web-toolbar/web-toolbarOeffentlicheSpeiseplaene/webToolbarPubSplan.component';
import {WebFarbkatlogComponent} from './web-farbkatalog/web-farbkatalog.component';
import {BestellungenModule} from '../../submodul/obs.bestellungen/bestellungen.module';

@NgModule({
    imports: [
      CommonModule,
      RouterModule,
      FormsModule,
      PipeModule,
      DirectiveModule,
      ReactiveFormsModule,
      MaterialModule,
      SharedModule,
      RegistrierungModule,
      SpeiseplanModule,
      BestellungenModule,
    ],
  declarations: [
    WebLoginComponent,
    WebFehlerComponent,
    WebLadeanzeigeComponent,
    WebSpeiseplanMandantComponent,
    WebSpeiseplanKundeComponent,
    WebWarenkorbComponent,
    WebMitteilungenComponent,
    WebRechtComponent,
    WebBestellhistorieComponent,
    WebProfileComponent,
    WebProfilComponent,
    WebBenachrichtigungenComponent,
    WebSpeiseplanOverviewComponent,
    WebToolbarComponent,
    WebDialogComponent,
    WebPdfComponent,
    WebToolbarPubSplanComponent,
		WebRegistrierungEinrichtungComponent,
		WebRegistrierungFormComponent,
		WebRegistrierungZwischenComponent,
		WebRegistrierungInfoComponent,
		WebRegistrierungAbschlussComponent,
    WebRegistrierungKopfComponent,
    WebFooterComponent,
    WebSpeiseplanHeaderComponent,
    WebFarbkatlogComponent,
  ],
  exports: [
    WebLoginComponent,
    WebFehlerComponent,
    WebLadeanzeigeComponent,
    WebSpeiseplanMandantComponent,
    WebSpeiseplanKundeComponent,
    WebWarenkorbComponent,
    WebMitteilungenComponent,
    WebBestellhistorieComponent,
    WebProfileComponent,
    WebProfilComponent,
    WebBenachrichtigungenComponent,
    WebToolbarComponent,
    WebToolbarPubSplanComponent,
    WebDialogComponent,
    WebPdfComponent,
		WebDialogComponent,
		WebRegistrierungEinrichtungComponent,
		WebRegistrierungFormComponent,
		WebRegistrierungZwischenComponent,
		WebRegistrierungInfoComponent,
		WebRegistrierungAbschlussComponent,
    WebRegistrierungKopfComponent,
    WebFooterComponent,
    WebSpeiseplanHeaderComponent,
    WebFarbkatlogComponent,
  ],
})
export class WebModule { }
