import { Injectable } from '@angular/core';
import { AsyncSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DbReaderService {

  private indexeddb = window.indexedDB;

  constructor() { }

  private open(){
    const database = this.indexeddb.open('MMS-GUI', 7);
    database.onupgradeneeded = function (event) {
      if (event.oldVersion < 1) {
        database.result.createObjectStore('User');
      }
      if (event.oldVersion < 2) {
        database.result.createObjectStore('Options');
      }
      if (event.oldVersion < 3) {
        database.result.createObjectStore('Cache');
      }
      if (event.oldVersion < 4) {
        database.result.deleteObjectStore('User');
        database.result.createObjectStore('User');
      }
      if (event.oldVersion < 5) {
        database.result.createObjectStore('Mandant');
      }
      if (event.oldVersion < 6) {
        database.result.createObjectStore('System');
      }
      if (event.oldVersion < 7) {
        database.result.createObjectStore('Content');
      }
    };
    return database;
  }

  public add(data:any, key:string, collection:string){
    const database = this.open();
    database.onsuccess = function () {
      const db_transaction = database.result.transaction(collection, 'readwrite');
      const store = db_transaction.objectStore(collection);
      if (store.get(key)) {
        store.delete(key);
      }
      store.add(data, key);
      db_transaction.oncomplete = function () {
        database.result.close();
      }
    }
  }

  public update(data:any, key:string, collection){
    const response = new AsyncSubject()
    const database = this.open();
    database.onsuccess = function () {
      const db_transaction = database.result.transaction(collection, 'readwrite')
      const request = db_transaction.objectStore(collection).put(data, key)
      request.onsuccess = function() {
        response.next(true)
        response.complete()
      }
    }
    return response
  }

  public get(key: string, collection:string): AsyncSubject<any> {
    const response = new AsyncSubject();
    const database = this.open();
    database.onsuccess = function () {
      const db_transaction = database.result.transaction(collection, 'readwrite');
      const store = db_transaction.objectStore(collection);
      const result: any = store.get(key);

      db_transaction.oncomplete = function () {
      database.result.close();
      if (result) {
        response.next(result.result);
      } else {
        response.next({});
      }
      response.complete();
      }
      db_transaction.onerror = function () {
        database.result.close();
        response.next({});
        response.complete();
      }
    }
    return response;
  }

  public all(collection, parameter): AsyncSubject<any> {
    const response = new AsyncSubject();
    const database = this.open();
    database.onsuccess = function () {
      const db_transaction = database.result.transaction(collection, 'readwrite');
      const store = db_transaction.objectStore(collection);
      let result: any;
      if (parameter == 'value'){result = store.getAll();}
      else if(parameter = 'keys'){result = store.getAllKeys();}
      result.onsuccess = function () {
        response.next(result.result);
        response.complete();
      }
      db_transaction.onerror = function () {
        response.next({});
        response.complete();
      }
    }
    return response;
  }

  public delete(key:string){
    const database = this.open()
    const response = new AsyncSubject()
    database.onsuccess = function () {
      const db_transaction = database.result.transaction('User', 'readwrite');
      const store = db_transaction.objectStore('User');
      store.delete(key);
      db_transaction.oncomplete = function () {
        database.result.close();
        response.next(true)
        response.complete()
      }
    }
    return response
  }

  public clear(collection){
    let observer = new AsyncSubject()
    const database = this.open();

    database.onsuccess = function(){
      const db_transaction = database.result.transaction(collection, 'readwrite')
      const request = db_transaction.objectStore(collection).clear()
      request.onsuccess = function(){
          observer.next(true)
          observer.complete()
      }
    }
    return observer
  }

  public clear_cache(){
    const database = this.indexeddb.open('MMS-GUI_Cache', 1)
    const response = new AsyncSubject()
    database.onupgradeneeded = function () {
      database.result.createObjectStore('API_Response')
    }
    database.onsuccess = function(){
      const db_transaction = database.result.transaction('API_Response', 'readwrite')
      const request = db_transaction.objectStore('API_Response').clear()
      request.onsuccess = function(){
        response.next(true)
        response.complete()
      }
    }
    return response
  }
}
