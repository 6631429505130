<div id="matDialogContent">
  <div id="matDialogContentHeader" *ngIf="detectedPlattform == 'WEB'">
    <strong>Passwort vergessen</strong>
    <mat-icon mat-dialog-close>close</mat-icon>
  </div>
  <form id="passwort-vergessen" [formGroup]="passwort_vergessen" (ngSubmit)="on_submit()">
    <div id="matDialogContentData">
    <span id="password_vergessen_span" *ngIf="gesendet">Bitte geben Sie Ihre Kundennummer ein.<br>
    Wir senden Ihnen per E-Mail einen Link zum Zurücksetzen Ihres Passwortes zu.</span>
      <div *ngIf="mitteilung" class="error swing-in-top-fwd" [class.good]="mitteilung.type == 'good'" [class.bad]="mitteilung.type == 'bad'">
        <p *ngIf="mitteilung.type == 'good'"><strong >E-Mail zur Verifizierung versandt.</strong></p>
        {{ mitteilung.inhalt }}
      </div>
      <input *ngIf="!send" formControlName="kundennummer" placeholder="Kundennummer" type="number">
    </div>
    <div id="matDialogFooter">
      <button mat-dialog-close type="button" class="btn-abbrechen" *ngIf="detectedPlattform == 'WEB'">Abbrechen</button>
      <button *ngIf="!send" type="submit" [disabled]="!passwort_vergessen.valid">Kundennummer überprüfen</button>
      <button mat-dialog-close *ngIf="send" (click)="navigation_login()">Zurück zum Login</button>
    </div>
  </form>
</div>
