export class RouterListe{
  public mandant:Array<string> = [
    'home',
    'login',
    'passwort-vergessen',
    'verify-email',
    'speiseplan-mandant',
    'speiseplan-kunde',
    'rechtliches',
    'fehler',
    'kontakt',
    'speiseplan-overview',
    'register-info',
    'register-einrichtung',
    'register-form',
    'register-zwischenseite',
    'register-abschluss',
    'email-verifikation'
  ]

  public kunde:Array<string> = [
    'speiseplan-kunde',
    'warenkorb',
    'bestellungen',
    'profil'
  ]
}
