import { Injectable } from '@angular/core';
import { ApiCallService } from '../api/api-call.service';

import { environment } from 'environments/environment';
import { DbReaderService } from 'services/db-reader/db-reader.service';
import { switchMap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import {MandantenAIZService} from './mandanten-aiz.service';

@Injectable()
export class MandantenService {

  private splanName = new BehaviorSubject('')
  getSplanName = this.splanName.asObservable();

  constructor(
    private api_call_service: ApiCallService,
    private datenbank_service: DbReaderService,
    private mandantenAIZService: MandantenAIZService,
  ) { }

  public kunde: any
  public mandant_id: BehaviorSubject<string>

  public ladeAkutelleMandantId(){
    return this.datenbank_service.get('aktiv', 'Mandant')
  }

  public mandant_get() {
    return this.datenbank_service.get('aktiv', 'Mandant')
      .pipe(switchMap((id) => { return id ? this.datenbank_service.get(id, 'Mandant') : of(false) }))
  }

  public mandant_login(mandantId) {
    let parameter = {
      mandantId: mandantId
    }
    this.api_call_service.request('/public', 'mandant/get', parameter)
      .subscribe((mandant: any) => {
        this.mandant_id.next(mandantId)
        this.mandantenAIZService.init(mandant)
        this.datenbank_service.add(mandant, mandantId, 'Mandant')
        this.datenbank_service.add(mandantId, 'aktiv', 'Mandant')
      })
  }

  public contractDelete(termination) {
    const payload = {
      mandantId: environment.mandant_id,
      termination: JSON.stringify(termination)
    }
    return this.api_call_service.request('/public', 'contract/delete', payload)
  }

  public kontakt_commit(parameter) {

    parameter['mandantId'] = environment.mandant_id
    return this.api_call_service.request('/public', 'kontakt/commit', parameter)
  }

  public splanName_get(varifahri) {
    this.splanName.next(varifahri)
  }
}
