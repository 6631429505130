import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SpeiseplanMenuService} from '../../services/speiseplanMenu.service';
import {environment} from '../../../../environments/environment';
import {SpeiseplanDetailsComponent} from '../speiseplanDetails/speiseplanDetails.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as moment from 'moment/moment';

@Component({
  selector: 'speiseplanMenu',
  templateUrl: 'speiseplanMenu.component.html',
  styleUrls: ['./speiseplanMenu.component.css'],
})
export class SpeiseplanMenuComponent implements OnInit, AfterViewInit{

  @Input() mandant
  @Input() kunde
  @Input() menuId
  @Input() menu
  @Input() speiseplan
  @Input() speiseplanMenuDatum
  @Input() config

  public bestellungen
  public bestellung
  public gesperrt: boolean
  public aktiv
  public datum
  public plattform

  constructor(
    public speiseplanMenuService: SpeiseplanMenuService,
    private dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.plattform = environment.type
    if (this.kunde){
      this.menu = this.speiseplanMenuService.speiseplanMenuBeschreibungTranslate(this.speiseplan, this.menu)
      this.bestellungen = this.speiseplan.bestellung.tage[this.speiseplanMenuDatum]
      this.bestellung = Object.values(this.bestellungen.bestellMenues).filter((element: any) => {
        if (element.menueTextId == this.menu.menueTextId) { return element }
      })[0]
      this.gesperrt = this.speiseplanMenuService.speiseplanMenuGesperrt(
        this.mandant,
        this.speiseplanMenuDatum,
        this.bestellung,
        this.config,
        this.speiseplan
      )
    }else{
      this.bestellungen = {}
      this.config = {
        bestellungen: {}
      }
      this.config.bestellungen[this.menu.menueTextId] = {markierung: false}
      this.bestellung = {
        mengeNeu: 0,
        mengeAlt: 0
      }
    }
  }

  ngAfterViewInit() {
    this.speiseplanMenuService.menuDetailsObserver.subscribe((result:any) => {
      if (result){
        this.aktiv = result.menueTextId
        if (this.aktiv == this.menu.menueTextId){
          // TODO: einabuen das er nach wechsel in den splan bei der richtigen kachel beginnt
          // console.log('test')
          // this.speiseplanMenuService.seitenleistenDetailsAktualisieren(this.mandant, result)
        }
      } else {
        if (this.kunde){
          if (new Date(this.bestellungen.datum).toDateString() == new Date().toDateString()){
            this.aktiv = Object.values(this.bestellungen.bestellMenues)[0]['menueTextId']
          }
        }
      }
    })
  }

  open_details(){
    if (environment.type !== 'web'){
      let dialogRef = this.dialog.open(SpeiseplanDetailsComponent, { width: '90%' });
      dialogRef.componentInstance.menu = this.menu
      dialogRef.componentInstance.mandant = this.mandant
    } else {
      this.speiseplanMenuService.seitenleistenDetailsAktualisieren(this.mandant, this.menu)
    }
  }

  protected readonly environment = environment;
}
