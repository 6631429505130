import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class LadeanzeigeService {

  public loadingOverlay: BehaviorSubject<boolean> = new BehaviorSubject(false)

  constructor() {}
}
