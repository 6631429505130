import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'web-fehler',
    templateUrl: './web-fehler.component.html',
    styleUrls: ['./web-fehler.component.css']
})
export class WebFehlerComponent implements OnInit {

  public error: object

  constructor(private route: ActivatedRoute){}

  ngOnInit() {
    this.route.params
    .pipe(map((error) => {return JSON.parse(error.error)}))
    .subscribe((data) => {this.error = data })
  }
}
