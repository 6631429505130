<div id="emailVerifikationDialog">
  <mat-card>
    <div id="emailVerifikationHeader">
      <mat-icon *ngIf="mailResponseType == 'good'" >mark_email_read</mat-icon>
      <mat-icon *ngIf="mailResponseType == 'bad'" >warning</mat-icon>
    </div>
    <div [innerHTML]="mailNachricht"></div>
    <div id="emailVerifikationFooter">
      <button mat-button (click)="linkToLogin()">Zum Login</button>
    </div>
  </mat-card>
</div>
