<div id="speiseplanDetail" *ngIf="menu && mandant">
  <div id="speiseplanDetailsContent" [class.mobil]="detectedPlattform == 'mobil'">
    <div class="speiseplan-details-beschreibung details-abschnitt" [innerHTML]="menu.menueText | bbCode"></div>
    <ng-container *ngIf="menuAIZ">
      <sup *ngIf="menuAIZ.length != 0" [innerHTML]="menuAIZ"></sup>
    </ng-container>
    <ng-container *ngIf="menuZusatz">
      <div id="menuZusatz" [innerHTML]="menuZusatz | img | async"></div>
    </ng-container>
    <div *ngIf="menu.naehrwerte.length > 0" class="speiseplan-details-naehrwert details-abschnitt">
      <div class="speiseplan-details-naehrwert-tabelle">
        <div *ngIf="menu.portionsGroesse" id="speiseplanDetailsPortion">
          <span>Portionsgröße:</span>
          <span>{{ menu.portionsGroesse }}g</span>
        </div>
        <strong>Nährwerte pro 100g</strong>
        <div *ngFor="let naehrwert of menu.naehrwerte">
          <span>{{ naehrwert.name }}</span>
          <span>{{ naehrwert.wert }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
