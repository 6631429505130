<div class="speiseplan-details-glossar">
    <mat-accordion *ngIf="glossar">
      <mat-expansion-panel *ngFor="let eintrag of glossar" id="{{eintrag.name}}" class="aizCard" (expandedChange)="statusWechsel(eintrag)">
        <mat-expansion-panel-header>
          <mat-panel-title class="titel">
            <strong [class.active]="statusAnzeigen(eintrag)">{{ eintrag.name }}</strong>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div id="glossarExpansionContent">
          <ng-container *ngIf="eintrag.data.length > 0">
            <ul *ngFor="let test of eintrag.data">
                <ol>{{ test['schluessel'] }}</ol>
                <span>{{ test['bezeichnung'] }}</span>
            </ul>
          </ng-container>
        </div>
        <ng-container *ngIf="eintrag.data.length < 0">
          <span>Keine Angaben vorhanden</span>
        </ng-container>
      </mat-expansion-panel>
    </mat-accordion>
</div>
