import {Injectable} from '@angular/core';
import {ApiCallService} from '../../../services/api/api-call.service';
import {Observable} from 'rxjs';


@Injectable()
export class SpeiseplanDatenService {

  constructor(
    private apiCallSerive: ApiCallService,
  ) {}

  public speiseplanZeitraum(mandantId:string, bestellerId:string):Observable<any>{
    return this.apiCallSerive.request('/call', 'speiseplan/zeitraum', {
      mandantId: mandantId,
      bestellerId: bestellerId,
    })
  }

  public einrichtungsGruppenGet(mandantId:string, einrichtungsName:string){
    return this.apiCallSerive.request('/call', 'einrichtungsgruppen/get', {
      mandantId: mandantId,
      einrichtungsName: einrichtungsName,
    })
  }

  public essensteilnehmerGet(sammelbesteller:Object, einrichtungsName:string, etName:string, etGruppe:string){
    return this.apiCallSerive.request('/call', 'sammelbesteller/get', {
      mandantId: sammelbesteller['mandantId'],
      sbBestellerId: sammelbesteller['bestellerId'],
      einrichtungsName: einrichtungsName,
      etName: etName,
      etGruppe: etGruppe
    })
  }

}

