import {Component, OnInit, AfterViewInit, Input, ElementRef, ViewChild} from '@angular/core';
import {timer} from 'rxjs';
import {SpeiseplanKalenderService} from '../../../obs.speiseplan/einzelbesteller/speiseplan-kalender/speiseplan-kalender.service';
import {environment} from '../../../../environments/environment';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import * as moment from 'moment';

@Component({
  selector: 'kalender-picker',
  templateUrl:  './kalender-picker.component.html',
  styleUrls: ['./kalender-picker.component.css'],
})

export class KalenderPickerComponent implements OnInit, AfterViewInit {
  @Input('zeitraum') zeitraum
  @Input('modus') modus
  @Input('aktuelles_datum') aktuelles_datum
  @ViewChild('picker') picker: ElementRef
  public dP
  public aktuelleKw

  constructor(
    private kalender_service: SpeiseplanKalenderService,
    public dialog: MatDialog,
  ) {}

  ngOnInit() {
    this.dP = environment.type
    this.aktuelleKw = moment.default().isoWeek()
  }

  ngAfterViewInit() {
    if (this.dP != 'web'){
      this.fokusSetzen()
    }
  }

  aktualisieren(zeitraum){
    this.kalender_service.kalenderTrigger.next(['aktualisieren', zeitraum])
    this.menue_sichtbarkeit()
    if (this.dP != 'web'){
      this.dialog.closeAll()
    }
  }

  fokusSetzen(){
    const element = document.getElementsByClassName("aktuelles_datum")[0];
    timer(500).subscribe(() => { element.scrollIntoView({
      behavior: 'smooth',
    })
    })
  }

  menue_sichtbarkeit() {
    if (this.dP == 'web'){
      this.picker.nativeElement.classList.remove('swing-in-top-fwd')
      this.picker.nativeElement.classList.remove('swing-out-top-bck')
      let index = true
      for (let el of this.picker.nativeElement.classList)
        if (el == 'sichtbar'){
          index = false
        }
      if (index) {
        this.picker.nativeElement.classList.add('sichtbar')
        this.picker.nativeElement.classList.add('swing-in-top-fwd')
      } else {
        this.picker.nativeElement.classList.add('swing-out-top-bck')
        timer(500).subscribe(() => {
          this.picker.nativeElement.classList.remove('sichtbar')
        })
      }
    }
  }

  public dialogSchliessenChevron() {
    if (this.dP == 'web'){
      this.picker.nativeElement.classList.remove('swing-in-top-fwd')
      this.picker.nativeElement.classList.remove('swing-out-top-bck')
      this.picker.nativeElement.classList.add('swing-out-top-bck')
      timer(500).subscribe(() => {
        this.picker.nativeElement.classList.remove('sichtbar')
      })
    }
  }
}
