import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {SammelbestellerFilter} from '../sammelbestellerFilter/sammelbestellerFilter.config';
import * as moment from 'moment/moment';
import {SpeiseplanZeitraumService} from '../../services/speiseplan-zeitraum.service';

@Component({
	selector: 'app-sammelbestellerKalender',
	templateUrl: './sammelbestellerKalender.component.html',
	styleUrls: ['./sammelbestellerKalender.component.css'],
})
export class SammelbestellerKalenderComponent implements OnInit, AfterViewInit {

  @Input('sammelbestellerFilterConfig') filterConfig: SammelbestellerFilter;
  public zeitraum
  public apiZeitraum
  public aktuellesDatum: Date | null
  public startWoche
  public startMonat


  constructor(
    // private speiseplanZeitraum: SpeiseplanZeitraumService,
  ) {
    moment.locale('de');
  }

	ngOnInit() {
    this.aktuellesDatum = this.filterConfig.filterDatum
    this.apiZeitraum = this.filterConfig.apiZeitraum
    let woche = moment.utc().startOf('week').format('YYYY-MM-DD')
    this.startWoche = new Date(woche) > new Date(this.apiZeitraum.von) ? woche : false
    let monat = moment.utc().startOf('month').format('YYYY-MM-DD')
    this.startMonat = new Date(monat) > new Date(this.apiZeitraum.von) ? monat : false
    console.log(this.startWoche, this.startMonat)
  }

	ngAfterViewInit() {}

  public aktualisieren(datum){
    this.filterConfig.filterDatum = moment.utc(datum).add(1, 'd').format('YYYY-MM-DD')
  }

  public aktuelleWoche(){
    this.filterConfig.filterDatum = this.startWoche
    this.aktuellesDatum = new Date(this.startWoche)
  }

  public aktuellerMonat(){
    this.filterConfig.filterDatum = this.startMonat
    this.aktuellesDatum = new Date(this.startMonat)
  }
}
