import { Injectable } from '@angular/core';

@Injectable()
export class MenueSperreSopro {

  constructor(
  ) {
  }

  public menuSperreSoproPruefen(mandant, datum, bestellung, speiseplan){
    if (mandant.sonderKonfiguration['Lock4Menue'][datum]){
      for (let i of mandant.sonderKonfiguration['Lock4Menue'][datum]) {
        if (i == parseInt(bestellung.menueTextId.split('_')[1])) {
          if (speiseplan.menutexte[bestellung.menueTextId.menueTyp] != 'A'){
            return true;
          }
        }
      }
    } else {
      return false
    }
  }
}
