  <div #picker class="speiseplan-kalender-picker" [class.wba]="dP != 'web'" *ngIf="zeitraum">
      <section
          (click)="aktualisieren([woche, zeitraum]);"  [class.wbaSection]="dP != 'web'"
          *ngFor="let woche of zeitraum" [class.aktuelle_kw]="(modus == 'woche' && woche.kw == aktuelles_datum.kw) || (modus == 'monat' && woche.monat == aktuelles_datum.monat)"
          [class.aktuelles_datum]="(modus == 'woche' && woche.kw == aktuelles_datum.kw) || (modus == 'monat' && woche.monat == aktuelles_datum.monat) || (modus == 'tag' && woche.start == aktuelles_datum.start)"
          [class.aktuelleKw]="woche.kw == aktuelleKw"
      >
        <strong *ngIf="modus == 'tag'">{{ woche.start | datum:'wochentag'}}</strong>
        <strong *ngIf="modus == 'woche'">Kalenderwoche {{ woche.kw }}</strong>
        <strong *ngIf="modus == 'monat'">{{ woche.monat | monat }} </strong>
        <br>
        <span *ngIf="modus == 'tag'" > {{ woche.start | kalenderDatum: 'dd.MM.yyyy'}}</span>
        <span *ngIf="modus == 'woche'">{{ woche.start | kalenderDatum:'dd.MM.YYYY'}} - {{ woche.ende | kalenderDatum:'dd.MM.YYYY' }}</span>
      </section>
  </div>
