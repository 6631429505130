<div #mitteilungen id="mitteilung" *ngIf="mitteilung && mitteilung.art != 'kritisch'">
  <div *ngIf="mitteilung.art == 'dialog'" id="mitteilung_dialog">
    <div class="ladeanzeige" [class]="mitteilung.typ">
      <mat-icon (click)="close()">close</mat-icon>
    </div>
    <div id="mitteilung_dialog_content" class="swing-in-top-fwd">
      <span [innerHTML]="mitteilung.content | mitteilung:kunde:mandant:mitteilung.art"></span>
    </div>
  </div>
  <!-- Global Mitteilungen -->
  <div *ngIf="mitteilung.art == 'global'" id="mitteilung_global" [class]="mitteilung.typ">
    <div id="mitteilung_global_content" class="swing-in-top-fwd">
      {{ mitteilung.content | mitteilung:kunde:mandant:mitteilung.art }}
    </div>
  </div>
</div>
  <!-- Kritische Mitteilungen -->
  <div *ngIf="mitteilung && mitteilung.art == 'kritisch'" id="mitteilung_kritisch" [class]="mitteilung.typ">
    <div id="mitteilung_kritisch_content" class="swing-in-top-fwd">
      {{ mitteilung.content | mitteilung:kunde:mandant:mitteilung.art }}
    </div>
  </div>
  <!-- Feld Mitteilungen -->
  <div *ngIf="art == 'feld' && feld_error" id="mitteilung_feld">
    <div id="fehlermeldung">
      <mat-icon>warning</mat-icon>
      <span>Es wurden Fehler festgestellt</span>
    </div>
    <div id="fehler_bereich">
      <ng-container *ngFor="let value of feld_error">
        <div class="fehler_nachricht">
          <strong>{{value[0]}}</strong><span [id]="value[0]">{{value[1]}}</span>
        </div>
      </ng-container>
    </div>
  </div>
