<div id="benachrichtigungen">
  <div class="einrichtung-title">
    <strong>Benachrichtigung Ihrer Einrichtung</strong>
    <div class="einrichtung-text">
      <div id="einrichtung-texte" (click)="read()">
        <mat-icon *ngIf="flag == true" >notifications_none</mat-icon>
        <span [innerHTML]="info"></span>
      </div>
    </div>
  </div>
</div>