import {Component, OnInit} from '@angular/core';
import {SeitenleisteService} from '../../seitenleiste.service';
import {Router} from '@angular/router';

@Component({
	selector: 'profil-seitenleiste',
	templateUrl: './profil.seitenleiste.component.html',
	styleUrls: ['./profil.seitenleiste.component.css'],
})
export class ProfilSeitenleisteComponent implements OnInit {

  public mandant
  public kunde
  public hidePasswort: Boolean = true
  public aktiv = false
  public aktuelleUrl

	constructor(
    private seitenleistenService: SeitenleisteService,
    private router: Router,
	) { }

  ngOnInit() {}

  reset(){
    window.location.reload()
  }

  change(event) {
    if (event.target.value != ''){
      this.aktiv = true
    }
    else {
      this.aktiv = false
    }
    this.seitenleistenService.emitterSeitenleistenInhalt(['change', event])
  }

  public navigiere(id:string){
    this.router.navigate(['/profil'], { queryParams: { id: id } })
  }

  onSubmit() {
    this.seitenleistenService.emitterSeitenleistenInhalt(['onSubmit'])
  }
}
