import {AfterViewInit, Component } from '@angular/core';
import {ApiCallService} from '../../../../services/api/api-call.service';
import {environment} from '../../../../environments/environment';
import { AppStateService } from 'services/app/app-state.service';

@Component({
  selector: 'app-registrierung-zwischenseite',
  templateUrl: './registrierung-zwischenseite.component.html',
  styleUrls: ['./registrierung-zwischenseite.component.css'],
})
export class RegistrierungZwischenseiteComponent implements AfterViewInit {

  public zwischenseiteContent
  public zustand

  constructor(
    private apiCallService: ApiCallService,
    private appStateService: AppStateService,
  ) {
    this.appStateService.set_page_name('Neukundenregistrierung');
    this.appStateService.set_seitenname('registrierungsZwischenseite')
  }

  ngAfterViewInit() {
    this.zustand = environment.type
    this.apiCallService.request('/public', 'content/get', {
      mandantId: environment.mandant_id ,
      'contentItemIds': JSON.stringify([]),
      zweck: environment.type == 'mobil' ? 'App_RegistrierungZwischenseite' : 'Web_RegistrierungZwischenseite',
      lang: 'de'
    }).subscribe(result => { this.zwischenseiteContent = atob(result[0].content)
    })
  }
}
