import {AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';

@Component({
	selector: 'app-sammelbestellerEssensteilnehmer',
	templateUrl: './sammelbestellerEssensteilnehmer.component.html',
	styleUrls: ['./sammelbestellerEssensteilnehmer.component.css'],
})
export class SammelbestellerEssensteilnehmerComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor() {}

	ngOnInit() {}

	ngAfterViewInit() {}

  ngOnDestroy() {}
}
