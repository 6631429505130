import {Component, Input, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {environment} from '../../../../../environments/environment';
import {ExpressStornoDialogComponent} from '../expressStorno-dialog/expressStorno-dialog.component';

@Component({
    selector: 'expressStorno-icon',
    templateUrl: './expressStorno-icon.component.html',
    styleUrls: ['./expressStorno-icon.component.css'],
})
export class ExpressStornoIconComponent implements OnInit {

  @Input('kunde') kunde
  @Input('mandant') mandant

  public mobil = false

  constructor(
    private matDialog: MatDialog,
  ) {}

  ngOnInit(){
    this.mobil = environment.type != 'web' ? true : false
  }

  dialogOpen(){
    this.matDialog.open(ExpressStornoDialogComponent, {
      data: {
        kunde: this.kunde,
        mandant: this.mandant
      },
      maxHeight: '650px'
    })
  }

  protected readonly environment = environment;
}
