import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { DialogService } from 'services/prozesse/dialog.service';
import { KundeService } from 'services/kunden/kunde.service';
import { MandantenService } from 'services/mandanten/mandanten.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {MitteilungsService} from '../../../services/prozesse/mitteilung.service';
import {VerifyDialogComponent} from '../../../submodul/obs.shared/dialog/verify-dialog/verify-dialog.components';
import {Observable} from 'rxjs';


@Component({
	selector: 'web-login',
	templateUrl: './web-login.component.html',
	styleUrls: ['./web-login.component.css'],
})
export class WebLoginComponent implements OnInit, AfterViewInit {
	public path
  public mandant
  public logo: string = "assets/web/logo.webp";

	constructor(
		private dialog_service: DialogService,
		private kunde_service: KundeService,
		private mandant_service: MandantenService,
    private mitteilungsService: MitteilungsService,
    private dialog: MatDialog,
		private router: Router,
	) { }

	ngOnInit() {
    this.path = this.router.url
		this.dialog_service.activePage_get(this.path)
    this.mandant_service.mandant_get().subscribe((mandant) => {
      this.mandant = mandant
    })
    if (/passwort-vergessen/gm.test(this.router.url)){
      let id = this.router.url.split('/passwort-vergessen/')[1]
      this.kunde_service.kunde_passwort_reseten(id).subscribe(
        result => { this.mitteilungsService.setMitteilung('8105')},
        error => { console.log(error)},
        () => {}
      )
    }
    if (/verify-email/gm.test(this.router.url)){
      let id = this.router.url.split('/verify-email/')[1]
      this.kunde_service.kunde_verify(id).subscribe(
        result => {
          this.dialog_open(VerifyDialogComponent, 'good')
        },
        error => {
          this.dialog_open(VerifyDialogComponent, 'bad')
        },
        () => {}
      )
    }
	}

	ngAfterViewInit() {
  }

  onLogin(loginEvent: Observable<any>){
    loginEvent.subscribe(
      () => {
        if (this.mandant.navigation_logged_in_default === 'home') {
          this.router.navigateByUrl('/home')
        } else if (this.mandant.navigation_logged_in_default === 'menu') {
          this.router.navigateByUrl('/speiseplan-kunde')
        }
      }, errors => {
        document.getElementById('passwortVergessen').classList.add('heartbeat')
        setTimeout(() => {
          document.getElementById('passwortVergessen').classList.remove('heartbeat')
        }, 1000)
        this.mitteilungsService.setMitteilung(errors.error.content.msgId)
      });
  }

  dialog_open(component, type){
    this.dialog.open(component, {
      data: {
        type: type,
        mandant: this.mandant
      }
    });
  }

  // TODO: hier nen Service drauß machen, ich bin zu faul grad lol
  onImageError() {
    this.logo = "assets/web/icon-1024x1024.webp";
  }
}
