import {AfterViewInit, Component, OnInit} from '@angular/core';
import { AppStateService } from 'services/app/app-state.service';
import { MandantenService } from '../../../../services/mandanten/mandanten.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
// @ts-ignore
import * as formConfig from './vertrag-kuendigen.config.json';
import {MitteilungsService} from '../../../../services/prozesse/mitteilung.service';
import {environment} from '../../../../environments/environment';
import {KundeService} from '../../../../services/kunden/kunde.service';

@Component({
  selector: 'vertrag-kuendigen',
  templateUrl: './vertrag-kuendigen.component.html',
  styleUrls: ['./vertrag-kuendigen.component.css']
})
export class VertragKuendigenComponent implements OnInit, AfterViewInit {

  public mandant
  public form = new FormGroup({})
  public formConfig

  constructor(
    public mandant_service: MandantenService,
    public kundeService: KundeService,
    private app_state_service: AppStateService,
    private mitteilungsService: MitteilungsService,
    private dialog: MatDialog
  ) {
    this.app_state_service.set_page_name('Vertrag kündigen');
    this.app_state_service.set_seitenname('vertragKuendigen')
  }

  ngOnInit() {
    this.mandant_service.mandant_get().subscribe(mandant => {
      this.mandant = mandant
    })
    this.formConfig = formConfig.default[environment.mandant_id] ? formConfig.default[environment.mandant_id] : formConfig.default['default']
    for (let formControlName in this.formConfig){
      let formControlConfig = this.formConfig[formControlName]
      this.form.addControl(
        formControlName,
        new FormControl('', formControlConfig.validators == 'requiered' ? Validators.required : null)
      )
    }
  }

  ngAfterViewInit() {
    const vertragKuendigenTermin:any = document.getElementById('vertragKuendigenTermin')
    vertragKuendigenTermin.min = new Date().toISOString().split('T')[0];
    this.form.controls['termin'].setValue(new Date().toISOString().split('T')[0])
    this.kundeService._kunde_get().subscribe(kunde => {
      if (kunde){
        this.form.controls['kundenNr'].setValue(kunde.kundenNr)
        let vertragsNehmer = kunde.vertragspartner.name ? 'vertragspartner' : 'essensteilnehmer'
        this.form.controls['anrede'].setValue(kunde[vertragsNehmer].anrede)
        this.form.controls['vorname'].setValue(kunde[vertragsNehmer].vorname)
        this.form.controls['name'].setValue(kunde[vertragsNehmer].name)
        this.form.controls['eMail'].setValue(kunde[vertragsNehmer].eMail)
      }
    })
  }

  public onSubmit() {
    this.mandant_service.contractDelete(this.form.value).subscribe(
      (next) => {
        this.form.reset()
        if (environment.type == 'web'){
          this.mitteilungsService.setMitteilung('8805');
        }
        else{
          this.mitteilungsService.set_snackbar('8805', 4000);
        }
        const vertragKuendigenTermin:any = document.getElementById('vertragKuendigenTermin')
        vertragKuendigenTermin.min = new Date().toISOString().split('T')[0];
        vertragKuendigenTermin.value = new Date().toISOString().split('T')[0];
      },
      (error) => {
        if (environment.type == 'web'){
          this.mitteilungsService.setMitteilung('8806');
        }
        else{
          this.mitteilungsService.set_snackbar('8806', 4000);
        }
      }
    )
  }

  public closeDialog(){
    this.dialog.closeAll()
  }

  protected readonly environment = environment;
}
