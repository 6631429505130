import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnDestroy, ViewChild } from '@angular/core';
import { LoadingService } from 'services/prozesse/loading.service';

@Component({
  selector: 'web-ladeanzeige',
  templateUrl: './web-ladeanzeige.component.html',
  styleUrls: ['./web-ladeanzeige.component.css']
})
export class WebLadeanzeigeComponent implements AfterViewInit, OnDestroy {

  @ViewChild('ladeanzeige') ladeanzeige: ElementRef
  public loading: boolean = false;
  private loadingSubscription


  constructor(
    private loadingScreenService: LoadingService,
    private cdRef: ChangeDetectorRef
  ) { }

  ngAfterViewInit() {
    this.loadingSubscription = this.loadingScreenService.loadingStatus.subscribe((loading: boolean) => {
      this.loading = loading
    })
  }

  ngOnDestroy() { this.loadingSubscription.unsubscribe() }
}
