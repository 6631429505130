// src/app/auth/auth.service.ts
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { ApiCallService } from 'services/api/api-call.service';
import { MandantenService } from 'services/mandanten/mandanten.service';

@Injectable()
export class SpeiseplanMandantGuard  {
  constructor(
    private router: Router,
    private mandant_service: MandantenService,
  ) { }

  canActivate(): boolean {
    let parameter = {
      mandantId: environment.mandant_id,
    }

    this.mandant_service.mandant_get()
    .subscribe(mandant => {
      if (Object.keys(mandant.speisePlanIds).length == 1){
        this.router.navigateByUrl(`/speiseplan-mandant/${mandant.speisePlanIds[0].splanId}`)
      }
    })
    return true;
  }
}
