import {Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy} from '@angular/core';
import {BehaviorSubject, of, zip} from 'rxjs';
import {BestellungService} from 'services/bestellung/bestellung.service';
import {DialogService} from 'services/prozesse/dialog.service';
import {KundeService} from 'services/kunden/kunde.service';
import {MandantenService} from 'services/mandanten/mandanten.service';
import {NavigationEnd, Router} from '@angular/router';
import {map} from 'rxjs/operators';
import {MitteilungsService} from 'services/prozesse/mitteilung.service';
import {ConfirmDialogComponent} from '../../../submodul/obs.shared/dialog/confirm-dialog/confirm-dialog.components';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {StateService} from '../../../services/app/state.service';
import {BenachrichtigungService} from '../../../services/prozesse/benachrichtigung.service';
import {FaqService} from '../../../submodul/obs.shared/componenten/faq/faq.service';

@Component({
  selector: 'web-footer',
  templateUrl: './web-footer.component.html',
  styleUrls: ['./web-footer.component.css'],
})


export class WebFooterComponent implements OnInit, AfterViewInit, OnDestroy {

  constructor(
    public dialog_service: DialogService,
    public mandant_service: MandantenService,
    public state_service: StateService,
    public kunde_service: KundeService,
    public bestell_service: BestellungService,
    public mitteilungsService: MitteilungsService,
    private benachrichtigung_service: BenachrichtigungService,
    private dialog: MatDialog,
    private router: Router,
    private faq_service: FaqService
  ) {
  }

  @ViewChild('web-menu-tiles') tiles: ElementRef;
  public mandant: any
  public kunde: object | boolean
  public data = new BehaviorSubject<any>(false)
  public menu_tiles = this.dialog_service.menu_tiles
  public item: string
  public message: any
  public rechts_content: any
  public content: string
  public flag: any
  public kuendigung
  public faq
  public seite

  private subscription

  ngOnInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd){
        this.seite = decodeURIComponent(this.router.url.split('=')[1])
      }
    })
    this.dialog_service.routes_observer(() => {
      this.get()
    })
    this.get()
    this.getRechtliches()
  }

  ngAfterViewInit() {
    zip(
      this.kunde_service._kunde_get(),
      this.mitteilungsService.benachrichtigungen
    ).subscribe((response) => {
      this.kunde = response[0]
      if (this.kunde != undefined) {
        this.benachrichtigung_service.setze_benachrichtigungen(this.kunde)
      }
      this.flag = response[1]
      this.faq_service.faqAnzeigen(this.kunde)
      this.faq_service.faqAnzeige.subscribe(anzeige => {
        this.faq = anzeige
      })
    })
  }

  ngOnDestroy() {
    this.subscription.unsubscribe()
  }

  setLinkActive(value) {
    if (value == 1) {
      let button = document.getElementById('rechtliches')
      button.classList.add('active')
    } else if (value == 2) {
      let button = document.getElementById('profil')
      button.classList.add('active')
    }
  }

  kuendigen() {
    if (/kuendigen_step1/gm.test(this.kuendigung)) {
      this.router.navigate(['/rechtliches'], {queryParams: {id: 'Kündigung'}})
    } else {
      window.open(this.kuendigung, '_blank')
    }
  }

  setLinkInactive(value) {
    if (value != null) {
      if (value == 1) {
        let button = document.getElementById('rechtliches')
        button.classList.remove('active')
      } else if (value == 2) {
        let button = document.getElementById('rechtliches')
        button.classList.remove('active')
        let button2 = document.getElementById('profil')
        button2.classList.remove('active')
      } else {
        let button2 = document.getElementById('profil')
        button2.classList.remove('active')
      }
    }
  }

  public get() {
    zip(
      this.mandant_service.mandant_get(),
      this.kunde_service._kunde_get()
    ).subscribe((result: any) => {
      this.kuendigung = result[0].sonderKonfiguration.API_1_5.terminateContractURL
      this.mandant = result[0]
      this.kunde = result[1]
      this.data.next({
        mandant: result[0],
        kunde: result[1]
      })
    })
  }

  public getRechtliches() {
    setTimeout(() => {
      this.subscription = this.state_service.mandant
        .pipe(map((mandant) => {
          return <any>mandant
        }))
        .subscribe((mandant) => {
          this.rechts_content = [
            {content: mandant.betreiberDaten.agb, name: 'AGB'},
            {content: mandant.betreiberDaten.datenschutz, name: 'Datenschutz'},
            {content: mandant.betreiberDaten.widerruf, name: 'Widerruf'},
            {content: mandant.betreiberDaten.impressum, name: 'Impressum'},
            {name: 'Kontakt'}
          ]
        })
    }, 500)
  }1
}

