import {Component, OnInit} from '@angular/core';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {WerbebannerService} from '../werbebanner/werbebanner.service';
import {environment} from '../../../../environments/environment';

@Component({
  selector: 'app-werbePopup',
  templateUrl: './werbePopup.component.html',
  styleUrls: ['./werbePopup.component.css']
})
export class WerbePopupComponent implements OnInit {

  public werbePopupContent:string

  constructor(
    public dialogRef: MatDialogRef<WerbePopupComponent>,
    private werbeBannerSerivce: WerbebannerService,
  ) { }

  ngOnInit(): void {
    this.werbePopupContent = this.werbeBannerSerivce.werbePopupContent
  }

  protected readonly environment = environment;
}
